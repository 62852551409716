<template>
  <div class="page">
    <div class="content__container">
      <div class="content">
        <div class="mds-doc-404__container">
          <mds-empty-state
            size="large"
            title="It looks like you're not entitled to access this page."
            message="To get an access or If you need additional help,
             send us a note at
              <a href='mailto:commoditydata-support@morningstar.com'>
              commoditydata-support@morningstar.com
              </a>."
          >
            <template #mds-empty-state-icon>
              <img
                slot="mds-empty-state-icon"
                :src="require(`../../assets/images/404-face.svg`)"
                alt=""
              >
            </template>
          </mds-empty-state>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MdsEmptyState from '@mds/empty-state';

export default {
  name: 'NotEntitledUser',
  components: { MdsEmptyState },
};
</script>
<style lang="scss">
@import '@mds/link';
@import '@mds/constants';

.mds-doc-404__container {
  display: flex;
  justify-content: center;
  margin: $mds-space-8-x 0;
}

.mds-empty-state {
  a {
    @include mds-link();
  }
}
.page {
  display: flex;
}

.content__container {
  flex-grow: 1;
}

.content {
  max-width: 1000px;
  margin: 0 auto;
}
</style>
