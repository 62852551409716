<template>
  <div class="diagram">
    <mds-notification-container v-if="showPageSuccess">
      <mds-notification
        key="success-tinted"
        variation="success"
        title="Success"
        tinted
        :dismiss-delay="3000"
        @mds-notification-dismissed="hideSuccess1"
      >
        <span v-html="pageSuccess" />
      </mds-notification>
    </mds-notification-container>
    <mds-notification-container v-if="errorMessage">
      <mds-notification
        key="error-tinted"
        visible
        variation="error"
        title="Error"
        tinted
        :dismiss-delay="3000"
        @mds-notification-dismissed="dissmissError"
      >
        <strong>Address these findings before continuing:</strong>
        <div v-html="errorMessage" />
      </mds-notification>
    </mds-notification-container>
    <mds-layout-grid>
      <mds-loader
        v-if="showLoader"
        aria-label=" Loader"
      />
      <div
        v-if="!showLoader"
        :class="!isEditable ? 'read-only' : ''"
      >
        <template v-if="errorMessage !== ''" />
        <mds-row>
          <mds-col
            :cols="2"
            class="left-pannel vl"
          >
            <mds-row style="margin-bottom: 2%; margin-top: 20%;">
              <mds-col style="text-align: center;">
                <p style="margin: 0px;">
                  <strong>Connections</strong>
                  <mds-button
                    id="default-trigger-connection-info"
                    size="small"
                    variation="icon-only"
                    icon="exclaim-circle-fill"
                    type="button"
                    style="margin-left: 0.5rem"
                  />
                  <mds-tooltip
                    triggered-by="default-trigger-connection-info"
                    :position="['right-center']"
                  >
                    Select the connection and press delete button to remove connection between bubbles.
                  </mds-tooltip>
                </p>
              </mds-col>
            </mds-row>
            <div
              class="path-buttons"
            >
              <div>
                <button
                  id="s-btn"
                  class="path-btn path-s-btn"
                  :class="{active:isActiveS}"
                  type="button"
                  text="S"
                  @click="highlightSucessfullNodes"
                />
                <mds-tooltip
                  v-model="toggles"
                  triggered-by="s-btn"
                  :position="['right-center']"
                >
                  Successful completion
                </mds-tooltip>
              </div>
              <div>
                <button
                  id="qf-btn"
                  class="path-btn path-qf-btn"
                  type="button"
                  :class="{active:isActiveQF}"
                  text="S"
                  @click="highlightQAFailureNodes"
                />
                <mds-tooltip
                  v-model="toggleqf"
                  triggered-by="qf-btn"
                  :position="['right-center']"
                >
                  QA failure
                </mds-tooltip>
              </div>
              <div>
                <button
                  id="f-btn"
                  class="path-btn path-f-btn"
                  :class="{active:isActiveF}"
                  type="button"
                  text="Data"
                  @click="highlightAllFailureNodes"
                />
                <mds-tooltip
                  v-model="togglef"
                  triggered-by="f-btn"
                  :position="['right-center']"
                >
                  Any sort of failure, including QA failure
                </mds-tooltip>
              </div>
            </div>
            <hr>
            <div
              style="margin-top: 5%;"
            >
              <mds-row>
                <mds-col style="text-align: center;">
                  <p style="margin: 0px;">
                    <strong>Bubbles</strong>
                  </p>
                </mds-col>
              </mds-row>
              <div class="bubbles-btn-section">
                <mds-row>
                  <mds-col class="series-btn node-btn">
                    <mds-button
                      class="bubble"
                      type="button"
                      text="Data"
                      size="touch"
                      variation="primary"
                      @click="addNodes('data', data)"
                    />
                  </mds-col>
                </mds-row>
                <mds-row>
                  <mds-col class="series-btn node-btn">
                    <mds-button
                      type="button"
                      text="QA"
                      size="touch"
                      variation="primary"
                      @click="addNodes('qa', qa)"
                    />
                  </mds-col>
                </mds-row>
                <mds-row>
                  <mds-col class="series-btn node-btn">
                    <mds-button
                      type="button"
                      text="Formula"
                      size="touch"
                      variation="primary"
                      @click="addNodes('formula', formula)"
                    />
                  </mds-col>
                </mds-row>
                <mds-row>
                  <mds-col class="series-btn node-btn">
                    <mds-button
                      type="button"
                      size="touch"
                      text="Publish"
                      variation="primary"
                      @click="addNodes('save', save)"
                    />
                  </mds-col>
                </mds-row>
                <mds-row>
                  <mds-col class="series-btn node-btn">
                    <mds-button
                      type="button"
                      size="touch"
                      text="Notification"
                      variation="primary"
                      @click="addNodes('notification', notif)"
                    />
                  </mds-col>
                </mds-row>
                <mds-row>
                  <mds-col class="series-btn node-btn">
                    <mds-button
                      type="button"
                      text="Variable"
                      variation="primary"
                      size="touch"
                      @click="addNodes('single_var', single)"
                    />
                  </mds-col>
                </mds-row>
              </div>
            <!-- <mds-row>
              <mds-col class="series-btn node-btn">
                <mds-button
                  type="button"
                  text="AMQP Task"
                  variation="primary"
                  @click="addNodes('apmq_task')"
                />
              </mds-col>
            </mds-row> -->
            </div>
          </mds-col>
          <mds-col class="right-pannel">
            <div
              :class="theme"
              class="node-diagram"
            >
              <mds-row class="nd-wrapper">
                <mds-col
                  :cols="7"
                  class="nd-title-heading"
                  style="padding: 0px; margin-top: 0.25%;"
                >
                  <div v-if="operation === 'view'">
                    <strong>{{ workflowName }}</strong>
                  </div>
                  <div
                    v-else
                    style="margin-top: -0.5rem"
                  >
                    <mds-form>
                      <mds-input
                        v-model="workflowNameProp"
                        placeholder="Enter workflow name here.."
                        label=""
                        @keyup="editWorkflowName"
                        @focusout="editWorkflowName"
                        @focusin="wfNameError = false"
                      />
                      <span
                        v-if="wfNameError"
                        id="bubbleName"
                        class="mds-form__field-error"
                        role="alert"
                      >
                        <span
                          class="mds-form__field-error-text"
                          style="margin-bottom: 10px;"
                        >
                          Workflow with {{ workflowNameProp }} is already present. Please change the name.
                        </span>
                      </span>
                      <span
                        v-if="workflowNameProp === ''"
                        id="bubbleName"
                        class="mds-form__field-error"
                        role="alert"
                      >
                        <span
                          class="mds-form__field-error-text"
                          style="margin-bottom: 10px;"
                        >
                          Please enter valid workflow name.
                        </span>
                      </span>
                    </mds-form>
                  </div>
                </mds-col>
                <mds-col :cols="3">
                  <div
                    style="display: flex;
                      justify-content: center;
                      align-items: center;padding: 0px; margin-top: 0.3rem;"
                  >
                    <label style="margin-top: -1.75%;"><b>Timeseries Correction: </b></label>
                    <mds-input
                      v-model="correctionDays"
                      type="number"
                      min="0"
                      max="60"
                      label="Timeseries Correction"
                      :hidden-label="true"
                      style="display: inline-block !important; margin-left: 10px; margin-top: 0;"
                      :error="correctionValidationFailed"
                      @change="validateCorrectionDays"
                    />
                  </div>
                </mds-col>
                <mds-col
                  class="nd-btn-container"
                  style="display: flex;
                        justify-content: end;
                        height: 100%;
                        margin-top: 0.3rem;"
                >
                  <mds-button
                    variation="primary"
                    @click="resetZoom()"
                  >
                    Reset Zoom
                  </mds-button>
                  <!-- <mds-button
                    variation="primary"
                    @click="fit()"
                  >
                    Fit
                  </mds-button> -->
                </mds-col>
              </mds-row>
              <div
                class="nd-editor"
              >
                <div
                  v-if="overlay"
                  id="mds-overlay"
                  class="mds-overlay"
                />
                <VueDiagramEditor
                  ref="diagram"
                  :node-color="nodeColor"
                  :node-pulsable="nodePulsable"
                  :zoom-enabled="true"
                  :before-delete-link="isEditable ? verifyDeleteLink : () => {}"
                  @before-delete-node="overlay=onOverlay"
                  @select-node="selectNode"
                  @deleted-node="deletedNode"
                  @deleted-link="deletedLinks"
                  @updated-node="updatedNode"
                  @click-port="clickPort"
                  @created-link="createdLink"
                >
                  <pre
                    slot="node"
                    slot-scope="{node}"
                    :class="{'has-error': node.data.hasError}"
                    style="display: flex; justify-content: space-between; align-items: flex-start;"
                  >
                    <span
                      v-if="format(node)"
                      style="font-size: 12px !important; margin-left: 8%; cursor: pointer;"
                      @click="openActionPopup(node, format(node))"
                      >{{ format(node).replace( /,/g, "" ) }}</span>
                    <span
                      v-if="!format(node)"
                      style="font-size: 12px !important; margin-left: 8%; cursor: pointer; text-transform: capitalize"
                      @click="openActionPopup(node, format(node))"
                      >{{ node.title }}</span>
                    <!-- <mds-button
                      id="wf-bubble-action"
                      type="button"
                      icon="share"
                      variation="icon-only"
                      @click="openActionPopup(node, format(node))"
                    >Actions</mds-button> -->
                  </pre>
                </VueDiagramEditor>
              </div>
            </div>
          </mds-col>
        </mds-row>
      </div>

      <div v-if="toggleEdit">
        <workflow-actions
          :node="actionNode"
          :node-var-name="actionNodeVarName"
          :workflow-data="workflowData"
          :formulas="formulas"
          :parameter-set-data="parameterSetData"
          :workflow="workflow"
          :is-new-node="isNewNode"
          :is-editable="isEditable"
          :bad-formula-bubbles="badFormulaBubbles"
          @newFormulaAdded="newFormulaAdded"
          @newQAFormulaAdded="newQAFormulaAdded"
          @notificationEdit="notificationEdit"
          @dataBubbleEdited="dataBubbleEdited"
          @newDataBubbleAdded="newDataBubbleAdded"
          @publishBubbleEdited="publishBubbleEdited"
          @newPublishBubbleAdded="newPublishBubbleAdded"
          @variableBubbleEdited="variableBubbleEdited_method"
          @newVariableBubbleAdded="newVariableBubbleAdded"
          @gotFormulaError="gotFormulaError"
          @closeEdit="closeEdit"
          @diagramChanged="diagramChanged"
        />
      </div>
    </mds-layout-grid>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { MdsButton } from '@mds/button';
import VueDiagramEditor from 'vue-diagram-editor';
import MdsInput from '@mds/input';
import MdsForm from '@mds/form';
import axios from 'axios';
import moment from 'moment';
import { MdsNotification, MdsNotificationContainer } from '@mds/notification';
import 'vue-diagram-editor/dist/vue-diagram-editor.css';
import { type } from 'os';
import svgPanZoom from 'svg-pan-zoom';
import WorkflowActions from './Actions/WorkflowActions.vue';
import {
  _newBubble, _newLink, _removeBubbleMenuCB, _scanFormulaErrors, _getVarsInFormula,
} from '../../scripts/utils/Manager';
import LinkType, { requireLinkTypes } from '../../scripts/utils/linktype.es6';
import BubbleType from '../../scripts/utils/bubbletype.es6';

const data = BubbleType.DATA;
const qa = BubbleType.QA;
const formula = BubbleType.FORMULA;
const save = BubbleType.SAVE;
const notif = BubbleType.NOTIFICATION;
const single = BubbleType.SINGLE_VAR;

export default {
  name: 'WorkflowDiagram',
  components: {
    MdsButton,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    VueDiagramEditor,
    WorkflowActions,
    MdsInput,
    MdsForm,
    MdsNotification,
    MdsNotificationContainer,
  },
  filters: {},
  props: {
    zoomEnabled: {
      type: Boolean,
      Default: false,
    },
    pan: {
      type: Boolean,
      default: true,
    },
    workflow: {
      type: Object,
      default: null,
    },
    workflowName: {
      type: String,
      default: '',
    },
    workflowData: {
      type: Object,
      default: null,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    formulas: {
      type: Array,
      default: null,
    },
    operation: {
      type: String,
      default: '',
    },
    badFormulaBubbles: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      defaultLink: {
        id: 'link-1',
        start_id: 'node-1',
        start_port: 'default',
        end_id: 'node-2',
        end_port: 'default',
      },
      nodes: {},
      links: {},
      workflowUi: {},
      paramSet: {},
      targetData: {},
      formulae: [],
      workflowInfo: {},
      // newly added qa-formula bubble formula array
      newAddedFormulas: [],
      // edited qa-formula bubbles formula
      editatedFormulas: [],
      parameterSetData: {},

      nodeModeArray: [],
      theme: 'blue-theme',

      toggleEdit: false,
      isActive: false,
      currentNodeType: '',
      currentOperation: '',

      actionNode: {},
      actionNodeVarName: '',

      linksCount: 0,

      isActiveS: false,
      isActiveQF: false,
      isActiveF: false,
      toggles: true,
      toggleqf: false,
      togglef: false,
      enableLinkCreation: false,

      newNodeId: '',

      enableSaveBtn: false,
      workflowNameProp: '',
      prevWfName: '',

      bubblesIdentifier: {
        notification: [],
        qa: [],
        formula: [],
      },

      targetsInfo: {
        notifications: [],
        qa: [],
        qa_failed: [],
        formula: [],
      },

      formulaNodeConnections: ['publish', 'save', 'notification', 'apmq_task'],
      publishNodeConnections: ['qa', 'formula', 'notification', 'apmq_task'],

      errorMessage: '',
      errorMessageModel: false,

      notifCount: 0,
      qaCount: 0,
      formulaCount: 0,
      isNewNode: false,

      // for newly added bubbles
      newDataBubbles: [],
      newQABubbles: [],
      newFormulaBubbles: [],
      newPublishBubbles: [],
      newNotificationBubbles: [],
      newVaribaleBubbles: [],
      newAPMQTaskBubbles: [],

      // already added bubbles
      prevDataBubbles: [],
      prevQABubbles: [],
      prevFormulaBubbles: [],
      prevPublishBubbles: [],
      prevNotificationBubbles: [],
      prevVaribaleBubbles: [],
      prevAPMQTaskBubbles: [],

      showLoader: false,
      successMessage: '',
      allWorkflows: [],
      wfNameError: '',

      workflowId: -1,

      // Parameter model status
      updateParameterSetData: {
        add: [],
        update: [],
        delete: [],
        currentPsgVersion: '1',
      },
      manager: {},
      data,
      formula,
      qa,
      save,
      notif,
      single,
      correctionDays: 0,
      wfCorrectionDays: 0,
      correctionValidationFailed: false,
      overlay: false,
      toggleConnectionInfo: false,
      pageSuccess: '',
      showPageSuccess: false,
    };
  },
  computed: {
    ...mapGetters('workflowModule', [
      'getParamStatus',
      'getWorkflowFormulas',
      'getEditableWorkflowDetails',
      'getEditableWorkflowUI',
      'getEditableParamSet',
      'getEditableFormulae',
      'getEditableNewFormulae',
      'getEditableNodes',
      'getEditableLinks',
      'getEditableTargets',
      'getCreateNewWorkflowDetails',
      'getCreateNewWorkflowUI',
      'getCreateNewParamSet',
      'getCreateNewFormulae',
      'getallWorkflowsForName',
      'getCurrentWorkflowManager',
      'getWorkflowName',
    ]),
    showSubmitButton() {
      return parseInt(this.wfCorrectionDays, 10) !== parseInt(this.correctionDays, 10);
    },
  },
  watch: {
    correctionDays(val) {
      this.manager._priv.correctionDays = parseInt(val, 10);
    },
    showSubmitButton(val) {
      if (val) {
        this.$emit('diagramChanged');
      }
    },
  },
  created() {},
  mounted() {
    this.$eventBus.$on('close-edit-action', this.closeEditAction);
    this.init();
    this.$parent.$on('notifyDiagram', this.handleData);
  },
  beforeDestroy() {
    this.$parent.$off('notifyDiagram', this.handleData); // Clean up event listener
  },
  methods: {
    ...mapActions('workflowModule', [
      'updateUserWorkflowsDiagram',
      'getFormulaNodeUUID',
      'updateWorkflowFormulas',
      'saveParameterSet',
      'createNewWorkflow',
      'getAllWorkflowsForDiagram',
      'getWorkflowStatus',
      'getUserWorkflows',
      'updateWorkflowByID',
      'setCurrentEditNodes',
      'setCurrentEditLinks',
      'setCurrentEditTargets',
      'setCurrentEditWorkflowUI',
      'setCurrentEditParamSet',
      'setNewlyCreateNodes',
      'setNewlyCreateLinks',
      'setNewlyCreateTargets',
      'setAllWorkflowsForName',
      'setCurrentEditFormulae',
      'setCurrentEditNewFormula',
      'setWorkflowName',
      'setCurrentWorkflowManager',
    ]),
    closeEditAction() {
      this.toggleEdit = false;
    },
    validateCorrectionDays(val) {
      if (parseInt(val.target._value, 10) > 60) {
        this.correctionValidationFailed = true;
        this.correctionDays = this.wfCorrectionDays;
        this.errorMessage = 'Timeseries correction days should not be greater than 60 days';
        this.errorMessageModel = true;
      } else if (parseInt(val.target._value, 10) < 0) {
        this.correctionValidationFailed = true;
        this.correctionDays = this.wfCorrectionDays;
        this.errorMessage = 'Timeseries correction days cannot be negative';
        this.errorMessageModel = true;
      } else {
        this.correctionValidationFailed = false;
      }
    },
    init() {
      this.manager = this.getCurrentWorkflowManager;
      this.wfCorrectionDays = this.manager._priv.correctionDays;
      this.correctionDays = this.manager._priv.correctionDays;
      if (this.manager._priv) {
        this.updateParameterSetData = this.manager._priv.updateParameterSetData;
      }
      // _scanFormulaErrors(this.manager, this);
      if (this.operation === 'edit' || this.operation === 'view') {
        this.processBubbleDiagram(this.getEditableWorkflowUI.ui);
        this.links = this.processLinksDiagram();

        // fetching all saved detalis in vueX store
        this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
        this.paramSet = this.getEditableWorkflowDetails.paramSet;
        this.parameterSetData = this.paramSet.workFlowJobModel.parameterSetGroupModel;
        this.formulae = this.getEditableWorkflowDetails.formulae;
        this.newAddedFormulas = this.getEditableWorkflowDetails.newlyAddedFormule;
        this.nodes = this.getEditableWorkflowDetails.nodes;
        this.links = this.getEditableWorkflowDetails.links;
        this.targetData = this.getEditableTargets;
        this.workflowId = this.getEditableWorkflowUI.id;
        this.workflowNameProp = this.getEditableWorkflowUI.name;
        this.prevWfName = this.getEditableWorkflowUI.name;
        if (this.operation === 'edit') {
          // -------- need to chnage----------
          // eslint-disable-next-line vue/no-mutating-props
          this.isEditable = true;
        } else if (this.operation === 'view') {
          // -------- need to chnage----------
          // eslint-disable-next-line vue/no-mutating-props
          this.isEditable = false;
        }
        this.getAllWorkflowsForDiagram().then((response) => {
          this.allWorkflows = response.data;

          this.setAllWorkflowsForName(response.data);
        }).catch((error) => {
          this.errorMessage = 'Error occurred while fetching all workflows';
          setTimeout(() => {
            this.errorMessage = '';
          }, 5000);
          this.showLoader = false;
          return false;
        });
      } else if (this.operation === 'create') {
        this.workflowUi = this.getEditableWorkflowUI.ui;
        this.processBubbleDiagram(this.getEditableWorkflowUI.ui);
        this.links = this.processLinksDiagram();
        // fetching all saved detalis in vuew store
        this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
        this.paramSet = this.getEditableWorkflowDetails.paramSet;
        this.parameterSetData = this.paramSet.workFlowJobModel.parameterSetGroupModel;
        this.formulae = this.getEditableWorkflowDetails.formulae;
        this.newAddedFormulas = this.getEditableWorkflowDetails.newlyAddedFormule;
        this.nodes = this.getEditableWorkflowDetails.nodes;
        this.links = this.getEditableWorkflowDetails.links;
        this.targetData = this.getEditableTargets;
        this.workflowUi = this.getEditableWorkflowUI.ui;
        this.workflowId = this.getEditableWorkflowUI.id;
        this.workflowNameProp = this.getEditableWorkflowUI.name;

        if (this.getallWorkflowsForName.length === 0) {
          this.checkWorkflowName();
          this.checkNameError();
        } else {
          this.allWorkflows = this.getallWorkflowsForName;
          this.checkNameError();
        }
      }
      this.$refs.diagram.setModel({
        nodes: this.nodes,
        links: this.links,
      });

      if (this.workflowData) {
        this.updateParameterSetData.currentPsgVersion = this.workflowData.workFlowJobModel.psgVersion;
      }
      // Zoom out
      const diagramEditor = this.$refs.diagram;

      // Get the SVG element inside the diagram editor
      const svgElement = diagramEditor.$el.querySelector('svg');

      // Initialize the svg-pan-zoom instance
      // Save the svg-pan-zoom instance for future reference
      this.panZoomInstance = svgPanZoom(svgElement, {
        zoomEnabled: true,
        controlIconsEnabled: true,
        fit: true,
        center: true,
      });

      // Call a custom method to set the desired zoom level (you can adjust the zoom level as needed)
      this.panZoomInstance.zoom(0.5);

      if (this.badFormulaBubbles.length > 0) {
        this.flagErrorBubble();
      }
    },
    flagErrorBubble() {
      for (let i = 0; i < this.badFormulaBubbles.length; i++) {
        this.nodes[`${this.badFormulaBubbles[i]}`].data.hasError = true;
      }
    },
    gotFormulaError(badFormulaBubbles, errorMessage) {
      // eslint-disable-next-line vue/no-mutating-props
      this.badFormulaBubbles = badFormulaBubbles;
      this.errorMessage = errorMessage;
      if (this.errorMessage) {
        for (let i = 0; i < this.badFormulaBubbles.length; i++) {
          this.nodes[`${this.badFormulaBubbles[i]}`].data.hasError = true;
        }
      } else {
        const nodes = Object.values(this.getEditableWorkflowDetails.nodes);
        for (let i = 0; i < nodes.length; i++) {
          if (this.badFormulaBubbles.includes(nodes[i].id)) {
            this.nodes[`${nodes[i].id}`].data.hasError = true;
          } else {
            this.nodes[`${nodes[i].id}`].data.hasError = false;
          }
        }
      }
      // _scanFormulaErrors(this.manager, this);
    },
    verifyDeleteLink(link) {
      if (!this.isEditable) {
        return false;
      }
      if (link && link.id) {
        this.deletedLinks(link.id);
      }
      return true;
    },
    format(node) {
      switch (node.title) {
        case ('data'):
          return this.processDataVars(node);
        case ('qa'):
          return (node.data && node.data.bubbleName) ? `$${node.data.bubbleName}` : '';
        case ('formula'):
          return (node.data && node.data.bubbleName) ? `$${node.data.bubbleName}` : '';
        case ('publish'):
          return this.processDataVars(node);
        case ('save'):
          return this.processDataVars(node);
        case ('notification'):
          return '';
        case ('single_var'):
          return this.processVariablesVars(node);
        case ('apmq_task'):
          return '';
        default:
          break;
      }
      return '';
    },
    processDataVars(node) {
      this.workflowUi = this.getEditableWorkflowDetails.workflowUI.ui;
      if (!this.workflowUi.datasets) {
        return '';
      }
      for (let i = 0; i < this.workflowUi.datasets.length; i++) {
        const element = this.workflowUi.datasets[i];
        if (node.id === element.bubbleId) {
          if (element && element.varName) {
            return `$${ element.varName}`;
          }
          return '';
        }
      }
      return '';
    },
    processVariablesVars(node) {
      this.workflowUi = this.getEditableWorkflowDetails.workflowUI.ui;
      if (!this.workflowUi.datasets) {
        return '';
      }
      for (let i = 0; i < this.workflowUi.single_vars.length; i++) {
        const element = this.workflowUi.single_vars[i];
        if (node.id === element.bubbleId) {
          if (element && element.varName) {
            return `$${ element.varName}`;
          }
          return '';
        }
      }
      return '';
    },
    nodeColor(node) {
      switch (node.title) {
        case ('data'):
          return '#e29cff';
        case ('qa'):
          return '#ff897d';
        case ('formula'):
          return '#73b8ff';
        case ('publish'):
          return '#ffdc73';
        case ('save'):
          return '#ffdc73';
        case ('notification'):
          return '#f79977';
        case ('single_var'):
          return '#7efcb4';
        case ('apmq_task'):
          return '#66CDAA';
        default:
          break;
      }
      return '#FBCFCD';
    },
    // eslint-disable-next-line no-shadow
    nodePulsable(node, type) {
      if (type === 'newNode') {
        if (node.id === this.newNodeId) {
          this.nodeModeArray = [];
          this.enableLinkCreation = false;
          this.isActiveF = false;
          this.isActiveQF = false;
          this.isActiveS = false;
          return true;
        }
      }
      if (this.nodeModeArray.includes(node.title)) {
        return true;
      }
      return false;
    },
    selectNode(nodeId, e) {},
    onOverlay(node) {
      if (node) {
        return true;
      }
      return false;
    },
    deletedNode(nodeId) {
      this.overlay = true;

      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;
      this.enableSaveBtn = true;

      this.removeParameterSetData(nodeId);

      this.removeTargetData(nodeId);

      this.removeEntryFromFormula(nodeId);

      const link = Object.values(this.links);

      if (link) {
        for (let i = 0; i < link.length; i++) {
          const element = link[i];
          if (element.end_id === nodeId || element.start_id === nodeId) {
            this.deletedLinks(element.id);
          }
        }
      }
      delete this.nodes[nodeId];
      this.$refs.diagram.setModel({
        nodes: this.nodes,
        links: this.links,
      });

      this.setCurrentEditLinks(this.links);
      this.setCurrentEditNodes(this.nodes);
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.links = this.getEditableWorkflowDetails.links;
      this.targetData = this.getEditableWorkflowDetails.targets;
      const bubbleToBeDeleted = this.manager._priv.bubbles[nodeId];
      _removeBubbleMenuCB(bubbleToBeDeleted);
      this.processFinalBubbles();
      this.processFinalLiks();

      _scanFormulaErrors(this.manager, this, this.operation);

      this.$emit('diagramChanged');
      this.overlay = false;
    },
    braodcastFormulaErrorMsg() {
      this.gotFormulaError(this.badFormulaBubbles, this.errorMessage);

      if (this.badFormulaBubbles.length > 0) {
        this.flagErrorBubble();
      }
    },
    diagramChanged() {
      this.$emit('diagramChanged');
    },
    removeParameterSetData(nodeId) {
      let currentBubbleName = '';
      let currentIndex = -1;

      // this.nodes = this.getEditableNodes;
      this.workflowUi = this.getEditableWorkflowUI.ui;

      if (this.nodes[nodeId].title === 'publish'
      || this.nodes[nodeId].title === 'save'
      || this.nodes[nodeId].title === 'data') {
        let flag = 0;
        for (let i = 0; i < this.workflowUi.datasets.length; i++) {
          const element = this.workflowUi.datasets[i];

          if (element.bubbleId === nodeId) {
            currentBubbleName = element.varName;
            currentIndex = i;
            flag = 1;
            break;
          }
        }

        if (flag === 1) {
          this.workflowUi.datasets.splice(currentIndex, 1);
        }

        if (currentBubbleName.length > 0) {
          for (let j = 0; j < this.parameterSetData.parameterSetModels.length; j++) {
            const parameterSetModel = this.parameterSetData.parameterSetModels[j];
            const arrayOfremaining = [];

            for (let k = 0; k < parameterSetModel.parameterModels.length; k++) {
              const element = parameterSetModel.parameterModels[k];
              if (element.propKey.startsWith(`udef.ds.${currentBubbleName}.`)) {
                if (Object.hasOwn(element, 'id')) {
                  this.updateParameterSetData.delete.paramIds.push(element.id);
                }
                // this.parameterSetData.parameterSetModels[j].parameterModels.splice(k, 1);
              }
            }
            this.parameterSetData.parameterSetModels[j].parameterModels = this.parameterSetData.parameterSetModels[j]
              // eslint-disable-next-line no-loop-func
              .parameterModels.filter(p => !p.propKey.startsWith(`udef.ds.${currentBubbleName}.`));
          }

          this.updateParameterSetData.add.forEach((psg) => {
            if (psg && Object.hasOwn(psg, 'parameterModels')) {
              psg.parameterModels = psg.parameterModels.filter(p => !p.propKey.startsWith(`udef.ds.${currentBubbleName}.`));
            }
          });
          this.updateParameterSetData.add = this.updateParameterSetData.add.filter(psg => psg
            && Object.hasOwn(psg, 'parameterModels') && psg.parameterModels.length > 0);
          this.updateParameterSetData.update = this.updateParameterSetData.update
            .filter(p => !p.propKey.startsWith(`udef.ds.${currentBubbleName}.`));
        }
      } else if (this.nodes[nodeId].title === 'single_var') {
        let flag = 0;
        for (let i = 0; i < this.workflowUi.single_vars.length; i++) {
          const element = this.workflowUi.single_vars[i];

          if (element.bubbleId === nodeId) {
            currentBubbleName = element.varName;
            currentIndex = i;
            flag = 1;
            break;
          }
        }

        if (flag === 1) {
          this.workflowUi.single_vars.splice(currentIndex, 1);
        }

        if (currentBubbleName.length > 0) {
          for (let j = 0; j < this.parameterSetData.parameterSetModels.length; j++) {
            const parameterSetModel = this.parameterSetData.parameterSetModels[j];
            for (let k = 0; k < parameterSetModel.parameterModels.length; k++) {
              const element = parameterSetModel.parameterModels[k];
              if (element.propKey.startsWith(`udef.var.${currentBubbleName}`)) {
                if (Object.hasOwn(element, 'id')) {
                  this.updateParameterSetData.delete.paramIds.push(element.id);
                }
                this.parameterSetData.parameterSetModels[j].parameterModels.splice(k, 1);
              }
            }
          }
          this.updateParameterSetData.add.forEach((psg) => {
            if (psg && Object.hasOwn(psg, 'parameterModels')) {
              psg.parameterModels.forEach((p, index) => {
                if (p.propKey.startsWith(`udef.var.${currentBubbleName}`)) {
                  psg.parameterModels.splice(index, 1);
                }
              });
            }
          });
          this.updateParameterSetData.add = this.updateParameterSetData.add.filter(psg => psg
            && Object.hasOwn(psg, 'parameterModels') && psg.parameterModels.length > 0);

          this.updateParameterSetData.update = this.updateParameterSetData.update
            .filter(p => !p.propKey.startsWith(`udef.var.${currentBubbleName}`));
        }
      }
      this.paramSet.workFlowJobModel.parameterSetGroupModel = this.parameterSetData;
      this.setCurrentEditParamSet({ data: this.paramSet, workflowId: this.workflowId });
      this.paramSet = this.getEditableWorkflowDetails.paramSet;
      this.parameterSetData = this.paramSet.workFlowJobModel.parameterSetGroupModel;
      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      this.workflowInfo.ui = this.workflowUi;
      this.setCurrentEditWorkflowUI(this.workflowInfo);
      this.workflowUi = this.getEditableWorkflowUI.ui;
    },
    removeTargetData(nodeId) {
      if (this.nodes[nodeId].title !== 'qa') {
        const currentNodeTargetName = this.nodes[nodeId].data.targetName;
        delete this.targetData[currentNodeTargetName];
        const currentNodeFormulaID = this.nodes[nodeId].data.formula_id ? this.nodes[nodeId].data.formula_id : '';
        const remainingEditFormulae = [];
        if (this.currentNodeFormulaID !== '') {
          for (let i = 0; i < this.getEditableFormulae.length; i++) {
            const element = this.getEditableFormulae[i];
            if (element.uuid !== currentNodeFormulaID) {
              remainingEditFormulae.push(element);
            }
          }
          this.setCurrentEditFormulae({ data: remainingEditFormulae, workflowId: this.getEditableWorkflowUI.id });

          const remainingNewFormulae = [];
          if (this.getEditableWorkflowDetails.newlyAddedFormule.length > 0) {
            for (let i = 0; i < this.getEditableFormulae.length; i++) {
              const element = this.getEditableFormulae[i];
              if (element.nodeID !== nodeId) {
                remainingNewFormulae.push(element);
              }
            }
          }
          this.setCurrentEditNewFormula(remainingNewFormulae);
          this.formulae = this.getEditableWorkflowDetails.formulae;
          this.newAddedFormulas = this.getEditableWorkflowDetails.newlyAddedFormule;
          this.nodes[nodeId].data.formula_id = '';
        }
      } else {
        const currentNodeTargetName = this.nodes[nodeId].data.targetName;
        const currentNodeTargetNameFailed = `${this.nodes[nodeId].data.targetName }_failed`;
        delete this.targetData[currentNodeTargetName];
        delete this.targetData[currentNodeTargetNameFailed];
        const currentNodeFormulaID = this.nodes[nodeId].data.formula_id ? this.nodes[nodeId].data.formula_id : '';
        const remainingEditFormulae = [];
        if (this.currentNodeFormulaID !== '') {
          for (let i = 0; i < this.getEditableFormulae.length; i++) {
            const element = this.getEditableFormulae[i];
            if (element.uuid !== currentNodeFormulaID) {
              remainingEditFormulae.push(element);
            }
          }
          this.setCurrentEditFormulae({ data: remainingEditFormulae, workflowId: this.getEditableWorkflowUI.id });

          const remainingNewFormulae = [];
          if (this.getEditableWorkflowDetails.newlyAddedFormule.length > 0) {
            for (let i = 0; i < this.getEditableFormulae.length; i++) {
              const element = this.getEditableFormulae[i];
              if (element.nodeID !== nodeId) {
                remainingNewFormulae.push(element);
              }
            }
          }
          this.setCurrentEditNewFormula(remainingNewFormulae);
          this.formulae = this.getEditableWorkflowDetails.formulae;
          this.newAddedFormulas = this.getEditableWorkflowDetails.newlyAddedFormule;
          this.nodes[nodeId].data.formula_id = '';
        }
      }
      this.setCurrentEditTargets(this.targetData);
      this.targetData = this.getEditableTargets;
      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      this.workflowInfo.targets = Object.values(this.targetData);
      this.setCurrentEditWorkflowUI(this.workflowInfo);
      this.workflowUi = this.getEditableWorkflowUI.ui;
    },
    removeEntryFromFormula(nodeId) {
      // =================================================================================================== //
      // WRITE LOGIC HERE //
      // =================================================================================================== //
    },
    deletedLinks(linkId) {
      if (!this.isEditable) {
        return false;
      }
      this.links = this.getEditableWorkflowDetails.links;
      if (this.links[linkId]) {
        const deletedLink = this.links[linkId];
        this.enableSaveBtn = true;
        delete this.links[linkId];
        this.$refs.diagram.setModel({
          nodes: this.nodes,
          links: this.links,
        });

        this.setCurrentEditLinks(this.links);
        this.setCurrentEditNodes(this.nodes);
        this.nodes = this.getEditableWorkflowDetails.nodes;
        this.links = this.getEditableWorkflowDetails.links;
        this.manager._priv.links = this.manager._priv.links
          .filter(l => l._src._id !== deletedLink.start_id || l._target._id !== deletedLink.end_id);
        this.$emit('diagramChanged');
        return true;
      }
      return false;
    },
    updatedNode(node) {
      this.enableSaveBtn = true;
      this.nodes[node.id] = node;
      this.$refs.diagram.setModel({
        nodes: this.nodes,
        links: this.links,
      });
      this.setCurrentEditLinks(this.links);
      this.setCurrentEditNodes(this.nodes);
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.links = this.getEditableWorkflowDetails.links;
      this.targetData = this.getEditableTargets;
      this.processFinalBubbles();
      this.processFinalLiks();
      this.$emit('diagramChanged');
    },
    clickPort(linkdefault) {},
    createdLink(link) {
      this.enableSaveBtn = true;
      if (this.enableLinkCreation) {
        if (this.isActiveS) {
          if (!this.verifyLinks(link, 'success')) {
            return;
          }
          link.type = 'ok';
        } else if (this.isActiveQF) {
          if (!this.verifyLinks(link, 'qaFaliure')) {
            return;
          }
          link.type = 'qa_fail';
        } else if (this.isActiveF) {
          if (!this.verifyLinks(link, 'allFaliure')) {
            return;
          }
          link.type = 'fail';
        }
        this.processFinalLiks();
        this.setCurrentEditLinks(this.links);
        this.links = this.getEditableWorkflowDetails.links;
        const src = this.manager._priv.bubbles[link.start_id];
        const dest = this.manager._priv.bubbles[link.end_id];
        _newLink(this.manager, src, dest, link.type);
        this.$emit('diagramChanged');
      } else {
        this.$refs.diagram.deleteLink(link.id);
        this.errorMessage = '<br/>Please select the connection type.';
      }
    },
    verifyLinks(link, mode) {
      const startNodeTitle = this.nodes[link.start_id].title;
      const endNodeTitle = this.nodes[link.end_id].title;
      if (this.isLinkAlraedyCreated(link)) {
        this.$refs.diagram.deleteLink(link.id);
        return 0;
      }

      if (mode === 'success') {
        if (startNodeTitle === 'data') {
          const NodeConnections = ['qa', 'formula', 'notification', 'apmq_task'];
          if (this.establishSConnection(NodeConnections, startNodeTitle, endNodeTitle, link) === 0) {
            this.$refs.diagram.deleteLink(link.id);
            return 0;
          }
        } else if (startNodeTitle === 'qa') {
          const NodeConnections = ['formula', 'notification', 'apmq_task'];
          if (this.establishSConnection(NodeConnections, startNodeTitle, endNodeTitle, link) === 0) {
            this.$refs.diagram.deleteLink(link.id);
            return 0;
          }
        } else if (startNodeTitle === 'formula') {
          const NodeConnections = ['publish', 'save', 'notification', 'apmq_task'];
          if (endNodeTitle === 'publish' || endNodeTitle === 'save') {
            if (this.isSecondConnectionToPublishBubble(link)) {
              this.$refs.diagram.deleteLink(link.id);
              return 0;
            }
          }
          if (this.establishSConnection(NodeConnections, startNodeTitle, endNodeTitle, link) === 0) {
            this.$refs.diagram.deleteLink(link.id);
            return 0;
          }
        } else if (startNodeTitle === 'publish' || startNodeTitle === 'save') {
          const NodeConnections = ['qa', 'formula', 'notification', 'apmq_task'];
          if (this.establishSConnection(NodeConnections, startNodeTitle, endNodeTitle, link) === 0) {
            this.$refs.diagram.deleteLink(link.id);
            return 0;
          }
        } else {
          this.$refs.diagram.deleteLink(link.id);
          return 0;
        }
      } else if (mode === 'qaFaliure') {
        if (startNodeTitle === 'qa') {
          const NodeConnections = ['qa', 'formula', 'notification'];
          if (this.establishQFConnection(NodeConnections, startNodeTitle, endNodeTitle, link) === 0) {
            this.$refs.diagram.deleteLink(link.id);
            return 0;
          }
        } else if (startNodeTitle === 'formula') {
          const NodeConnections = ['notification'];
          if (this.establishQFConnection(NodeConnections, startNodeTitle, endNodeTitle, link) === 0) {
            this.$refs.diagram.deleteLink(link.id);
            return 0;
          }
        } else {
          this.$refs.diagram.deleteLink(link.id);
          return 0;
        }
      } else if (mode === 'allFaliure') {
        if (startNodeTitle === 'qa') {
          const NodeConnections = ['qa', 'formula', 'notification'];
          if (this.establishAQFConnection(NodeConnections, startNodeTitle, endNodeTitle, link) === 0) {
            this.$refs.diagram.deleteLink(link.id);
            return 0;
          }
        } else if (startNodeTitle === 'formula') {
          const NodeConnections = ['notification'];
          if (this.establishAQFConnection(NodeConnections, startNodeTitle, endNodeTitle, link) === 0) {
            this.$refs.diagram.deleteLink(link.id);
            return 0;
          }
        } else {
          this.$refs.diagram.deleteLink(link.id);
          return 0;
        }
      }
      return 1;
    },
    isSecondConnectionToPublishBubble(link) {
      const links = Object.keys(this.links);
      for (let i = 0; i < links.length; i++) {
        const element = this.links[links[i]];
        if (element.end_id === link.end_id) {
          if (this.nodes[`${element.start_id}`].title === 'formula') {
            this.errorMessage = 'You must link one (and only one) Formula into Publish.';
            return true;
          }
        }
        if (element.start_id === link.end_id) {
          if (this.nodes[`${element.end_id}`].title === 'formula') {
            this.errorMessage = 'You must link one (and only one) Formula into Publish.';
            return true;
          }
        }
      }
      return false;
    },
    establishSConnection(NodeConnections, startNodeTitle, endNodeTitle, link) {
      if (NodeConnections.includes(endNodeTitle)) {
        link.id = `link-green-${ ++this.linksCount}`;
        link.type = 'ok';
        this.links[`link-green-${this.linksCount}`] = link;
        // if (!this.addDependencyInTargets(link)) {
        //   return 0;
        // }
      } else {
        this.$refs.diagram.deleteLink(link.id);
        this.errorMessageModel = true;
        this.errorMessage = `- Cannot establish connection from ${ startNodeTitle } to ${ endNodeTitle} bubbles`;
        setTimeout(() => {
          this.errorMessage = '';
        }, 5000);
        return 0;
      }
      return 1;
    },
    establishQFConnection(NodeConnections, startNodeTitle, endNodeTitle, link) {
      if (NodeConnections.includes(endNodeTitle)) {
        link.id = `link-maroon-${ ++this.linksCount}`;
        link.type = 'qa_fail';
        this.links[`link-maroon-${this.linksCount}`] = link;
        // if (!this.addDependencyInTargets(link)) {
        //   return 0;
        // }
      } else {
        this.$refs.diagram.deleteLink(link.id);
        this.errorMessageModel = true;
        this.errorMessage = `- Cannot establish connection from ${ startNodeTitle } to ${ endNodeTitle} bubbles`;
        setTimeout(() => {
          this.errorMessage = '';
        }, 5000);
        return 0;
      }
      return 1;
    },
    establishAQFConnection(NodeConnections, startNodeTitle, endNodeTitle, link) {
      if (NodeConnections.includes(endNodeTitle)) {
        link.id = `link-red-${ ++this.linksCount}`;
        link.type = 'fail';
        this.links[`link-red-${this.linksCount}`] = link;
        // if (!this.addDependencyInTargets(link)) {
        //   return 0;
        // }
      } else {
        this.$refs.diagram.deleteLink(link.id);
        this.errorMessageModel = true;
        this.errorMessage = `- Cannot  establish connection from ${ startNodeTitle } to ${ endNodeTitle} bubbles`;
        setTimeout(() => {
          this.errorMessage = '';
        }, 5000);
        return 0;
      }
      return 1;
    },
    isLinkAlraedyCreated(link) {
      const links = Object.keys(this.links);
      for (let i = 0; i < links.length; i++) {
        const element = this.links[links[i]];
        if ((element.start_id === link.start_id && element.end_id === link.end_id)
          || (element.start_id === link.start_id && element.end_id === link.start_id)
          || (element.start_id === link.end_id && element.start_id === link.start_id)
          || (element.end_id === link.start_id && element.start_id === link.end_id)
          || (element.end_id === link.end_id && element.start_id === link.start_id)
          || (element.end_id === link.end_id && element.start_id === link.end_id)) {
          return true;
        }
      }
      return false;
    },
    addDependencyInTargets(link) {
      if (this.nodes[link.end_id].title === 'notification'
        && (this.nodes[link.start_id].title === 'data'
        || this.nodes[link.start_id].title === 'save'
        || this.nodes[link.start_id].title === 'publish')
      ) {
        const bubbleName = this.format(this.nodes[link.start_id]).replace(/[^a-zA-Z0-9_]/g, '');
        if (!bubbleName) {
          this.$refs.diagram.deleteLink(link.id);
          this.errorMessageModel = true;
          this.errorMessage = `<br /> - Found 1 ${ this.nodes[link.start_id].title } bubble with no variable name.`;
          return false;
        }

        const objForTargetInfo1 = {
          type: 'kaw_sub',
          scope: 'run_local',
          feed: `{{udef.ds.${ bubbleName }.feed}}`,
          columns: `{{udef.ds.${ bubbleName }.cols}}`,
          roots: `{{udef.ds.${ bubbleName }.key}}`,
          nodeType: this.nodes[link.start_id].title,
          endNodeId: link.end_id,
        };
        const { targetName } = this.nodes[link.end_id].data;
        this.targetData[`${targetName}`].dependencies.push(objForTargetInfo1);
      } else if (this.nodes[link.end_id].title === 'notification'
        && (this.nodes[link.start_id].title === 'qa'
        || this.nodes[link.start_id].title === 'formula')) {
        if (link.type === 'ok') {
          const objForTargetInfo2 = {
            link_id: link.start_id,
            topic: 'formula.worker.script.complete',
            props: {
              uuid: this.nodes[link.start_id].data.formula_id !== '' ? this.nodes[link.start_id].data.formula_id : '',
              'formula.worker.script.status': 'success',
            },
            scope: 'run_local',
          };
          const { targetName } = this.nodes[link.end_id].data;
          this.targetData[`${targetName}`].dependencies.push(objForTargetInfo2);
        } else if (link.type === 'qa_fail' || link.type === 'fail') {
          const objForTargetInfo3 = {
            link_id: link.start_id,
            topic: 'formula.worker.script.complete',
            props: {
              uuid: this.nodes[link.start_id].data.formula_id !== '' ? this.nodes[link.start_id].data.formula_id : '',
              'formula.worker.script.status': 'failed',
              'formula.worker.script.err.cause': 'JavascriptQAException',
            },
            scope: 'run_local',
          };
          const { targetName } = this.nodes[link.end_id].data;
          this.targetData[`${targetName}`].dependencies.push(objForTargetInfo3);
        }
      } else if (this.nodes[link.end_id].title === 'formula'
        && (this.nodes[link.start_id].title === 'data')
      ) {
        const bubbleName = this.format(this.nodes[link.start_id]).replace(/[^a-zA-Z0-9_]/g, '');
        if (!bubbleName) {
          this.$refs.diagram.deleteLink(link.id);
          this.errorMessageModel = true;
          // eslint-disable-next-line max-len
          this.errorMessage = `<br /> - Found 1 ${ this.nodes[link.start_id].title } bubble with no variable name. Please try editing ${ this.nodes[link.start_id].title } bubble`;
          return false;
        }

        const objForTargetInfo1 = {
          type: 'kaw_sub',
          scope: 'run_local',
          feed: `{{udef.ds.${ bubbleName }.feed}}`,
          columns: `{{udef.ds.${ bubbleName }.cols}}`,
          roots: `{{udef.ds.${ bubbleName }.key}}`,
          nodeType: this.nodes[link.start_id].title,
          endNodeId: link.end_id,
        };
        const { targetName } = this.nodes[link.end_id].data;
        this.targetData[`${targetName}`].dependencies.push(objForTargetInfo1);
      } else if (this.nodes[link.start_id].title === 'formula'
        && (this.nodes[link.end_id].title === 'save'
        || this.nodes[link.end_id].title === 'publish')
      ) {
        const bubbleName = this.format(this.nodes[link.end_id]).replace(/[^a-zA-Z0-9_]/g, '');
      } else if (this.nodes[link.end_id].title === 'formula'
        && (this.nodes[link.start_id].title === 'qa')) {
        if (link.type === 'ok') {
          const objForTargetInfo2 = {
            link_id: link.start_id,
            topic: 'formula.worker.script.complete',
            props: {
              uuid: this.nodes[link.start_id].data.formula_id !== '' ? this.nodes[link.start_id].data.formula_id : '',
              'formula.worker.script.status': 'success',
            },
            scope: 'run_local',
          };
          const { targetName } = this.nodes[link.end_id].data;
          this.targetData[`${targetName}`].dependencies.push(objForTargetInfo2);
        } else if (link.type === 'qa_fail' || link.type === 'fail') {
          const objForTargetInfo3 = {
            link_id: link.start_id,
            topic: 'formula.worker.script.complete',
            props: {
              uuid: this.nodes[link.start_id].data.formula_id !== '' ? this.nodes[link.start_id].data.formula_id : '',
              'formula.worker.script.status': 'failed',
              'formula.worker.script.err.cause': 'JavascriptQAException',
            },
            scope: 'run_local',
          };
          const { targetName } = this.nodes[link.end_id].data;
          this.targetData[`${targetName}`].dependencies.push(objForTargetInfo3);
        }
      } else if (this.nodes[link.end_id].title === 'qa'
        && (this.nodes[link.start_id].title === 'data'
        || this.nodes[link.start_id].title === 'publish'
        || this.nodes[link.start_id].title === 'save')
      ) {
        const bubbleName = this.format(this.nodes[link.start_id]).replace(/[^a-zA-Z0-9_]/g, '');
        if (!bubbleName) {
          this.$refs.diagram.deleteLink(link.id);
          this.errorMessageModel = true;
          this.errorMessage = `<br /> - Found 1 ${ this.nodes[link.start_id].title } bubble with no variable name.`;
          return false;
        }

        const objForTargetInfo4 = {
          type: 'kaw_sub',
          scope: 'run_local',
          feed: `{{udef.ds.${ bubbleName }.feed}}`,
          columns: `{{udef.ds.${ bubbleName }.cols}}`,
          roots: `{{udef.ds.${ bubbleName }.key}}`,
          nodeType: this.nodes[link.start_id].title,
          endNodeId: link.end_id,
        };
        const { targetName } = this.nodes[link.end_id].data;
        this.targetData[`${targetName}`].dependencies.push(objForTargetInfo4);
      } else if (this.nodes[link.end_id].title === 'qa'
        && (this.nodes[link.start_id].title === 'qa')
      ) {
        if (link.type === 'ok') {
          const objForTargetInfo2 = {
            link_id: link.start_id,
            topic: 'formula.worker.script.complete',
            props: {
              uuid: this.nodes[link.start_id].data.formula_id !== '' ? this.nodes[link.start_id].data.formula_id : '',
              'formula.worker.script.status': 'success',
            },
            scope: 'run_local',
          };
          const { targetName } = this.nodes[link.end_id].data;
          this.targetData[`${targetName}`].dependencies.push(objForTargetInfo2);
        } else if (link.type === 'qa_fail' || link.type === 'fail') {
          const objForTargetInfo3 = {
            link_id: link.start_id,
            topic: 'formula.worker.script.complete',
            props: {
              uuid: this.nodes[link.start_id].data.formula_id !== '' ? this.nodes[link.start_id].data.formula_id : '',
              'formula.worker.script.status': 'failed',
              'formula.worker.script.err.cause': 'JavascriptQAException',
            },
            scope: 'run_local',
          };
          const { targetName } = this.nodes[link.end_id].data;
          this.targetData[`${targetName}`].dependencies.push(objForTargetInfo3);
        }
      }
      this.setCurrentEditTargets(this.targetData);
      this.targetData = this.getEditableTargets;
      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      this.workflowInfo.targets = Object.values(this.targetData);
      this.setCurrentEditWorkflowUI(this.workflowInfo);
      this.workflowUi = this.getEditableWorkflowUI.ui;
      return true;
    },
    highlightSucessfullNodes() {
      this.nodeModeArray = ['data', 'qa', 'publish', 'save', 'formula', 'notification', 'apmq_task'];
      this.theme = 'blue-them';
      this.nodePulsable({});
      if (this.isActiveS) {
        this.enableLinkCreation = false;
        this.isActiveS = !this.isActiveS;
        return;
      }
      this.enableLinkCreation = true;
      this.isActiveS = !this.isActiveS;
      this.isActiveQF = false;
      this.isActiveF = false;
    },
    highlightQAFailureNodes() {
      this.nodeModeArray = ['qa', 'formula', 'notification'];
      this.theme = 'maroon-them';
      this.nodePulsable({});
      if (this.isActiveQF) {
        this.enableLinkCreation = !this.enableLinkCreation;
        this.isActiveQF = !this.isActiveQF;
        return;
      }
      this.enableLinkCreation = true;
      this.isActiveQF = !this.isActiveQF;
      this.isActiveS = false;
      this.isActiveF = false;
    },
    highlightAllFailureNodes() {
      this.nodeModeArray = ['qa', 'formula', 'notification'];
      this.theme = 'red-them';
      this.nodePulsable({});
      if (this.isActiveF) {
        this.enableLinkCreation = false;
        this.isActiveF = !this.isActiveF;
        return;
      }
      this.enableLinkCreation = true;
      this.isActiveF = !this.isActiveF;
      this.isActiveS = false;
      this.isActiveQF = false;
    },
    async addNodes(nodeType, bubbleType) {
      const newBubble = _newBubble(this.manager, bubbleType, null);
      let newNode = {};
      this.enableSaveBtn = true;

      newNode = {
        title: 'data',
        size: {
          width: 150,
          height: 65,
        },
        coordinates: {
          x: Math.floor((Math.random() * (150 - 10) + 50), 2),
          y: Math.floor((Math.random() * (150 - 10) + 50), 2),
        },
        portsOut: {
          default: 'out',
        },
        portsIn: {
          default: 'in',
        },
        data: {},
        type: 'data',
      };

      newNode.type = nodeType;
      newNode.title = nodeType;
      newNode.id = newBubble._id;

      if (nodeType === 'data') {
        newNode.data = {
          expire_set: false,
          expire_time: '00:00',
          popoverData: false,
          targetName: '',
        };
        newNode.portsIn = {};
        this.newDataBubbles.push(newNode.id);
      } else if (nodeType === 'qa') {
        this.qaCount++;
        const qaTargetData = {
          name: `qa_${ this.qaCount}`,
          nodeID: newNode.id,
          dependencies: [
          ],
          tasks: [
            {
              topic: 'run.formula_script',
              suppressDuration: 0,
              props: {
                uuid: '',
                bypass_formula_schedule: 'true',
              },
              override: false,
            },
          ],
          required: true,
        };
        const qaTargetDataFailed = {
          name: `qa_${ this.qaCount}_failed`,
          nodeID: newNode.id,
          dependencies: [
            {
              topic: 'formula.worker.script.complete',
              props: {
                uuid: '',
                'formula.worker.script.status': 'failed',
              },
              scope: 'run_local',
            },
          ],
          tasks: [],
          user_actions: [
            {
              type: 'approve',
              msg: 'Skip error and continue?',
              tasks: [
                {
                  topic: 'formula.worker.script.complete',
                  suppressDuration: 0,
                  props: {
                    'formula.worker.script.status': 'success',
                  },
                  override: true,
                },
              ],
            },
          ],
          required: false,
        };
        newNode.data = {
          targetInfo: [
            qaTargetData, qaTargetDataFailed,
          ],
          targetName: `qa_${ this.qaCount}`,
        };
        this.newQABubbles.push(newNode.id);
        this.targetData[`qa_${ this.qaCount}`] = qaTargetData;
        this.targetData[`qa_${ this.qaCount}_failed`] = qaTargetDataFailed;
      } else if (nodeType === 'formula') {
        this.formulaCount++;
        const formulaTargetData = {
          name: `formula_${ this.formulaCount}`,
          nodeID: newNode.id,
          dependencies: [
          ],
          tasks: [
            {
              topic: 'run.formula_script',
              suppressDuration: 0,
              props: {
                uuid: '',
                bypass_formula_schedule: 'true',
              },
              override: false,
            },
          ],
          required: true,
        };
        newNode.data = {
          formula_id: '',
          bubbleName: '',
          bubbleDescr: '',
          targetInfo: formulaTargetData,
          targetName: `formula_${ this.formulaCount}`,
        };
        this.newFormulaBubbles.push(newNode.id);
        this.targetData[`formula_${ this.formulaCount}`] = formulaTargetData;
      } else if (nodeType === 'save') {
        newNode.data = {
          expire_set: false,
          targetName: '',
        };
        newNode.type = 'save';
        newNode.title = 'publish';
        this.newPublishBubbles.push(newNode.id);
      } else if (nodeType === 'notification') {
        this.notifCount++;
        const notifTargetData = {
          name: `notif_${this.notifCount}`,
          nodeID: newNode.id,
          dependencies: [
          ],
          tasks: [
            {
              topic: 'email.workflow',
              suppressDuration: 0,
              props: {
                recipients: '',
                subject: 'QA Alerts {{workflow.name}} {{parameter-set.name}}',
                // eslint-disable-next-line max-len
                body: '\n\nMessage: {{formula.worker.script.err.msg|N/A}}\n\nWorkflow Name: {{workflow.name}}\nVariable Set: {{parameter-set.name}}\nVariable Description: {{parameter-set.description}}\n\n\nTechnical Details:\nStatus: {{formula.worker.script.status|N/A}}\nTimestamp: {{workflow.event_time|N/A}}\nWorkflow ID: {{workflow.id|N/A}}\nScript ID: {{uuid|N/A}}\nRun ID: {{workflow.run.id|N/A}}',
              },
              override: false,
            },
          ],
          required: false,
        };
        newNode.data = {
          // eslint-disable-next-line max-len
          msg: '\n\nMessage: {{formula.worker.script.err.msg|N/A}}\n\nWorkflow Name: {{workflow.name}}\nVariable Set: {{parameter-set.name}}\nVariable Description: {{parameter-set.description}}\n\n\nTechnical Details:\nStatus: {{formula.worker.script.status|N/A}}\nTimestamp: {{workflow.event_time|N/A}}\nWorkflow ID: {{workflow.id|N/A}}\nScript ID: {{uuid|N/A}}\nRun ID: {{workflow.run.id|N/A}}',
          subject: 'QA Alerts {{workflow.name}} {{parameter-set.name}}',
          to: '',
          targetInfo: notifTargetData,
          targetName: `notif_${this.notifCount}`,
        };
        newNode.portsOut = {};
        this.newNotificationBubbles.push(newNode.id);
        this.targetData[`notif_${this.notifCount}`] = notifTargetData;
      } else if (nodeType === 'single_var' || nodeType === 'variable') {
        newNode.data = {
          expire_set: false,
          targetName: '',
        };
        newNode.portsIn = {};
        newNode.portsOut = {};
        this.newVaribaleBubbles.push(newNode.id);
      } else if (nodeType === 'apmq_task') {
        newNode.data = {
          expire_set: false,
        };
        this.newAPMQTaskBubbles.push(newNode.id);
      } else {
        newNode.data = {};
      }

      this.$refs.diagram.addNode(newNode);
      this.nodes[newNode.id] = newNode;
      this.newNodeId = newNode.id;
      this.nodePulsable(newNode, 'newNode');
      this.theme = 'new-node-them';

      this.setCurrentEditNodes(this.nodes);
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.setCurrentEditTargets(this.targetData);
      this.targetData = this.getEditableTargets;
      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      this.workflowInfo.targets = Object.values(this.targetData);
      this.setCurrentEditWorkflowUI(this.workflowInfo);
      this.workflowUi = this.getEditableWorkflowUI.ui;
      this.processFinalBubbles();
      newNode = {};
      this.$emit('diagramChanged');
    },
    processBubbleDiagram() {
      this.workflowUi = this.getEditableWorkflowDetails.workflowUI.ui;
      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;

      if (this.workflowUi.bubbles) {
        for (let i = 0; i < this.workflowUi.bubbles.length; i++) {
          let newNode = {};
          if (this.workflowUi.bubbles[i].type === 'data') {
            newNode = {
              title: 'data',
              type: 'data',
              size: {
                width: 150,
                height: 65,
              },
              coordinates: {
                x: -47.5,
                y: 409,
              },
              portsOut: {
                default: 'out',
              },
              data: {
                expire_set: false,
                _isNewNode: false,
                expire_time: '00:00',
                popoverData: false,
                targetName: this.processDataVars(this.workflowUi.bubbles[i]),
              },
            };
            newNode.data = this.workflowUi.bubbles[i].props;
            newNode.data._isNewNode = false;
            this.prevDataBubbles.push(this.workflowUi.bubbles[i].id);
          } else if (this.workflowUi.bubbles[i].type === 'qa') {
            this.qaCount++;
            newNode = {
              type: 'qa',
              title: 'qa',
              size: {
                width: 150,
                height: 65,
              },
              coordinates: {
                x: 146.5,
                y: 320,
              },
              portsOut: {
                default: 'out',
              },
              portsIn: {
                default: 'in',
              },
              data: {
                expire_set: false,
                _isNewNode: false,
              },
            };
            newNode.data = this.workflowUi.bubbles[i].props;
            newNode.data._isNewNode = false;
            newNode.data.targetInfo = [];
            for (let j = 0; j < this.workflowInfo.targets.length; j++) {
              const element = this.workflowInfo.targets[j];

              if (`qa_${this.qaCount}` === element.name || `qa_${this.qaCount}_failed` === element.name) {
                element.nodeID = this.workflowUi.bubbles[i].id;
                newNode.data.targetInfo.push(element);
                this.targetData[element.name] = element;
              }
            }
            newNode.data.targetName = `qa_${ this.qaCount}`;
            this.prevQABubbles.push(this.workflowUi.bubbles[i].id);
          } else if (this.workflowUi.bubbles[i].type === 'formula') {
            // if (this.badFormulaIdBubbles.length > 0) {
            //   if (this.badFormulaIdBubbles.includes(this.workflowUi.bubbles[i].id)) {
            //     this.workflowUi.bubbles[i].id = `${this.workflowUi.bubbles[i].id} badbubble`;
            //   }
            // }
            this.formulaCount++;
            newNode = {
              type: 'formula',
              title: 'formula',
              size: {
                width: 150,
                height: 65,
              },
              coordinates: {
                x: 329.5,
                y: 247,
              },
              portsOut: {
                default: 'out',
              },
              portsIn: {
                default: 'in',
              },
              data: {
                formula_id: '',
                bubbleName: '',
                bubbleDescr: '',
                _isNewNode: false,
              },
            };
            newNode.data = this.workflowUi.bubbles[i].props;
            newNode.data._isNewNode = false;
            newNode.data.targetInfo = [];
            for (let j = 0; j < this.workflowInfo.targets.length; j++) {
              const element = this.workflowInfo.targets[j];
              if (`formula_${ this.formulaCount }` === element.name) {
                element.nodeID = this.workflowUi.bubbles[i].id;
                newNode.data.targetInfo = element;
                this.targetData[element.name] = element;
                break;
              }
            }
            for (let j = 0; j < this.formulae.length; j++) {
              const element = this.formulae[j];
              if (this.workflowUi.bubbles[i].props.formula_id === element.uuid) {
                element.nodeID = this.workflowUi.bubbles[i].id;
                // eslint-disable-next-line vue/no-mutating-props
                this.formulae[j] = element;
              }
            }
            newNode.data.targetName = `formula_${ this.formulaCount }`;
            this.prevFormulaBubbles.push(this.workflowUi.bubbles[i].id);
          } else if (this.workflowUi.bubbles[i].type === 'save') {
            newNode = {
              type: 'save',
              title: 'publish',
              size: {
                width: 150,
                height: 65,
              },
              coordinates: {
                x: 359.5,
                y: 227,
              },
              portsOut: {
                default: 'out',
              },
              portsIn: {
                default: 'in',
              },
              data: {
                expire_set: false,
                _isNewNode: false,
                targetName: this.workflowUi.bubbles[i].props.srcVar,
              },
            };
            newNode.data = this.workflowUi.bubbles[i].props;
            newNode.data._isNewNode = false;
            this.prevPublishBubbles.push(this.workflowUi.bubbles[i].id);
          } else if (this.workflowUi.bubbles[i].type === 'publish') {
            newNode = {
              type: 'save',
              title: 'publish',
              size: {
                width: 150,
                height: 65,
              },
              coordinates: {
                x: 429.5,
                y: 187,
              },
              portsOut: {
                default: 'out',
              },
              portsIn: {
                default: 'in',
              },
              data: {
                expire_set: false,
                _isNewNode: false,
                targetName: this.workflowUi.bubbles[i].props.srcVar,
              },
            };
            newNode.data = this.workflowUi.bubbles[i].props;
            newNode.data._isNewNode = false;
            this.prevPublishBubbles.push(this.workflowUi.bubbles[i].id);
          } else if (this.workflowUi.bubbles[i].type === 'notification') {
            this.notifCount++;
            newNode = {
              type: 'notification',
              title: 'notification',
              size: {
                width: 150,
                height: 65,
              },
              coordinates: {
                x: 389.5,
                y: 207,
              },
              portsIn: {
                default: 'in',
              },
              data: {
                _isNewNode: false,
              },
              targetName: '',
            };
            newNode.data = this.workflowUi.bubbles[i].props;
            newNode.data._isNewNode = false;
            newNode.data.targetInfo = [];
            for (let j = 0; j < this.workflowInfo.targets.length; j++) {
              const element = this.workflowInfo.targets[j];
              if (`notif_${ this.notifCount }` === element.name) {
                element.nodeID = this.workflowUi.bubbles[i].id;
                newNode.data.targetInfo = element;
                this.targetData[element.name] = element;
                break;
              }
            }
            newNode.data.targetName = `notif_${ this.notifCount }`;
            this.prevNotificationBubbles.push(this.workflowUi.bubbles[i].id);
          } else if (this.workflowUi.bubbles[i].type === 'single_var') {
            newNode = {
              title: 'single_var',
              type: 'single_var',
              size: {
                width: 150,
                height: 65,
              },
              coordinates: {
                x: 459.5,
                y: 167,
              },
              data: {
                expire_set: false,
                _isNewNode: false,
                targetName: this.processVariablesVars(this.workflowUi.bubbles[i]),
              },
            };
            newNode.data = this.workflowUi.bubbles[i].props;
            newNode.data._isNewNode = false;
            this.prevVaribaleBubbles.push(this.workflowUi.bubbles[i].id);
          } else if (this.workflowUi.bubbles[i].type === 'apmq_task') {
            newNode = {
              title: 'apmq_task',
              type: 'apmq_task',
              size: {
                width: 150,
                height: 65,
              },
              coordinates: {
                x: 489.5,
                y: 147,
              },
              portsOut: {
                default: 'out',
              },
              portsIn: {
                default: 'in',
              },
              data: {
                expire_set: false,
                _isNewNode: false,
              },
            };
            newNode.data._isNewNode = false;
            this.prevAPMQTaskBubbles.push(this.workflowUi.bubbles[i].id);
          } else {
            console.log('missed node!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
          }

          newNode.id = this.workflowUi.bubbles[i].id;
          newNode.coordinates.x = this.workflowUi.bubbles[i].coord.x;
          newNode.coordinates.y = this.workflowUi.bubbles[i].coord.y;
          this.manager._priv.bubbles[`${ this.workflowUi.bubbles[i].id }`]._data._isNewNode = false;
          this.nodes[`${ this.workflowUi.bubbles[i].id }`] = newNode;
          newNode = null;
        }
        this.setCurrentEditNodes(this.nodes);
        this.nodes = this.getEditableWorkflowDetails.nodes;
      }
      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      for (let j = 0; j < this.workflowInfo.targets.length; j++) {
        const element = this.workflowInfo.targets[j];
        if (element.name === 'workflow.completion') {
          this.targetData[element.name] = element;
        }
        if (element.name === 'workflow_timeout') {
          this.targetData[element.name] = element;
        }
      }
      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      this.workflowInfo.targets = Object.values(this.targetData);
      this.setCurrentEditWorkflowUI(this.workflowInfo);
      this.workflowUi = this.getEditableWorkflowUI.ui;
      this.setCurrentEditTargets(this.targetData);
      this.targetData = this.getEditableTargets;
    },
    processLinksDiagram() {
      const link = {};
      for (let i = 0; i < this.workflowUi.links.length; i++) {
        const singlelink = {};
        singlelink.start_id = this.workflowUi.links[i].source;
        singlelink.end_id = this.workflowUi.links[i].target;
        singlelink.type = this.workflowUi.links[i].type;
        singlelink.start_port = 'default';
        singlelink.end_port = 'default';
        let linkvar = 'link';

        if (singlelink.type === 'ok') {
          linkvar = 'link-green';
          singlelink.id = `link-green-${ ++this.linksCount}`;
        } else if (singlelink.type === 'fail') {
          linkvar = 'link-red';
          singlelink.id = `link-red-${ ++this.linksCount}`;
        } else if (singlelink.type === 'qa_fail') {
          linkvar = 'link-maroon';
          singlelink.id = `link-maroon-${ ++this.linksCount}`;
        }

        link[`${linkvar}-${ this.linksCount}`] = singlelink;
      }
      this.setCurrentEditLinks(link);
      return link;
    },
    openActionPopup(node, nodeVarName) {
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;
      this.actionNode = node;
      this.actionNodeVarName = nodeVarName;
      const currentBubble = this.getCurrentWorkflowManager._priv.bubbles[node.id];
      if (node.title === 'formula') {
        this.isNewNode = currentBubble._data._isNewNode;
      } else if (node.title === 'data') {
        this.isNewNode = currentBubble._data._isNewNode;
      } else if (node.title === 'variable' || node.title === 'single_var') {
        this.isNewNode = currentBubble._data._isNewNode;
      } else if (node.title === 'notification') {
        this.isNewNode = currentBubble._data._isNewNode;
      } else if (node.title === 'qa') {
        this.isNewNode = currentBubble._data._isNewNode;
      } else if (node.title === 'save' || node.title === 'publish') {
        if (this.getCurrentWorkflowManager._priv.bubbles[node.id]._ins.length !== 1) {
          this.errorMessage = 'You must link one (and only one) Formula into Publish.';
          return;
        }

        const varsToSave = _getVarsInFormula(this.getCurrentWorkflowManager._priv.bubbles[node.id]._ins[0]._src, {});
        if (Object.keys(varsToSave).length === 0) {
          this.errorMessage = 'Cannot find any variable being defined in the previous formula'
                           + ' nothing to publish.<br />Try editing the formula first.';
          return;
        }

        this.isNewNode = currentBubble._data._isNewNode;
        if (this.checkNodeConnection(node)) {
          return;
        }
      }
      this.toggleEdit = !this.toggleEdit;
    },
    checkNodeConnection(node) {
      const link = Object.values(this.links);
      let flag = 0;
      for (let j = 0; j < link.length; j++) {
        const element = link[j];
        if (element.end_id === node.id) {
          if (this.nodes[element.start_id].title === 'formula') {
            this.nodes[node.id].data.connectedFormulaNodeId = element.start_id;
            flag = 1;
            break;
          }
        }
      }
      if (flag === 0) {
        this.errorMessage = 'You must link one (and only one) Formula into Publish.';
        setTimeout(() => {
          this.errorMessage = '';
        }, 5000);
        return 1;
      }
      return 0;
    },
    resetZoom() {
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;
      this.$refs.diagram.resetZoom();
      this.panZoomInstance.zoom(0.5);
    },
    fit() {
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;
      this.$refs.diagram.fit();
    },
    processFinalBubbles() {
      const bubbleArr = [];
      const nodes = Object.values(this.getEditableWorkflowDetails.nodes);

      for (let i = 0; i < nodes.length; i++) {
        const bubbleObj = {
          id: '',
          type: '',
          props: {},
          coord: {},
          title: '',
        };
        const element = nodes[i];
        bubbleObj.id = element.id;
        bubbleObj.type = element.title;
        bubbleObj.title = element.title;
        bubbleObj.props = element.data;
        bubbleObj.coord = element.coordinates;

        bubbleArr.push(bubbleObj);
      }
      this.workflowUi.bubbles = bubbleArr;
      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      this.workflowInfo.ui = this.workflowUi;
      this.setCurrentEditWorkflowUI(this.workflowInfo);
      this.workflowUi = this.getEditableWorkflowUI.ui;
    },
    processFinalLiks() {
      const linkArr = [];
      const link = Object.values(this.getEditableWorkflowDetails.links);

      for (let j = 0; j < link.length; j++) {
        const linkObj = {
          source: '',
          target: '',
          type: '',
        };
        const element = link[j];

        linkObj.source = element.start_id;
        linkObj.target = element.end_id;
        linkObj.type = element.type;

        linkArr.push(linkObj);
      }
      this.workflowUi.links = linkArr;
      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      this.workflowInfo.ui = this.workflowUi;
      this.setCurrentEditWorkflowUI(this.workflowInfo);
      this.workflowUi = this.getEditableWorkflowUI.ui;
    },
    checkWorkflowName() {
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;
      this.showLoader = true;
      const currentWFName = this.workflowNameProp;

      // eslint-disable-next-line consistent-return
      this.getAllWorkflowsForDiagram().then((response) => {
        this.allWorkflows = response.data;

        this.setAllWorkflowsForName(response.data);

        for (let i = 0; i < this.allWorkflows.length; i++) {
          const element = this.allWorkflows[i];

          if (element.name === currentWFName && currentWFName !== this.prevWfName) {
            this.wfNameError = true;
            this.showLoader = false;
            return true;
          }
        }
      }).catch((error) => {
        this.errorMessage = 'Error occurred while fetching all workflows';
        setTimeout(() => {
          this.errorMessage = '';
        }, 5000);
        this.showLoader = false;
        return false;
      }).finally(() => {
        this.showLoader = false;
        this.$nextTick(() => {
          this.$refs.diagram.setModel({
            nodes: this.nodes,
            links: this.links,
          });
        });
      });
    },
    checkNameError() {
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;

      this.wfNameError = '';

      if (this.workflowNameProp !== this.prevWfName) {
        for (let i = 0; i < this.allWorkflows.length; i++) {
          const element = this.allWorkflows[i];
          if (element.name === this.workflowNameProp) {
            this.wfNameError = true;
            return true;
          }
        }
      } else {
        this.wfNameError = false;
        return false;
      }
      return false;
    },
    editWorkflowName() {
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;
      if (this.checkNameError()) {
        this.$emit('wfNameErrorFun', true);
        return;
      }
      this.$emit('wfNameErrorFun', false);
      if (this.workflowNameProp !== this.prevWfName) {
        this.$emit('diagramChanged');
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.workflow.name = this.workflowNameProp;
      this.$emit('editWorkflowNameFun', this.workflowNameProp);
    },
    dissmissError() {
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;
      this.errorMessage = '';
    },
    closeEdit() {
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;
      this.toggleEdit = false;
    },
    newFormulaAdded(objformula, node) {
      this.manager = this.getCurrentWorkflowManager;
      this.manager._priv.bubbles[node.id]._data._isNewNode = false;
      this.setCurrentWorkflowManager(this.manager);
      
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.nodes[node.id] = node;
      this.setCurrentEditNodes(this.nodes);
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.processFinalBubbles();

      this.formulae = this.getEditableWorkflowDetails.formulae;
      this.toggleEdit = false;
      this.$emit('diagramChanged');
    },
    newQAFormulaAdded(objformula, node) {
      this.manager = this.getCurrentWorkflowManager;
      this.manager._priv.bubbles[node.id]._data._isNewNode = false;
      this.setCurrentWorkflowManager(this.manager);
      
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.nodes[node.id] = node;
      this.setCurrentEditNodes(this.nodes);
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.processFinalBubbles();

      this.formulae = this.getEditableWorkflowDetails.formulae;
      this.toggleEdit = false;
      this.$emit('diagramChanged');
    },
    notificationEdit(currentNode) {
      this.manager = this.getCurrentWorkflowManager;
      this.manager._priv.bubbles[currentNode.id]._data._isNewNode = false;
      this.setCurrentWorkflowManager(this.manager);
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.nodes[currentNode.id] = currentNode;
      this.setCurrentEditNodes(this.nodes);
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.processFinalBubbles();
      this.targetData[`${currentNode.data.targetName}`] = currentNode.data.targetInfo;
      this.setCurrentEditTargets(this.targetData);
      this.targetData = this.getEditableTargets;
      this.toggleEdit = !this.toggleEdit;

      this.$emit('diagramChanged');
    },
    dataBubbleEdited(parameterSetData, currentInputToEdit, node) {
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;
      this.manager = this.getCurrentWorkflowManager;
      this.toggleEdit = false;
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.nodes[node.id] = node;
      this.setCurrentEditNodes(this.nodes);
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.processFinalBubbles();

      const datasetObj = {
        varName: currentInputToEdit.name,
        bubbleId: node.id,
      };

      this.workflowUi = this.getEditableWorkflowUI.ui;
      for (let i = 0; i < this.workflowUi.datasets.length; i++) {
        const element = this.workflowUi.datasets[i];
        if (element.bubbleId === datasetObj.bubbleId) {
          element.varName = currentInputToEdit.name;
        }
      }

      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      this.workflowInfo.ui = this.workflowUi;
      this.setCurrentEditWorkflowUI(this.workflowInfo);
      this.workflowUi = this.getEditableWorkflowUI.ui;

      this.paramSet.workFlowJobModel.parameterSetGroupModel = parameterSetData;
      this.setCurrentEditParamSet({ data: this.paramSet, workflowId: this.workflowId });
      this.paramSet = this.getEditableWorkflowDetails.paramSet;
      this.parameterSetData = this.paramSet.workFlowJobModel.parameterSetGroupModel;
      _scanFormulaErrors(this.manager, this);
      this.flagErrorBubble();

      this.$emit('diagramChanged');
    },
    newDataBubbleAdded(parameterSetData, currentInputToEdit, node) {
      this.manager = this.getCurrentWorkflowManager;
      this.manager._priv.bubbles[node.id]._data._isNewNode = false;
      this.setCurrentWorkflowManager(this.manager);

      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;
      this.toggleEdit = false;
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.nodes[node.id] = node;
      this.setCurrentEditNodes(this.nodes);
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.processFinalBubbles();

      const datasetObj = {
        varName: currentInputToEdit.name,
        bubbleId: node.id,
      };

      this.workflowUi = this.getEditableWorkflowUI.ui;
      this.workflowUi.datasets.push(datasetObj);

      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      this.workflowInfo.ui = this.workflowUi;
      this.setCurrentEditWorkflowUI(this.workflowInfo);
      this.workflowUi = this.getEditableWorkflowUI.ui;

      this.paramSet.workFlowJobModel.parameterSetGroupModel = parameterSetData;
      this.setCurrentEditParamSet({ data: this.paramSet, workflowId: this.workflowId });
      this.paramSet = this.getEditableWorkflowDetails.paramSet;
      this.parameterSetData = this.paramSet.workFlowJobModel.parameterSetGroupModel;

      const index = this.newDataBubbles.indexOf(node.id);
      this.newDataBubbles.splice(index, 1);
      _scanFormulaErrors(this.manager, this);
      this.flagErrorBubble();

      this.$emit('diagramChanged');
    },
    publishBubbleEdited(parameterSetData, currentInputToEdit, node) {
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;

      this.manager = this.getCurrentWorkflowManager;
      this.toggleEdit = false;
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.nodes[node.id] = node;
      this.setCurrentEditNodes(this.nodes);
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.processFinalBubbles();

      const datasetObj = {
        varName: currentInputToEdit.name,
        bubbleId: node.id,
      };

      this.workflowUi = this.getEditableWorkflowUI.ui;
      for (let i = 0; i < this.workflowUi.datasets.length; i++) {
        const element = this.workflowUi.datasets[i];
        if (element.bubbleId === datasetObj.bubbleId) {
          element.varName = currentInputToEdit.name;
        }
      }

      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      this.workflowInfo.ui = this.workflowUi;
      this.setCurrentEditWorkflowUI(this.workflowInfo);
      this.workflowUi = this.getEditableWorkflowUI.ui;

      this.paramSet.workFlowJobModel.parameterSetGroupModel = parameterSetData;
      this.setCurrentEditParamSet({ data: this.paramSet, workflowId: this.workflowId });
      this.paramSet = this.getEditableWorkflowDetails.paramSet;
      this.parameterSetData = this.paramSet.workFlowJobModel.parameterSetGroupModel;
      _scanFormulaErrors(this.manager, this);
      this.flagErrorBubble();

      this.$emit('diagramChanged');
    },
    newPublishBubbleAdded(parameterSetData, currentInputToEdit, node) {
      this.manager = this.getCurrentWorkflowManager;
      this.manager._priv.bubbles[node.id]._data._isNewNode = false;
      this.setCurrentWorkflowManager(this.manager);
      
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;
      this.toggleEdit = false;

      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.nodes[node.id] = node;
      this.setCurrentEditNodes(this.nodes);
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.processFinalBubbles();

      const datasetObj = {
        varName: currentInputToEdit.name,
        bubbleId: node.id,
      };

      this.targetData = this.getEditableTargets;
      const dependency = {
        type: 'kaw_sub',
        scope: 'run_local',
        timeout: null,
        feed: `{{udef.ds.${currentInputToEdit.name}.feed}}`,
        columns: `{{udef.ds.${currentInputToEdit.name}.cols}}`,
        roots: `{{udef.ds.${currentInputToEdit.name}.key}}`,
      };
      this.targetData['workflow.completion'].dependencies.push(dependency);
      this.setCurrentEditTargets(this.targetData);
      this.targetData = this.getEditableTargets;

      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      this.workflowInfo.targets = Object.values(this.targetData);
      this.setCurrentEditWorkflowUI(this.workflowInfo);

      this.workflowUi = this.getEditableWorkflowUI.ui;
      this.workflowUi.datasets.push(datasetObj);

      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      this.workflowInfo.ui = this.workflowUi;
      this.setCurrentEditWorkflowUI(this.workflowInfo);

      this.workflowUi = this.getEditableWorkflowUI.ui;
      this.paramSet.workFlowJobModel.parameterSetGroupModel = parameterSetData;
      this.setCurrentEditParamSet({ data: this.paramSet, workflowId: this.workflowId });
      this.paramSet = this.getEditableWorkflowDetails.paramSet;
      this.parameterSetData = this.paramSet.workFlowJobModel.parameterSetGroupModel;

      const index = this.newPublishBubbles.indexOf(node.id);
      this.newPublishBubbles.splice(index, 1);
      _scanFormulaErrors(this.manager, this);
      this.flagErrorBubble();

      this.$emit('diagramChanged');
    },
    variableBubbleEdited_method(parameterSetData, currentInputToEdit, node) {
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;

      this.manager = this.getCurrentWorkflowManager;
      this.toggleEdit = false;
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.nodes[node.id] = node;
      this.setCurrentEditNodes(this.nodes);
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.processFinalBubbles();

      // add the single_var value in ui=>single_var
      const datasetObj = {
        varName: currentInputToEdit.name,
        bubbleId: node.id,
        varType: currentInputToEdit.type,
      };

      this.workflowUi = this.getEditableWorkflowUI.ui;
      for (let i = 0; i < this.workflowUi.single_vars.length; i++) {
        const element = this.workflowUi.single_vars[i];
        if (element.bubbleId === datasetObj.bubbleId) {
          element.varName = currentInputToEdit.name;
          element.varType = currentInputToEdit.type;
          this.workflowUi.single_vars[i] = element;
        }
      }

      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      this.workflowInfo.ui = this.workflowUi;
      this.setCurrentEditWorkflowUI(this.workflowInfo);
      this.workflowUi = this.getEditableWorkflowUI.ui;
      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;

      this.paramSet.workFlowJobModel.parameterSetGroupModel = parameterSetData;
      this.setCurrentEditParamSet({ data: this.paramSet, workflowId: this.workflowId });
      this.paramSet = this.getEditableWorkflowDetails.paramSet;
      this.parameterSetData = this.paramSet.workFlowJobModel.parameterSetGroupModel;

      _scanFormulaErrors(this.manager, this);
      this.flagErrorBubble();

      this.$emit('diagramChanged');
    },
    newVariableBubbleAdded(parameterSetData, currentInputToEdit, node) {
      this.manager = this.getCurrentWorkflowManager;
      this.manager._priv.bubbles[node.id]._data._isNewNode = false;
      this.setCurrentWorkflowManager(this.manager);
      
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;
      this.toggleEdit = false;

      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.nodes[node.id] = node;
      this.setCurrentEditNodes(this.nodes);
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.processFinalBubbles();
      // add the single_var value in ui=>single_var
      const datasetObj = {
        varName: currentInputToEdit.name,
        bubbleId: node.id,
        varType: currentInputToEdit.type,
      };

      this.workflowUi = this.getEditableWorkflowUI.ui;
      this.workflowUi.single_vars.push(datasetObj);
      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      this.workflowInfo.ui = this.workflowUi;
      this.setCurrentEditWorkflowUI(this.workflowInfo);
      this.workflowUi = this.getEditableWorkflowUI.ui;
      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;

      this.paramSet.workFlowJobModel.parameterSetGroupModel = parameterSetData;
      this.setCurrentEditParamSet({ data: this.paramSet, workflowId: this.workflowId });
      this.paramSet = this.getEditableWorkflowDetails.paramSet;
      this.parameterSetData = this.paramSet.workFlowJobModel.parameterSetGroupModel;

      const index = this.newVaribaleBubbles.indexOf(node.id);
      this.newVaribaleBubbles.splice(index, 1);
      _scanFormulaErrors(this.manager, this);
      this.flagErrorBubble();


      this.$emit('diagramChanged');
    },
    updateFormulaOfconnectedNode(currentInputToEdit, node) {
      this.formulae = this.getEditableWorkflowDetails.formulae;
      const date = new Date();
      let currentFormulaToUpdate = {};
      let currentFormulaToUpdateObj = {};
      const formulanumber = this.formulae.length;
      const nameDate = `${date.getFullYear()
        + (`0${ date.getMonth() + 1}`).slice(-2)
        // eslint-disable-next-line max-len
        + (`0${ date.getDate()}`).slice(-2)}T${ (`0${ date.getHours()}`).slice(-2) }${(`0${ date.getMinutes()}`).slice(-2) }${(`0${ date.getSeconds()}`).slice(-2)}`;

      for (let i = 0; i < this.formulae.length; i++) {
        const element = this.formulae[i];
        if (node.data.connectedFormulaNodeId === element.nodeID) {
          currentFormulaToUpdate = element.formula;
          this.formulae[i].formula = this.modifyFormula(currentInputToEdit, currentFormulaToUpdate.toString());
          this.formulae[i].insertedTime = new Date(`${new Date().toString().split('GMT')[0]} UTC`).toISOString();
          this.formulae[i].name = `wfgen_${this.workflow.name}_${nameDate}_${formulanumber}`;

          currentFormulaToUpdateObj = this.formulae[i];
        }
      }
      this.setCurrentEditFormulae({ data: this.formulae, workflowId: this.getEditableWorkflowUI.id });
      this.formulae = this.getEditableWorkflowDetails.formulae;

      this.newAddedFormulas = this.getEditableNewFormulae;
      if (this.newAddedFormulas && this.newAddedFormulas.length > 0) {
        for (let i = 0; i < this.newAddedFormulas.length; i++) {
          const element = this.newAddedFormulas[i];

          if (node.data.connectedFormulaNodeId === this.newAddedFormulas[i].nodeID) {
            currentFormulaToUpdate = element.formula;
            // eslint-disable-next-line vue/no-mutating-props
            this.newAddedFormulas[i] = currentFormulaToUpdateObj;
          }
        }
      } else {
        this.newAddedFormulas.push(currentFormulaToUpdateObj);
      }
      this.setCurrentEditNewFormula(this.newAddedFormulas);
      this.newAddedFormulas = this.getEditableNewFormulae;
    },
    modifyFormula(currentInputToEdit, currentFormulaToUpdate) {
      const currentFormulaToUpdate1 = this.addOutputDataSet(currentInputToEdit, currentFormulaToUpdate);
      return this.addSaveSeriesPart(currentInputToEdit, currentFormulaToUpdate1);
    },
    addOutputDataSet(currentInputToEdit, currentFormulaToUpdate) {
      const outputInitComment = '/*&ANALYZE_SUSPEND OUTPUT_DATASET_DECLARATION*/\n\n';
      const outputEndComment = '/*&ANALYZE_RESUME OUTPUT_DATASET_DECLARATION*/\n\n';
      let saveVar = '';

      const prevPart = currentFormulaToUpdate.split(outputInitComment)[0];
      let endPart = currentFormulaToUpdate.split(outputInitComment)[1];
      // eslint-disable-next-line prefer-destructuring
      const saveVar1 = endPart.split(outputEndComment)[0];
      // eslint-disable-next-line prefer-destructuring
      endPart = endPart.split(outputEndComment)[1];

      if (saveVar1 === '') {
        saveVar = `${`var $${ currentInputToEdit.name } = morn.Product.create(\n`
          + '    Parameters.getString(\'udef.ds.'}${ currentInputToEdit.name }.feed'),\n`
          + `    Parameters.getJson('udef.ds.${ currentInputToEdit.name }.key'),\n`
          + `    Parameters.getJson('udef.ds.${ currentInputToEdit.name }.cols')\n); \n\n`;
      } else {
        saveVar = `${saveVar1 }${`var $${ currentInputToEdit.name } = morn.Product.create(\n`
          + '    Parameters.getString(\'udef.ds.'}${ currentInputToEdit.name }.feed'),\n`
          + `    Parameters.getJson('udef.ds.${ currentInputToEdit.name }.key'),\n`
          + `    Parameters.getJson('udef.ds.${ currentInputToEdit.name }.cols')\n); \n\n`;
      }
      return prevPart + outputInitComment + saveVar + outputEndComment + endPart;
    },
    addSaveSeriesPart(currentInputToEdit, currentFormulaToUpdate) {
      const saveInitComment = '/*&ANALYZE_SUSPEND SAVE*/\n\n';
      let saveVar = '';
      const saveEndComment = '\n\n/*&ANALYZE_RESUME SAVE*/\n';

      const prevPart = currentFormulaToUpdate.split(saveInitComment)[0];
      let endPart = currentFormulaToUpdate.split(saveInitComment)[1];

      // eslint-disable-next-line prefer-destructuring
      const saveVar1 = endPart.split(saveEndComment)[0];
      // eslint-disable-next-line prefer-destructuring
      endPart = endPart.split(saveEndComment)[1];

      if (saveVar1 === '') {
        // eslint-disable-next-line prefer-destructuring
        const varName = currentInputToEdit.name.split('_')[0];
        saveVar = `save_series(${ varName }, $${ currentInputToEdit.name }, true);\n`;
      } else {
        const varName = currentInputToEdit.name.split('_')[0];
        saveVar = `${saveVar1} \nsave_series(${ varName }, $${ currentInputToEdit.name }, true);\n`;
      }
      return prevPart + saveInitComment + saveVar + saveEndComment + endPart;
    },
    async saveWorkflow() {
      this.showLoader = true;

      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      this.paramSet = this.getEditableWorkflowDetails.paramSet;
      this.parameterSetData = this.paramSet.workFlowJobModel.parameterSetGroupModel;
      this.formulae = this.getEditableWorkflowDetails.formulae;
      this.newAddedFormulas = this.getEditableWorkflowDetails.newlyAddedFormule;
      this.nodes = this.getEditableWorkflowDetails.nodes;
      this.links = this.getEditableWorkflowDetails.links;
      this.targetData = this.getEditableTargets;
      this.workflowUi = this.getEditableWorkflowUI.ui;
      this.workflowId = this.getEditableWorkflowUI.id;
      this.workflowNameProp = this.getEditableWorkflowUI.name;

      if (!this.workflowUi.isEditedPrev) {
        this.workflowUi.isEditedPrev = true;
      }

      if (this.operation === 'edit') {
        this.showLoader = true;
        this.finalChecksForEdit();

        if (this.newAddedFormulas && this.newAddedFormulas.length > 0) {
          this.showLoader = true;
          const promises = [];
          for (let i = 0; i < this.newAddedFormulas.length; i++) {
            const element = this.newAddedFormulas[i];
            // set the formula bubbles
            // get the UUID for formula
            // Update the formula bubble
            promises.push(this.saveFormula(element));
          }
          Promise.all(promises).finally(() => {
            this.showLoader = true;
            this.setCurrentEditNewFormula([]);
            this.newAddedFormulas = this.getEditableNewFormulae;
            if (!this.saveParameterSetModels()) {
              // eslint-disable-next-line no-useless-return
              return;
            }
          });
        } else if (this.newAddedFormulas && this.newAddedFormulas.length === 0) {
          if (!this.saveParameterSetModels()) {
            return '';
          }
        }
      } else if (this.operation === 'create') {
        this.showLoader = true;
        this.finalChecksForCreate();
        // get all worflows and check name is present in existing workflows
        this.getAllWorkflowsForDiagram().then((response) => {
          this.allWorkflows = response.data;

          for (let i = 0; i < this.allWorkflows.length; i++) {
            const element = this.allWorkflows[i];

            if (element.name === this.workflowNameProp) {
              this.wfNameError = true;
              this.errorMessage = 'Workflow with the same name already exist';
              setTimeout(() => {
                this.errorMessage = '';
              }, 5000);
              return;
            }
          }
          // work on formulas bubbles
          if (this.newAddedFormulas && this.newAddedFormulas.length > 0) {
            this.showLoader = true;
            const promises = [];
            for (let i = 0; i < this.newAddedFormulas.length; i++) {
              const element = this.newAddedFormulas[i];
              // set the formula bubbles
              // get the UUID for formula
              // Update the formula bubble
              promises.push(this.saveFormula(element));
            }
            // eslint-disable-next-line consistent-return
            Promise.all(promises).finally(() => {
              this.showLoader = true;
              if (!this.saveParameterSetModels()) {
              // eslint-disable-next-line no-useless-return
                return '';
              }
            });
          } else if (this.newAddedFormulas && this.newAddedFormulas.length === 0) {
            this.showLoader = true;
            if (!this.saveParameterSetModels()) {
              // eslint-disable-next-line no-useless-return
              return;
            }
          }
        }).catch((error) => {
          this.errorMessage = 'Not able to get all workflows';
          setTimeout(() => {
            this.errorMessage = '';
          }, 5000);
        });
        return '';
      }
      return '';
    },
    async saveFormula(currentFormulaObj) {
      this.nodeModeArray = [];
      this.enableLinkCreation = false;
      this.isActiveF = false;
      this.isActiveQF = false;
      this.isActiveS = false;
      this.showLoader = true;

      const payload = {
        name: currentFormulaObj.name,
        type: 'JS',
        formula: currentFormulaObj.formula,
      };
      // eslint-disable-next-line consistent-return
      await this.updateWorkflowFormulas(payload).then((response) => {
        this.showLoader = true;
        if (response.status === 200) {
          for (let i = 0; i < this.newAddedFormulas.length; i++) {
            const element = this.newAddedFormulas[i];

            if (element.name === response.data.name) {
              this.newAddedFormulas[i].uuid = response.data.uuid;
              this.formulae.push(this.newAddedFormulas[i]);
              this.setCurrentEditFormulae({ data: this.formulae, workflowId: this.getEditableWorkflowUI.id });
              this.formulae = this.getEditableWorkflowDetails.formulae;
              this.nodes[this.newAddedFormulas[i].nodeID].data.formula_id = response.data.uuid;
              this.setCurrentEditNodes(this.nodes);
              this.nodes = this.getEditableWorkflowDetails.nodes;
              this.processFinalBubbles();
              this.targetData[this.nodes[this.newAddedFormulas[i].nodeID].data.targetName]
                .tasks[0].props.uuid = response.data.uuid;
              this.setCurrentEditTargets(this.targetData);
              this.targetData = this.getEditableTargets;
              this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
              this.workflowInfo.targets = Object.values(this.targetData);
              this.setCurrentEditWorkflowUI(this.workflowInfo);
              this.workflowUi = this.getEditableWorkflowUI.ui;
            }
          }
          this.successMessage = 'Formula created successufully';
          this.errorMessage = '';
          setTimeout(() => {
            this.successMessage = '';
          }, 5000);
          return true;
        } if (response.status !== 200) {
          this.errorMessage = 'Error occurred while creating formula';
          setTimeout(() => {
            this.errorMessage = '';
          }, 5000);
          return false;
        }
      }).catch((error) => {
        this.errorMessage = 'Error occurred while creating formula';
        setTimeout(() => {
          this.errorMessage = '';
        }, 5000);
        return false;
      });
    },
    async saveParameterSetModels() {
      this.showLoader = true;
      // Get the current date and time
      const currentDate = new Date();

      // Extract individual components
      const year = currentDate.getFullYear();
      // eslint-disable-next-line max-len
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1 and format with leading zero if needed
      const day = String(currentDate.getDate()).padStart(2, '0');
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      const seconds = String(currentDate.getSeconds()).padStart(2, '0');

      this.parameterSetData = this.paramSet.workFlowJobModel.parameterSetGroupModel;
      const parameterSetModelsObj = this.parameterSetData.parameterSetModels;

      for (let i = 0; i < this.parameterSetData.parameterSetModels.length; i++) {
        const parameterModels = [];
        for (let j = 0; j < this.parameterSetData.parameterSetModels[i].parameterModels.length; j++) {
          const element = this.parameterSetData.parameterSetModels[i].parameterModels[j];
          const prop = { propKey: '', propValue: '' };

          prop.propKey = element.propKey;
          prop.propValue = element.propValue;
          if (!this.updateParameterSetData.delete.paramIds.includes(element.id)) {
            parameterModels.push(JSON.parse(JSON.stringify(prop)));
          }
        }
        parameterSetModelsObj[i].parameterModels = parameterModels;
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.parameterSetData.name = `${this.workflow.name }_${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

      const payload = {
        name: this.parameterSetData.name,
        parameterSetModels: parameterSetModelsObj,
      };

      await this.saveParameterSet(payload).then((response) => {
        if (response.status === 200) {
          // Work on parameter set model
          response.data.parameterSetModels = Array.isArray(response.data.parameterSetModels)
            ? response.data.parameterSetModels
            : [response.data.parameterSetModels];

          this.paramSet.workFlowJobModel.parameterSetGroupModel = response.data;
          this.setCurrentEditParamSet({ data: this.paramSet, workflowId: this.workflowId });
          this.paramSet = this.getEditableWorkflowDetails.paramSet;
          this.parameterSetData = this.paramSet.workFlowJobModel.parameterSetGroupModel;

          // eslint-disable-next-line vue/no-mutating-props
          this.workflowData.workFlowJobModel.parameterSetGroupModel = response.data;
          // eslint-disable-next-line vue/no-mutating-props
          this.workflow.psgId = response.data.id;
          // eslint-disable-next-line vue/no-mutating-props
          this.workflow.ui.psg_id = response.data.id;

          this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
          this.workflowInfo.psgId = response.data.id;
          this.workflowInfo.ui.psg_id = response.data.id;
          this.setCurrentEditWorkflowUI(this.workflowInfo);
          this.workflowUi = this.getEditableWorkflowUI.ui;

          this.successMessage = 'Parameter-set-model created successufully';
          this.errorMessage = '';
          setTimeout(() => {
            this.successMessage = '';
          }, 5000);

          // create targets : Done
          this.setCurrentEditTargets(this.targetData);
          this.targetData = this.getEditableTargets;
          this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
          this.workflowInfo.targets = Object.values(this.targetData);
          this.setCurrentEditWorkflowUI(this.workflowInfo);
          this.workflowUi = this.getEditableWorkflowUI.ui;

          // create UI
          this.processFinalBubbles();
          this.processFinalLiks();

          // eslint-disable-next-line vue/no-mutating-props
          this.workflow['ui'] = this.workflowUi;

          if (this.operation === 'create') {
            this.showLoader = true;
            // hit create API
            if (!this.createWF()) {
              return;
            }
          } else if (this.operation === 'edit') {
            this.showLoader = true;
            if (!this.updateWF()) {
              return;
            }
          }
          // eslint-disable-next-line consistent-return
          return true;
        } if (response.status !== 200) {
          this.errorMessage = 'Error occurred while creating Parameter-set-model';
          setTimeout(() => {
            this.errorMessage = '';
          }, 5000);
          this.showLoader = false;
          // eslint-disable-next-line consistent-return
          return false;
        }
      }).catch((error) => {
        this.errorMessage = 'Error occurred while creating Parameter-set-model';
        setTimeout(() => {
          this.errorMessage = '';
        }, 5000);
        this.showLoader = false;
        return false;
      });
      return true;
    },
    async updateParameterSet() {
      const payload = this.updateParameterSetData;

      await axios.post(`/api/workflows/${this.workflowId}/inputs`, payload)
        // eslint-disable-next-line consistent-return
        .then(async (response) => {
          if (response.status === 200) {
            await this.fetchWorkflow();
            return true;
          } if (response.status !== 200) {
            this.errorMessage = 'Error occurred while creating Parameter-set-model';
            setTimeout(() => {
              this.errorMessage = '';
            }, 5000);
            this.showLoader = false;
            return false;
          }
        }).catch((error) => {
          this.errorMessage = 'Error occurred while creating Parameter-set-model';
          setTimeout(() => {
            this.errorMessage = '';
          }, 5000);
          this.showLoader = false;
          return false;
        });
      return true;
    },
    async fetchWorkflow() {
      this.getWorkflowStatus(this.workflowId).then((response) => {
        // eslint-disable-next-line vue/no-mutating-props
        this.workflowData = response.data;
        // Work on parameter set model
        // eslint-disable-next-line vue/no-mutating-props
        this.parameterSetData = this.workflowData.workFlowJobModel.parameterSetGroupModel;
        // eslint-disable-next-line vue/no-mutating-props
        this.workflow.psgId = this.parameterSetData.id;
        // eslint-disable-next-line vue/no-mutating-props
        this.workflow.ui.psg_id = this.parameterSetData.id;

        this.successMessage = 'Got the workkflow status';
        setTimeout(() => {
          this.successMessage = '';
        }, 5000);
        this.errorMessage = '';

        // create targets : Done
        // eslint-disable-next-line vue/no-mutating-props
        this.workflowInfo.targets = Object.values(this.targetData);

        // create UI
        this.processFinalBubbles();
        this.processFinalLiks();

        // eslint-disable-next-line vue/no-mutating-props
        this.workflow['ui'] = this.workflowUi;

        // hit create API
        if (!this.updateWF()) {
          return true;
        }
        return true;
      }).catch((error) => {});
    },
    async createWF() {
      this.showLoader = true;
      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;

      const payload = {
        name: this.workflowInfo.name,
        timeZone: moment.tz.guess(),
        targets: this.workflowInfo.targets,
        ui: this.workflowInfo.ui,
        description: this.workflowInfo.description,
        psgId: this.workflowInfo.psgId,
      };
      // eslint-disable-next-line consistent-return
      await this.createNewWorkflow(payload).then((response) => {
        this.showLoader = true;
        if (response.status === 200) {
          // eslint-disable-next-line vue/no-mutating-props
          this.workflow = response.data;
          this.setCurrentEditWorkflowUI(response.data);
          this.workflowUi = this.getEditableWorkflowUI.ui;

          // set schedule
          this.updateGenericSchedule(0);

          this.successMessage = 'Workflow created successufully';
          this.errorMessage = '';
          setTimeout(() => {
            this.successMessage = '';
          }, 5000);
          this.showLoader = false;

          setTimeout(() => {
            this.init();
          }, 5000);
          return true;
        } if (response.status !== 200) {
          this.errorMessage = 'Error occurred while creating workflow';
          setTimeout(() => {
            this.errorMessage = '';
          }, 5000);
          this.showLoader = false;
          return false;
        }
      }).catch((error) => {
        this.errorMessage = 'Error occurred while creating workflow';
        setTimeout(() => {
          this.errorMessage = '';
        }, 5000);
        this.showLoader = false;
        return false;
      });
      return true;
    },
    async updateWF() {
      this.showLoader = true;
      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      this.workflowInfo.description = 'Hello';
      const payload = this.workflowInfo;

      // eslint-disable-next-line consistent-return
      await this.updateWorkflowByID(payload).then((response) => {
        if (response.status === 200) {
          // eslint-disable-next-line vue/no-mutating-props
          this.workflow = response.data;
          this.setCurrentEditWorkflowUI(response.data);
          this.workflowUi = this.getEditableWorkflowUI.ui;

          // set schedule
          this.updateGenericSchedule(0);

          this.successMessage = 'Workflow updated successufully';
          this.errorMessage = '';
          setTimeout(() => {
            this.successMessage = '';
          }, 5000);
          this.showLoader = false;
          return true;
        } if (response.status !== 200) {
          this.errorMessage = 'Error occurred while updating workflow';
          setTimeout(() => {
            this.errorMessage = '';
          }, 5000);
          this.showLoader = false;
          return false;
        }
      }).catch((error) => {
        this.errorMessage = 'Error occurred while updating workflow';
        setTimeout(() => {
          this.errorMessage = '';
        }, 5000);
        this.showLoader = false;
        return false;
      });
      return true;
    },
    async updateGenericSchedule(retryCount) {
      this.showLoader = true;
      const maxRetries = 3;

      if (retryCount >= maxRetries) {
        return;
      }

      this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
      const payload = { psgId: this.workflowInfo.psgId, cron: '0 0 8 ? * MON-FRI *', timeZone: this.workflowInfo.timeZone };
      await axios.post(`/api/workflows/${this.workflowInfo.id}/updateSchedule`, payload)
        .then((response) => {
          this.successMessage = 'Workflow created successfully';
          this.errorMessage = '';
          setTimeout(() => {
            this.successMessage = '';
          }, 5000);
          this.showLoader = false;
        })
        .catch((error) => {
          this.errorMessage = 'Error updating the schedule for workflow, retrying again';
          setTimeout(() => {
            this.errorMessage = '';
          }, 5000);
          this.updateGenericSchedule(retryCount + 1);
          this.showLoader = false;
        }).finally(() => {
          this.successMessage = 'Workflow created successfully';
          this.errorMessage = '';
          setTimeout(() => {
            this.successMessage = '';
          }, 5000);
          this.showLoader = false;
          this.init();
        });
    },
    dissmissSuccess() {
      this.successMessage = '';
    },
    hideSuccess1() {
      this.showPageSuccess = false;
      this.pageSuccess = '';
    },
    handleData(results) {
      // Process the results here
      console.log('Data received in WorkflowDiagram:', results);
      this.pageSuccess = "Workflow restarted sucessfully.";
      this.showPageSuccess = true;
    },
  },
};
</script>
<style>
.diagram {
  overflow-y: hidden !important;
}
.vl {
  border-right-style: solid;
  border-right-color: #dddddd;
  height: 100vh;
}
.bubbles-btn-section {
  text-align: center !important;
}
.series-btn {
  padding: 0px Imp !important;
}
.node-btn {
  margin-top: 0.5rem;
}
.node-btn .mds-button___Mcd-ui {
  width: 50%;
  text-align: center !important;
}
.red-them .diagram--editor__pulse {
  fill: #79090986;
  stroke: #79090986;
  box-shadow: 3px 5px 0px 0px #790909;
}
.blue-them .diagram--editor__pulse {
  fill: #0a642073;
  stroke: #0a642073;
  box-shadow: 3px 5px 0px 0px #0a6420;
}
.maroon-them .diagram--editor__pulse {
  fill: #9d44099e;
  stroke: #9d44099e;
  box-shadow: 3px 5px 0px 0px #9d4409;
}
.new-node-them .diagram--editor__pulse {
  fill: #6a27df73;
  stroke: #6a27df73;
  box-shadow: 3px 5px 0px 0px #6a27df73;
}
#fullscreen .mds-popover .mds-popover--right-center {
  left: 50% !important;
  top: 65% !important;
}
pre {
  display: grid;
  margin: 0.25em 0.5em;
}
#fullscreen .diagram-editor__wrapper svg g g svg  {
  height: 100%;
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-size: 13px;
  font-weight: 300;
  line-height: 21px;
  text-transform: capitalize;
}
#fullscreen .diagram-editor__wrapper svg g g svg text {
  x: 126 !important;
}
.diagram-editor__wrapper > svg {
  height: 80vh !important;
}
#fullscreen .diagram-editor__wrapper svg g g svg[width="10"]  {
  display: none;
}
#fullscreen .mds-popover--width-300px___Mcd-ui {
  z-index: 910 !important;
}
[id*= 'link-green-'] path {
  stroke: #0a642073 !important;
}
[id*= 'link-red-'] path {
  stroke: #79090982 !important;
}
[id*= 'link-maroon-'] path {
  stroke: #9d44099e !important;
}
.nd-wrapper {
  margin: 7px 0px 7px 0px !important;
}
.nd-title-heading {
  font-size: 20px;
  font-weight: 900;
}
.nd-btn-container {
  justify-content: right;
}
.nd-btn-container button {
  margin-left: 20px !important;
}
.path-buttons {
    display: flex;
    justify-content: center;
    margin: 7px;
    padding-bottom: 10px;
}
.path-btn {
    margin: 0px 10px;
    display: inline-block;
    width: 54px;
    height: 28px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #dadfe0;
    background-image: url(../../../../assets/images/workflow/link_sprites.png);
    background-repeat: no-repeat;
    opacity: .6;

}
.path-s-btn {
    background-position: 12px 2px;
    box-shadow: 3px 5px 0px 0px #888888;
}
.path-s-btn:hover {
  box-shadow: 3px 5px 0px 0px #0a6420;
}
.path-s-btn.active {
  box-shadow: 3px 5px 0px 0px #0a6420;
}
.path-f-btn {
    box-shadow: 3px 5px 0px 0px #888888;
    background-position: 11px -59px;
}
.path-f-btn:hover {
  box-shadow: 3px 5px 0px 0px #790909;
}
.path-f-btn.active {
  box-shadow: 3px 5px 0px 0px #790909;
}
.path-qf-btn {
    box-shadow: 3px 5px 0px 0px #888888;
    background-position: 13px -29px;
}
.path-qf-btn:hover {
  box-shadow: 3px 5px 0px 0px #9d4409;
}
.path-qf-btn.active {
  box-shadow: 3px 5px 0px 0px #9d4409;
}
.nd-editor {
  border: 2px solid #dddddd;
}
.diagram-editor__wrapper svg g g svg {
  pointer-events: fill !important;
}
.wf-save-btn {
  display: flex;
  justify-content: end;
  padding: 7px;
}
.wf-save-btn-disabled {
  cursor: not-allowed;
  opacity: 0.4;
  pointer-events: none !important;
}
#fullscreen .mds-section__content___Mcd-ui {
  padding-top: 3px;
  /* overflow-y: hidden; */
  overflow-x: hidden;
}
#fullscreen .mds-section___Mcd-ui .mds-section__header-container___Mcd-ui {
  margin-bottom: 0px !important;
}
.read-only {
  pointer-events: none !important;
  opacity: 1 !important;
}
#fullscreen .read-only [id*= 'link-green-'] path {
  pointer-events: all !important;
  opacity: 1 !important;
}
#fullscreen .read-only [id*= 'link-red-'] path {
  pointer-events: all !important;
  opacity: 1 !important;
}
#fullscreen .read-only [id*= 'link-maroon-'] path {
  pointer-events: all !important;
  opacity: 1 !important;
}
#fullscreen .read-only .left-pannel {
  pointer-events: none !important;
  opacity: 1 !important;
}
#fullscreen .read-only .right-pannel {
  pointer-events: all !important;
  opacity: 1 !important;
}
#fullscreen .read-only .wf-save-btn {
  pointer-events: none !important;
  opacity: 1 !important;
}
#fullscreen .read-only .diagram-editor__node-body {
  pointer-events: all !important;
  opacity: 1 !important;
}
#fullscreen .read-only .nd-editor .diagram-editor__wrapper svg g g {
  pointer-events: none !important;
  opacity: 1 !important;
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
}
#fullscreen .read-only .nd-editor .diagram-editor__wrapper svg g g svg {
  pointer-events: none !important;
  opacity: 1 !important;
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
}
#fullscreen .read-only .nd-editor .diagram-editor__wrapper svg g g foreignObject body {
  position: absolute;
  width: 100%;
}
#fullscreen .nd-editor .diagram-editor__wrapper svg g g foreignObject body pre span {
  position: relative;
  width: 85%;
  height: 1 !important;
  word-wrap: break-word;
  text-wrap: wrap;
  font-size: 13px !important;
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
}
#fullscreen .read-only .nd-editor .diagram-editor__wrapper svg g g foreignObject body pre button {
  pointer-events: all !important;
  align-self: flex-start;
  opacity: 1 !important;
  position: relative;
  width: 30%;
  max-width: 30%;
}
#fullscreen .mds-notification__item {
  width: 50% !important;
  left: 25% !important;
}
#fullscreen .diagram .mds-section__actions___Mcd-ui {
  display: flex;
}
#fullscreen .read-only .nd-editor .diagram-editor__wrapper svg g g svg[width="10"] {
  display: none;
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
}
#fullscreen foreignObject:has(.has-error) {
  background-color: #ffc5c9;
}
.series-btn {
  display: flex;
  justify-content: center;
}
.series-btn button {
  display: flex;
  justify-content: center;
}
</style>
