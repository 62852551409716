const getters = {
  getSearchedWorkflows: state => state.searchedWorkflows,
  getUserWorkflows: state => state.userWorkflows,
  getUserWorkflowsData: state => state.userWorkflows,
  getStoredUserWorkflows: state => state.userWorkflows,
  getParamStatus: state => id => state.paramSet[id],
  getAllParamStatus: state => state.paramSet,
  getWorkflowFormulas: state => id => state.formulae[id],
  getEditableWorkflowDetails: state => state.selectedWorkflow,
  getEditableWorkflowUI: state => state.selectedWorkflow.workflowUI,
  getEditableParamSet: state => state.selectedWorkflow.paramSet,
  getEditableFormulae: state => state.selectedWorkflow.formulae,
  getEditableNewFormulae: state => state.selectedWorkflow.newFormula,
  getCreateNewWorkflowDetails: state => state.createNewWorkflow,
  getCreateNewWorkflowUI: state => state.createNewWorkflow.workflowUI,
  getCreateNewParamSet: state => state.createNewWorkflow.paramSet,
  getCreateNewFormulae: state => state.createNewWorkflow.newFormula,
  getCurrentWorkflowManager: state => state.currentWorkflowManager,
  getallWorkflowsForName: state => state.allWorkflowsForName,
  getEditableTargets: state => state.selectedWorkflow.targets,
  getFormulaDataNodeSet: state => nodeId => state.formulaDataNodeSet[nodeId],
  getWorkflowName: state => state.workflowName,
  getUnsavedFormula: state => state.currentUnsavedFormula,
};

export default getters;
