<!-- eslint-disable max-len -->
<template>
  <div
    id="workflow-home"
    class="mcd-page-shell__content mds-page-shell__content"
  >
    <div
      v-if="overlay"
      id="mds-overlay"
      class="mds-overlay"
    />
    <mds-loader
      v-if="showMainLoader"
      size="large"
      class="div-centered"
      aria-label="Large Loader"
    />
    <mds-notification-container
      v-if="showPageSuccess"
      style="z-index: 100;"
    >
      <mds-notification
        key="success-tinted"
        variation="success"
        title="Success"
        tinted
        :dismiss-delay="3000"
        @mds-notification-dismissed="hideSuccess1"
      >
        <span v-html="pageSuccess" />
      </mds-notification>
    </mds-notification-container>
    <mds-notification-container v-if="showPageError">
      <mds-notification
        key="error-tinted"
        variation="error"
        title="Error"
        tinted
        :dismiss-delay="3000"
        @mds-notification-dismissed="showPageError = false;"
      >
        {{ pageError }}
      </mds-notification>
    </mds-notification-container>
    <div>
      <mds-layout-grid>
        <div style="margin-bottom: 0.5rem">
          <mds-row
            align-vertical="center"
            style="margin-bottom: 0.5rem"
          >
            <mds-col :cols="9">
              <h1
                class="mds-header__title component-title"
                style="font-size: 2rem !important;"
              >
                <small><b>{{ workflowHomeConstants.header }}</b></small>
              </h1>
            </mds-col>
            <mds-col
              :cols="3"
              style="display: flex; justify-content: flex-end;"
            >
              <mds-button
                variation="primary"
                style="margin-right: 3%;"
                @click.stop="showEditModal(-1, 'create')"
              >
                {{ workflowHomeConstants.buttons.create }}
              </mds-button>
              <mds-button
                v-if="loggedInUser.includes('@morningstar.com')"
                style="margin-right: 3%;"
                @click.stop="setVariablesForImportingWorkflow()"
              >
                Import
              </mds-button>
              <mds-button @click="toggle = !toggle">
                {{ workflowHomeConstants.buttons.search }}
              </mds-button>
            </mds-col>
          </mds-row>
        </div>
      </mds-layout-grid>
      <mds-loader
        v-if="showLoader"
        size="large"
        aria-label="Large Loader"
      />
      <mds-table
        v-else-if="userWorkflows && userWorkflows.length > 0"
        id="userWorkflowsTable"
        style="margin-bottom: 0.5rem !important"
        row-hover
      >
        <mds-thead hidden-header>
          <mds-th
            v-for="(header, index) in headers"
            :key="index"
            :right-aligned="header.align === 'right'"
          >
            {{ header.text }}
          </mds-th>
        </mds-thead>
        <mds-tbody>
          <template
            v-for="(row, index) in userWorkflows"
          >
            <mds-tr
              :key="index"
              type="expandable"
              :expanded="row.expanded"
              :aria-controls="index + 1"
              :class="row.retired ? 'retired-background' : ''"
              @click.native="toggleRow(index, true) "
              @keyup.enter.native="toggleRow(index, true)"
            >
              <mds-td
                v-for="(header, i) in headers"
                :key="i"
              >
                <div
                  v-if="header.fieldName == 'options'"
                  style="display: flex;
                  justify-content: space-between;
                  padding-inline: 5%;"
                >
                  <div v-if="row.isEditable">
                    <mds-button
                      :id="'edit-btn_'+ index"
                      icon="pencil"
                      type="button"
                      variation="icon-only"
                      :disabled="!row.enableActionButtons || row.retired"
                      @click.stop="showEditModal(index, 'edit')"
                    >
                      {{ workflowHomeConstants.buttons.edit }}
                    </mds-button>
                    <mds-tooltip
                      v-model="row.toggleEditView"
                      :triggered-by="'edit-btn_'+ index"
                      :position="['right-center']"
                    >
                      {{ workflowHomeConstants.buttons.edit }}
                    </mds-tooltip>
                  </div>
                  <div v-if="!row.isEditable">
                    <mds-button
                      :id="'view-btn_' + index"
                      icon="eye"
                      type="button"
                      variation="icon-only"
                      :disabled="!row.enableActionButtons || row.retired"
                      @click.stop="showEditModal(index, 'view')"
                    >
                      {{ workflowHomeConstants.buttons.view }}
                    </mds-button>
                    <mds-tooltip
                      v-model="row.toggleEditView"
                      :triggered-by="'view-btn_' + index"
                      :position="['right-center']"
                    >
                      {{ workflowHomeConstants.buttons.view }}
                    </mds-tooltip>
                  </div>
                  <div>
                    <mds-button
                      :id="'change-owner-btn_' + index"
                      icon="person"
                      type="button"
                      variation="icon-only"
                      :disabled="!row.enableActionButtons || row.retired"
                      @click.stop="showChangeOwnerModal(index)"
                    >
                      {{ workflowHomeConstants.buttons.changeOwner }}
                    </mds-button>
                    <mds-tooltip
                      v-model="row.toggleChangeOwner"
                      :triggered-by="'change-owner-btn_' + index"
                      :position="['right-center']"
                    >
                      {{ workflowHomeConstants.buttons.changeOwner }}
                    </mds-tooltip>
                  </div>
                  <div>
                    <mds-button
                      :id="'start-stop-btn_' + index"
                      icon="play-circle"
                      type="button"
                      variation="icon-only"
                      :disabled="(!row.enableActionButtons || row.retired) || !row.isStartStopPermission"
                      @click.stop="startStopWf(index)"
                    >
                      {{ workflowHomeConstants.buttons.startStop }}
                    </mds-button>
                    <mds-tooltip
                      v-model="row.toggleStartStop"
                      :triggered-by="'start-stop-btn_' + index"
                      :position="['right-center']"
                    >
                      {{ workflowHomeConstants.buttons.startStop }}
                    </mds-tooltip>
                  </div>
                  <div>
                    <mds-button
                      :id="'pemissioon-btn_' + index"
                      icon="person-group"
                      type="button"
                      variation="icon-only"
                      :disabled="!row.enableActionButtons || row.retired"
                      @click.stop="showPermissionsModal(index)"
                    >
                      {{ workflowHomeConstants.buttons.changePermissons }}
                    </mds-button>
                    <mds-tooltip
                      v-model="row.toggleChangePermission"
                      :triggered-by="'pemissioon-btn_' + index"
                      :position="['right-center']"
                    >
                      {{ workflowHomeConstants.buttons.changePermissons }}
                    </mds-tooltip>
                  </div>
                  <div>
                    <mds-button
                      :id="'delete-btn_' + index"
                      variation="icon-only"
                      icon="trash"
                      type="button"
                      :disabled="!row.enableActionButtons || !row.isEditable || row.retired"
                      @click.stop="showWorkflowRemoveModal(index)"
                    >
                      {{ workflowHomeConstants.buttons.delete }}
                    </mds-button>
                    <mds-tooltip
                      v-model="row.toggleDelete"
                      :triggered-by="'delete-btn_' + index"
                      :position="['right-center']"
                    >
                      {{ workflowHomeConstants.buttons.delete }}
                    </mds-tooltip>
                  </div>
                  <div>
                    <mds-button
                      :id="'wf_migration-btn_' + index"
                      icon="share"
                      type="button"
                      variation="icon-only"
                      :disabled="!row.enableActionButtons"
                      @click.stop="showMigrateWorkflowModal(index)"
                    >
                      {{ workflowHomeConstants.buttons.migrate }}
                    </mds-button>
                    <mds-tooltip
                      v-model="row.toggleWorkflowMigration"
                      :triggered-by="'wf_migration-btn_' + index"
                      :position="['right-center']"
                    >
                      {{ workflowHomeConstants.buttons.migrate }}
                    </mds-tooltip>
                  </div>
                  <div>
                    <mds-button
                      :id="'view-details-btn_' + index"
                      variation="icon-only"
                      icon="ellipsis-circle"
                      type="button"
                      :disabled="!row.enableActionButtons"
                      @click.stop="showViewDetailsDialog(index)"
                    >
                      {{ workflowHomeConstants.buttons.viewDetails }}
                    </mds-button>
                    <mds-tooltip
                      v-model="row.toggleViewDetails"
                      :triggered-by="'view-details-btn_' + index"
                      :position="['right-center']"
                      :disabled="!row.enableActionButtons"
                    >
                      {{ workflowHomeConstants.buttons.viewDetails }}
                    </mds-tooltip>
                  </div>
                  <div>
                    <mds-button
                      :id="'refresh-btn_' + index"
                      variation="icon-only"
                      icon="refresh"
                      type="button"
                      :disabled="!row.enableActionButtons"
                      @click.stop="refreshWorkflow(index)"
                    >
                      {{ workflowHomeConstants.buttons.refresh }}
                    </mds-button>
                    <mds-tooltip
                      v-model="row.toggleRemove"
                      :triggered-by="'refresh-btn_' + index"
                      :position="['right-center']"
                    >
                      {{ workflowHomeConstants.buttons.refresh }}
                    </mds-tooltip>
                  </div>
                  <div>
                    <mds-button
                      :id="'remove-btn_' + index"
                      variation="icon-only"
                      icon="remove"
                      type="button"
                      @click.stop="removeWorkflowFromDashboard(index)"
                    >
                      {{ workflowHomeConstants.buttons.removeFromDashboard }}
                    </mds-button>
                    <mds-tooltip
                      v-model="row.toggleRemove"
                      :triggered-by="'remove-btn_' + index"
                      :position="['right-center']"
                    >
                      {{ workflowHomeConstants.buttons.removeFromDashboard }}
                    </mds-tooltip>
                  </div>
                </div>
                <h3
                  v-else-if="header.fieldName == 'workflowNameToShow'"
                  style="display: inline;"
                >
                  <span v-html="getWorkflowName(index)" />
                </h3>
                <p
                  v-else-if="header.fieldName == 'status'"
                  style="display: inline;"
                >
                  <strong><span v-html="getWorkflowCompletedInfo(index)" /></strong>
                </p>
              </mds-td>
            </mds-tr>
            <mds-tr
              v-if="row.expanded"
              :id="index + 1"
              :key="index + 99999"
              type="container"
            >
              <mds-td
                :colspan="headers.length"
                style="padding-left: 20px;"
              >
                <mds-loader
                  v-if="showParamLoader(index)"
                  aria-label="Default Loader"
                />
                <div
                  v-else-if="!row.showError"
                >
                  <mds-table
                    row-numbers
                  >
                    <mds-thead>
                      <mds-th
                        v-for="(header, pIndex) in paramHeaders"
                        :key="pIndex"
                        :style="header.style"
                      >
                        {{ header.text }}
                      </mds-th>
                    </mds-thead>
                    <mds-tbody v-if="workflowParams[index] && workflowParams[index].workFlowJobModel">
                      <mds-tr
                        v-for="(pRow) in paginatedData[index]"
                        :key="pRow.rowIndex"
                        :row-number="pRow.rowIndex + 1"
                      >
                        <mds-td
                          v-for="(header, i) in paramHeaders"
                          :key="i"
                          :style="header.style"
                        >
                          <div
                            v-if="i == 2"
                          >
                            <div>
                              <span v-if="pRow.fetching">
                                Fetching..
                              </span>
                              <span
                                v-else
                                :id="(getWorkflowProgressPercentage(pRow) !== 'Not Started') ? 'default-trigger' + index + pRow.rowIndex : ''"
                              >
                                {{ getWorkflowProgressPercentage(pRow) }}
                              </span>
                              <mds-button
                                v-if="((getWorkflowProgressPercentage(pRow) !== 'Not Started'))"
                                size="small"
                                variation="icon-only"
                                icon="ellipsis-circle"
                                type="button"
                                style="margin-left: 0.5rem;"
                                @click="setWorkflowProgressForInputSet(index, pRow.rowIndex)"
                              />
                              <mds-button
                                v-if="paramErrors[index] && paramErrors[index][pRow.rowIndex].length > 0"
                                size="small"
                                variation="icon-only"
                                icon="exclaim-circle-fill"
                                type="button"
                                style="margin-left: 0.5rem;"
                                @click="showErrorDialog(index, pRow.rowIndex)"
                              />
                              <mds-tooltip
                                v-if="(getWorkflowProgressPercentage(pRow) !== 'Not Started')"
                                id="myPopover"
                                :visible="pRow.showDetails"
                                :triggered-by="'default-trigger' + index + pRow.rowIndex"
                                :position="['left-center']"
                                :title="pRow.name"
                              >
                                <div class="workflow-progress-section">
                                  <mds-table
                                    v-if="workflowProgressData[index]"
                                    right-aligned
                                  >
                                    <mds-tbody v-if="!!workflowProgressData[index]">
                                      <template v-for="(inputs, idx) in workflowProgressData[index][pRow.rowIndex].combinedProgress">
                                        <mds-tr :key="idx">
                                          <mds-td><b>{{ inputs.varName }}</b></mds-td>
                                          <mds-td
                                            :class="inputs.completionTime !== 'N/A' ? 'data-arrival' : 'no-data-arrival'"
                                          >
                                            <span>{{ inputs.keys }}</span><br>
                                            <span>{{ inputs.cols }}</span><br>
                                            <span>{{ inputs.feed }}</span>
                                          </mds-td>
                                          <mds-td v-if="inputs.completionTime !== 'N/A'">
                                            <span><b>Data Arrival</b></span><br>
                                            <span>{{ inputs.completionTime }}</span>
                                          </mds-td>
                                        </mds-tr>
                                      </template>
                                    </mds-tbody>
                                  </mds-table>
                                  <br>
                                  <div
                                    v-if="workflowProgressData[index]"
                                    class="workflow-progress-section-start"
                                    style="margin-bottom: 0.125rem;"
                                  >
                                    <b>Start Date:</b> {{ workflowProgressData[index][pRow.rowIndex].workflowRunDetails.startDate }}
                                  </div>
                                  <div
                                    v-if="workflowProgressData[index]"
                                    class="workflow-progress-section-finish"
                                  >
                                    <b>Finish Date:</b> {{ workflowProgressData[index][pRow.rowIndex].workflowRunDetails.finishDate }}
                                  </div>
                                </div>
                              </mds-tooltip>
                            </div>
                          </div>
                          <span v-else>
                            {{ pRow[header.fieldName] }}
                          </span>
                        </mds-td>
                      </mds-tr>
                    </mds-tbody>
                  </mds-table>
                  <mds-pagination
                    v-if="workflowParams[index].workFlowJobModel.parameterSetGroupModel.parameterSetModels.length > 10"
                    :total-items="workflowParams[index].workFlowJobModel.parameterSetGroupModel.parameterSetModels.length"
                    show-items-info
                    show-items-select
                    @mds-pagination-page-changed="handlePageChange($event, index)"
                  />
                </div>
                <div v-else-if="row.showError">
                  <span>{{ row.errorMessage }}</span>
                </div>
              </mds-td>
            </mds-tr>
          </template>
        </mds-tbody>
      </mds-table>
      <div v-else>
        <mds-empty-state
          title="No Results"
          message="Try adding workflows"
          size="large"
          style="margin: auto;"
        />
      </div>
      <mds-dialog
        v-model="showChangeOwnerDialog"
        action-required
        :title="`Change Owner - ${workflowName}`"
        size="medium"
        width="500px"
      >
        <hr class="horizontal-bar">
        <mds-alert
          v-if="showChangeOwnerPermissionWarning"
          variation="warning"
          title="Permissions Issue"
          size="small"
          tinted
          persistent
        >
          You don't have permission to change the workflow owner; only the current workflow owner can
          make that change.
        </mds-alert>
        <mds-layout-grid class="border-top-solid">
          <mds-row class="pading-top_05rem">
            <mds-col>
              <mds-form>
                <mds-input
                  readonly
                  :value="workflowCurrentOwner"
                  label="Current Owner"
                  size="small"
                />
              </mds-form>
            </mds-col>
          </mds-row>
          <mds-row class="pading-top_1rem">
            <mds-col>
              <mds-form>
                <mds-input
                  v-model="workflowNewOwner"
                  required
                  label="New Owner Name"
                  size="small"
                  placeholder="username@company.com"
                  :disabled="showChangeOwnerPermissionWarning"
                />
              </mds-form>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
        <template #mds-dialog-actions-right>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="secondary"
              @click="closeChangeOwnerModal"
            >
              Cancel
            </mds-button>
            <mds-button
              type="button"
              variation="primary"
              :disabled="changeOwnerButtonDisabled"
              :loading="showSaveButtonLoader"
              @click="changeWfOwner"
            >
              Save
            </mds-button>
          </mds-button-container>
        </template>
      </mds-dialog>
      <mds-dialog
        id="permission-dialog"
        v-model="showPermissionsDialog"
        :title="`Permissions - ${workflowName}`"
        size="medium"
        width="500px"
        action-required
      >
        <hr class="horizontal-bar">
        <mds-banner
          v-if="disableAllPermissionsButton"
          variation="warning"
          size="small"
          persistent
          style="margin-bottom: 1% !important;"
        >
          Please be advised that you lack the necessary permissions to modify the workflow settings.
        </mds-banner>
        <div class="table-scrolling-wrapper">
          <mds-table row-hover>
            <mds-thead>
              <mds-th style="width: 40%">
                User Name
              </mds-th>
              <mds-th>
                View
              </mds-th>
              <mds-th>
                Edit
              </mds-th>
              <mds-th>
                Approval
              </mds-th>
              <mds-th>
                Start/Stop
              </mds-th>
              <mds-th>
                Formula Edit
              </mds-th>
              <mds-th style="width: 8%">
                Input Edit
              </mds-th>
              <mds-th style="width: 3%" />
            </mds-thead>
            <mds-tbody>
              <mds-tr>
                <mds-td>
                  <span>{{ `${workflowCurrentOwner} (owner)` }} </span>
                </mds-td>
                <mds-td>
                  <mds-switch
                    value=""
                    checked
                    disabled
                  />
                </mds-td>
                <mds-td>
                  <mds-switch
                    value=""
                    checked
                    disabled
                  />
                </mds-td>
                <mds-td>
                  <mds-switch
                    value=""
                    checked
                    disabled
                  />
                </mds-td>
                <mds-td>
                  <mds-switch
                    value=""
                    checked
                    disabled
                  />
                </mds-td>
                <mds-td>
                  <mds-switch
                    value=""
                    checked
                    disabled
                  />
                </mds-td>
                <mds-td>
                  <mds-switch
                    value=""
                    checked
                    disabled
                  />
                </mds-td>
                <mds-td />
              </mds-tr>
              <mds-tr
                v-for="(workflowPermission, index) in workflowPermissions"
                :key="index"
              >
                <mds-td>
                  <span style="overflow-wrap: break-word;">
                    <mds-form v-if="workflowPermission.userName === ''">
                      <mds-input
                        v-model="workflowPermsNewUser"
                        hidden-label
                        label="userName"
                        type="text"
                        placeholder="username@company.com"
                        @focusout="setUserNameForPerms(workflowPermission)"
                      />
                    </mds-form>
                    <b v-else-if="workflowPermission.userName === 'Colleagues from same company'">
                      {{ workflowPermission.userName }}
                    </b>
                    <span v-else>
                      <mds-input
                        v-if="index + 1 > savedWorkflowPermissionsCount"
                        v-model="workflowPermission.userName"
                        label=""
                        type="text"
                        :value="workflowPermission.userName"
                      />
                      <span v-else>{{ workflowPermission.userName }}</span>
                    </span>
                  </span>
                </mds-td>
                <mds-td>
                  <mds-switch
                    value=""
                    :checked="isPermissionBtnChecked(workflowPermission, 'view')"
                    :disabled="disableAllPermissionsButton || workflowPermission.disableViewToggle"
                    @change="checkPermissionsBtn(workflowPermission, 'view', index)"
                  />
                </mds-td>
                <mds-td>
                  <mds-switch
                    value=""
                    :checked="isPermissionBtnChecked(workflowPermission, 'edit')"
                    :disabled="disableAllPermissionsButton"
                    @change="checkPermissionsBtn(workflowPermission, 'edit', index)"
                  />
                </mds-td>
                <mds-td>
                  <mds-switch
                    value=""
                    :checked="isPermissionBtnChecked(workflowPermission, 'approval')"
                    :disabled="disableAllPermissionsButton"
                    @change="checkPermissionsBtn(workflowPermission, 'approval', index)"
                  />
                </mds-td>
                <mds-td>
                  <mds-switch
                    value=""
                    :checked="isPermissionBtnChecked(workflowPermission, 'start_stop')"
                    :disabled="disableAllPermissionsButton"
                    @change="checkPermissionsBtn(workflowPermission, 'start_stop', index)"
                  />
                </mds-td>
                <mds-td>
                  <mds-switch
                    value=""
                    :checked="isPermissionBtnChecked(workflowPermission, 'formula_edit')"
                    :disabled="disableAllPermissionsButton"
                    @change="checkPermissionsBtn(workflowPermission, 'formula_edit', index)"
                  />
                </mds-td>
                <mds-td>
                  <mds-switch
                    value=""
                    :checked="isPermissionBtnChecked(workflowPermission, 'input_edit')"
                    :disabled="disableAllPermissionsButton"
                    @change="checkPermissionsBtn(workflowPermission, 'input_edit', index)"
                  />
                </mds-td>
                <mds-td>
                  <div
                    style="cursor: pointer;"
                    @click="removePermission(index)"
                  >
                    <mds-inline-message
                      v-if="index + 1 > savedWorkflowPermissionsCount"
                      size="small"
                      variation="error"
                      icon-only
                    >
                      Remove
                    </mds-inline-message>
                  </div>
                </mds-td>
              </mds-tr>
            </mds-tbody>
          </mds-table>
        </div>
        <mds-button
          style="margin-top: 3%"
          type="button"
          variation="primary"
          icon-right="person-plus"
          :disabled="permissionsAddUserButtonValidate"
          @click="AddUserToWorkflowPermissions"
        >
          Add User
        </mds-button>
        <hr style="margin-top: 2%">
        <template #mds-dialog-actions-right>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="secondary"
              @click="closePermissionsDialog"
            >
              Cancel
            </mds-button>
            <mds-button
              type="button"
              variation="primary"
              :loading="showSaveButtonLoader"
              :disabled="savePermissionsButtonValidation"
              @click="saveWorkflowPermissions()"
            >
              Save
            </mds-button>
          </mds-button-container>
        </template>
      </mds-dialog>
      <mds-dialog
        id="workflow-migration-dialog"
        v-model="openMigrateWorkflowModal"
        action-required
        :title="`Workflow Migration - ${workflowName}`"
        size="medium"
        width="500px"
      >
        <hr class="horizontal-bar">
        <mds-form style="margin-top: 2%">
          <mds-input
            id="targetWorkflowNameInput"
            v-model="targetWorkflowName"
            required
            size="small"
            placeholder="Search for existing workflow or enter a new name"
            variation="input"
            label="Target Workflow Name"
            style="margin-top: 1%; max-width: 500px; margin-bottom: 10px;"
            :disabled="validateTargetWorkflowNameField"
            @keyup="targetWorkflowNameChange"
            @focusout="targetWorkflowNameFocusOut"
            @keydown.down.prevent="focusTargetWfNameSuggestion"
            @keydown.up.prevent="focusTargetWfNameSuggestion"
            @focus="resetFocusedSuggestionIndex"
          />
          <mds-search-results
            v-if="showTargetWorkflowNameSuggetions && validateTargetWorkflowNameSuggestions"
            width="500px"
            style="max-height: 200px; margin-top: -0.5%; padding: 0px 0px 0px 2px;"
          >
            <mds-search-results-section
              id="targetWorkflowNameSearchResults"
              title=""
            >
              <mds-table
                ref="targetWfNameSuggestionsTable"
                size="small"
                row-hover
              >
                <mds-thead hidden-header>
                  <mds-th>WorkflowName</mds-th>
                </mds-thead>
                <mds-tbody>
                  <mds-tr
                    v-for="(name, index) in targetWorkflowNameSuggetions"
                    :key="index"
                  >
                    <mds-td style="padding: 2px !important;">
                      <mds-button
                        size="small"
                        variation="flat"
                        style="padding: 0px !important; width: 100% !important;"
                        @click.stop="targetWorkflowNameSelected(name)"
                        @focusout="targetWorkflowNameFocusOut"
                        @keydown.down.prevent="focusTargetWfNameSuggestion"
                        @keydown.up.prevent="focusTargetWfNameSuggestion"
                      >
                        {{ name }}
                      </mds-button>
                    </mds-td>
                  </mds-tr>
                </mds-tbody>
              </mds-table>
            </mds-search-results-section>
          </mds-search-results>
          <div class="workflow-migrate-options-section">
            <div
              v-for="(option, index) in workflowMigrateTypeOptions"
              :key="index"
              style="display: flex; margin-bottom: 1%;"
            >
              <mds-radio-button
                v-model="selectedWorkflowMigrateOption"
                name="migrate_option"
                :disabled="'isEditable' in option && !option.isEditable"
                :value="option"
              >
                {{ option.type === 'new' ?
                  'Create new' :
                  `Overwrite workflow owned by ${option.owner} (Workflow Id: ${option.id})` }}
              </mds-radio-button>
            </div>
          </div>
          <div style="display: flex;">
            <mds-radio-button
              v-model="workflowMigrateFeedType"
              name="feed_type"
              value="same-feeds"
            />
            <span>Use same feeds</span>
          </div>
          <div style="display: flex; margin-top: 1%;">
            <mds-radio-button
              v-model="workflowMigrateFeedType"
              name="feed_type"
              value="custom-feeds"
            />
            <span>Rename feeds with suffix</span>
            <mds-input
              v-model="suffixForCurrentFeedName"
              size="small"
              label=""
              style="margin: 0px 10px; margin-top: -1%"
              :disabled="workflowMigrateSameFeedTypeValidate || validateTargetWorkflowNameField"
              @focusout="applySuffixToTargetFeedName"
            />
            <span>to use suffix</span>
            <mds-input
              v-model="suffixForTargetFeedName"
              size="small"
              label=""
              style="margin: 0px 10px; margin-top: -1%"
              :disabled="workflowMigrateSameFeedTypeValidate || validateTargetWorkflowNameField"
              @focusout="applySuffixToTargetFeedName"
            />
            <span><b>.</b></span>
            <span style="margin-left: 1%;">
              <mds-button
                id="migration-rename-feed-prompt-trigger"
                icon="info-circle"
                type="button"
                variation="icon-only"
                size="small"
                style="margin-top: 5px;"
              />
              <mds-tooltip
                v-model="migrationRenameFeedPromptToggle"
                size="small"
                triggered-by="migration-rename-feed-prompt-trigger"
                :position="['right-center']"
              >
                Please note: The 'Rename feeds' feature is applicable only to private feeds.
              </mds-tooltip>
            </span>
          </div>
          <span style="margin-left: 22px; margin-top: 1%;">(ex.: _dev, _test, _stage, _prod, or leave empty)</span>
        </mds-form>
        <div class="migration-table-wrapper">
          <mds-table>
            <mds-thead>
              <mds-th style="width: 40%">
                Current Feeds
              </mds-th>
              <mds-th style="width: 40%">
                Target Feeds
              </mds-th>
              <mds-th style="width: 20%">
                Status
                <template
                  #mds-th-actions
                >
                  <mds-button
                    id="migration-feedstatus-info-trigger"
                    icon="info-circle"
                    type="button"
                    variation="icon-only"
                    size="small"
                    @mouseover="showMigrationFeedStatusInfo=true"
                    @mouseleave="showMigrationFeedStatusInfo=false"
                    @focus="showMigrationFeedStatusInfo=true"
                    @blur="showMigrationFeedStatusInfo=false"
                  />
                  <mds-tooltip
                    v-model="showMigrationFeedStatusInfo"
                    size="small"
                    triggered-by="migration-feedstatus-info-trigger"
                    :position="['right-center']"
                  >
                    <mds-section
                      border="none"
                      :size="6"
                      :bold="true"
                      title="Feed Migration Status:"
                    >
                      <div
                        v-if="showMigrationFeedStatusInfo"
                        style="width: 400px;"
                      >
                        <mds-table row-numbers>
                          <mds-thead hidden-header>
                            <mds-th style="width: 35%">
                              Status
                            </mds-th>
                            <mds-th style="width: 65%">
                              Meaning
                            </mds-th>
                          </mds-thead>
                          <mds-tbody>
                            <mds-tr row-number="1">
                              <mds-td> Same </mds-td>
                              <mds-td> No changes in feed during workflow migration. </mds-td>
                            </mds-tr>
                            <mds-tr row-number="2">
                              <mds-td style="color: red;">
                                Unrecognized
                              </mds-td>
                              <mds-td> Feed does not exist. Create a feed by clicking the plus (+) button. </mds-td>
                            </mds-tr>
                            <mds-tr row-number="3">
                              <mds-td style="color: red;">
                                Mismatched
                              </mds-td>
                              <mds-td> Definition of current feed and target feed do not match. </mds-td>
                            </mds-tr>
                            <mds-tr row-number="4">
                              <mds-td style="color: green;">
                                Ok
                              </mds-td>
                              <mds-td> Definition of current and target feeds match, making it applicable for migration. </mds-td>
                            </mds-tr>
                            <mds-tr row-number="5">
                              <mds-td style="color: red;">
                                Source not found
                              </mds-td>
                              <mds-td> User do not have license to the feed. </mds-td>
                            </mds-tr>
                          </mds-tbody>
                        </mds-table>
                      </div>
                    </mds-section>
                  </mds-tooltip>
                </template>
              </mds-th>
            </mds-thead>
          </mds-table>
          <div
            v-if="showFeedDetailsForMigrationLoader"
          >
            <mds-loader aria-label="Medium Loader" />
          </div>
          <div
            v-else-if="!showFeedDetailsForMigrationLoader && showErrorParameterSetModelNotFound"
          >
            <mds-banner
              variation="warning"
              persistent
              style="margin-top: 1%;"
            >
              Unable to retrieve feeds details for the workflow. Please reach out to our support team for prompt assistance.
            </mds-banner>
          </div>
          <div
            v-else
          >
            <mds-table>
              <mds-thead
                hidden-header
                style="border-bottom: 0px !important;"
              >
                <mds-th style="width: 40%">
                  Current Feeds
                </mds-th>
                <mds-th style="width: 40%">
                  Target Feeds
                </mds-th>
                <mds-th style="width: 20%">
                  Status
                </mds-th>
              </mds-thead>
              <mds-tbody>
                <mds-tr
                  v-for="(workflowFeed, feedIndex) in workflowAllFeeds"
                  :key="feedIndex"
                  :style="{ 'background-color': !workflowFeed.userLicensed ? '#ff8080' : '' }"
                >
                  <mds-td style="padding: 2px !important;">
                    <mds-input
                      size="small"
                      hidden-label
                      :value="workflowFeed.feedname"
                      label=""
                      readonly
                      tabindex="-1"
                    />
                  </mds-td>
                  <mds-td style="padding: 4px 4px 4px 9px !important;">
                    <mds-form>
                      <mds-input
                        :ref="'targetFeedInputFields_' + feedIndex"
                        v-model="migrationTargetFeedNames[feedIndex]"
                        size="small"
                        hidden-label
                        :value="workflowFeed.feedname"
                        label=""
                        :readonly="workflowMigrateSameFeedTypeValidate"
                        @input="targetFeedNameChange(feedIndex)"
                        @focusout="migrateTargetFeedNameFocusout"
                        @keydown.down.prevent="focusTargetFeedNameSuggestion"
                        @keydown.up.prevent="focusTargetFeedNameSuggestion"
                        @focus="resetTargetFeedNameSuggestionIndex(feedIndex)"
                      />
                      <mds-search-results
                        v-if="showTargetFeedNameSuggestions[feedIndex] && validateTargetFeedNameSuggestions"
                        :ref="'targetFeedSuggestionBoxes_' + feedIndex"
                        width="300px"
                        style="max-height: 150px; padding: 0px 0px 0px 2px;"
                      >
                        <mds-search-results-section
                          id="targetFeedNameSearchResults"
                          title=""
                        >
                          <mds-table
                            :ref="'targetFeedNameSuggestionsTable_' + feedIndex"
                            size="small"
                            row-hover
                          >
                            <mds-thead hidden-header>
                              <mds-th>Feed Name</mds-th>
                            </mds-thead>
                            <mds-tbody>
                              <mds-tr
                                v-for="(name, index) in targetFeedNameSuggestions"
                                :key="index"
                              >
                                <mds-td style="padding: 4px !important;">
                                  <mds-button
                                    size="small"
                                    variation="flat"
                                    style="padding: 0px !important; width: 100% !important;"
                                    tabindex="-1"
                                    @click.stop="targetFeedNameSelected(name)"
                                    @focusout="migrateTargetFeedNameFocusout"
                                    @keydown.down.prevent="focusTargetFeedNameSuggestion"
                                    @keydown.up.prevent="focusTargetFeedNameSuggestion"
                                  >
                                    {{ name }}
                                  </mds-button>
                                </mds-td>
                              </mds-tr>
                            </mds-tbody>
                          </mds-table>
                        </mds-search-results-section>
                      </mds-search-results>
                    </mds-form>
                  </mds-td>
                  <mds-td style="padding: 4px 4px 4px 8px !important;">
                    <span
                      :style="{ color: !workflowFeed.userLicensed
                        || workflowFeed.status === 'Mismatched'
                        || workflowFeed.status === 'Unrecognized' ?
                          'red' : (workflowFeed.status === 'Ok' ? 'green' : '') }"
                    >
                      <div style="display: flex; align-item: center;">
                        <span>{{ workflowFeed.status }}</span>
                        <div v-if="workflowFeed.status === 'Unrecognized'">
                          <mds-button
                            :id="'create-new-feed-btn-' + feedIndex"
                            variation="icon-only"
                            icon="ip-pillar-positive"
                            type="button"
                            style="margin-left: 6px;"
                            @click="redirectToNewFeedWithDefaults(feedIndex)"
                          >
                            Create new feed
                          </mds-button>
                          <mds-tooltip
                            v-model="createNewFeedToolTipToggle[feedIndex]"
                            size="small"
                            :triggered-by="'create-new-feed-btn-' + feedIndex"
                            :position="['right-center']"
                          >
                            Create new feed
                          </mds-tooltip>
                        </div>
                      </div>
                    </span>
                  </mds-td>
                </mds-tr>
              </mds-tbody>
            </mds-table>
          </div>
        </div>
        <template #mds-dialog-actions-right>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="secondary"
              @click="closeMigrateWorkflowModal()"
              @keydown.tab="focusMigrateButton"
            >
              Cancel
            </mds-button>
            <mds-button
              id="migrateButton"
              type="button"
              variation="primary"
              :loading="showSaveButtonLoader"
              :disabled="workflowMigrateButtonValidation"
              @click="migrateWorkflow()"
              @keydown.tab="focusTargetWorkflowNameInputField"
            >
              Migrate
            </mds-button>
          </mds-button-container>
        </template>
      </mds-dialog>
      <mds-dialog
        id="view-details-dialog"
        v-model="openViewDetailsDialog"
        size="medium"
        width="500px"
        action-required
        :title="`Workflow Details - ${workflowName}`"
      >
        <hr class="horizontal-bar">
        <mds-layout-grid>
          <mds-row>
            <mds-col :cols="3">
              Workflow ID :
            </mds-col>
            <mds-col :cols="3">
              {{ viewDetails_WorkflowId }}
            </mds-col>
            <mds-col :cols="3">
              Parameter Set Group ID :
            </mds-col>
            <mds-col :cols="3">
              {{ viewDetails_PsgId }}
            </mds-col>
          </mds-row>
        </mds-layout-grid>
        <div
          class="table-scrolling-wrapper"
          style="margin-top: 5%;"
        >
          <mds-table>
            <mds-thead>
              <mds-th style="width: 60%">
                Parameter-set :
              </mds-th>
              <mds-th style="padding-left: 9.5%">
                Run Id :
              </mds-th>
            </mds-thead>
          </mds-table>
          <div
            v-if="showErrorParameterSetModelNotFound"
            style="margin-top: 1%"
          >
            <mds-banner
              variation="warning"
              persistent
            >
              This workflow has no parameter and no history.
            </mds-banner>
          </div>
          <div v-else>
            <mds-table>
              <mds-thead
                hidden-header
                style="border-bottom: 0px !important;"
              >
                <mds-th style="width: 60%">
                  Parameter-set :
                </mds-th>
                <mds-th style="padding-left: 9.5%">
                  Run Id :
                </mds-th>
              </mds-thead>
              <mds-tbody>
                <mds-tr
                  v-for="(parameterSetWithJobId, index) in viewDetails_ParameterSetsWithJobIds"
                  :key="index"
                >
                  <mds-td> {{ parameterSetWithJobId.parameterSetName }} </mds-td>
                  <mds-td style="padding-left: 9.5%">
                    {{ parameterSetWithJobId.runId }}
                  </mds-td>
                </mds-tr>
              </mds-tbody>
            </mds-table>
          </div>
        </div>
        <template #mds-dialog-actions-right>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="primary"
              @click="closeViewDetailsDialog()"
            >
              Ok
            </mds-button>
          </mds-button-container>
        </template>
      </mds-dialog>
      <mds-dialog
        id="import-dialog"
        v-model="showImportDialog"
        :title="`Import From Config`"
        size="medium"
        width="500px"
        action-required
      >
        <template
          #mds-dialog-actions-right
        >
          <mds-button-container
            v-if="!showImportLoader && importParsedVariables"
            right-aligned
          >
            <mds-button
              type="button"
              variation="secondary"
              icon-right="remove"
              @click="
                showImportDialog=false;
                importParsedVariables=false;
                workflowConfigContents='';
                selectAllInputSetsForImportCheck=false;
                workflowConfigNewOwner=loggedInUser;
                selectInputsByType='Param Sets';
                workflowConfigName=`WFI_${Date.now()}`
              "
            >
              Close
            </mds-button>
            <mds-button
              type="button"
              variation="primary"
              icon-right="gear"
              :disabled="!workflowConfigContents"
              @click="generateWorkflowFromConfig()"
            >
              Generate
            </mds-button>
          </mds-button-container>
        </template>
        <div v-if="showImportLoader">
          <div style="text-align: center !important; color: #0077cf">
            <span><b>Generating Workflow...</b></span>
          </div>
          <mds-loader
            size="medium"
            aria-label="Medium Loader"
          />
        </div>
        <div v-if="!showImportLoader">
          <div v-if="!importParsedVariables">
            <span>Paste the workflow config contents from the debug section of workflow from stage / prod</span>
            <mds-textarea
              v-model="workflowConfigContents"
              label="Workflow Config:"
              spellcheck="false"
              size="medium"
              style="margin-top: 1rem;"
            />
            <mds-button
              type="button"
              variation="primary"
              icon-right="gear"
              style="margin-top: 1rem;"
              @click="parseWorkflowConfigContents()"
            >
              Parse
            </mds-button>
          </div>
          <div v-if="importParsedVariables">
            <mds-input
              v-model="workflowConfigName"
              label="Workflow Name:"
              style="margin-top: 0.5rem;"
              :readonly="true"
            />
            <mds-layout-grid>
              <mds-row>
                <mds-col :cols="4">
                  <mds-input
                    v-model="workflowConfigNewOwner"
                    label="New Owner:"
                    style="margin-top: 0.5rem;"
                  />
                </mds-col>
                <mds-col :cols="8">
                  <mds-row>
                    <mds-col :cols="4">
                      <mds-field-set
                        style="margin-top: 0.5rem"
                        variation="radio-group"
                        legend=""
                      >
                        <b>Select Inputs By</b>
                        <div style="margin-left: 1rem;">
                          <mds-radio-button
                            v-model="selectInputsByType"
                            name="Range"
                            value="Range"
                            label="Range - Specify Start and End Range"
                          />
                          <mds-radio-button
                            v-model="selectInputsByType"
                            name="Param Sets"
                            value="Param Sets"
                            label="Param Sets - Manually Select Input Sets By Checkbox"
                          />
                        </div>
                      </mds-field-set>
                    </mds-col>
                    <mds-col :cols="4">
                      <mds-input
                        v-model="inputSetStartRange"
                        label="Input Set Start Range:"
                        type="number"
                        :disabled="selectInputsByType !== 'Range'"
                        style="margin-top: 0.5rem;"
                      />
                    </mds-col>
                    <mds-col :cols="4">
                      <mds-input
                        v-model="inputSetEndRange"
                        label="Input Set End Range:"
                        type="number"
                        :disabled="selectInputsByType !== 'Range'"
                        style="margin-top: 0.5rem;"
                      />
                      <div
                        v-if="(inputSetEndRange - inputSetStartRange + 1) > 40"
                        style="margin-top: 0.5rem !important"
                      >
                        <!-- <b>Note:</b>
                        The current input set range is too large, consider decreasing the end range
                        / adjusting the overall range to avoid import failures. -->
                      </div>
                    </mds-col>
                  </mds-row>
                </mds-col>
              </mds-row>
              <mds-row>
                <mds-col :cols="3">
                  <b>Input Names</b>
                  <mds-checkbox
                    value="accept"
                    :checked="selectAllInputSetsForImportCheck"
                    :disabled="selectInputsByType === 'Range'"
                    style="margin-top: 0.5rem; margin-bottom: 0.5rem"
                    @change="selectAllInputSetsForImport()"
                  >
                    Select All
                  </mds-checkbox>
                  <div style="max-height: 300px; overflow: auto;">
                    <mds-table style="margin-top: 0.5rem">
                      <mds-tbody>
                        <template v-for="(input, idx) in workflowImportPayload.parameterSetGroup.parameterSetModels">
                          <mds-tr :key="idx">
                            <mds-checkbox
                              value="accept"
                              :disabled="selectInputsByType === 'Range'"
                              :checked="selectedInputSetMap[input.name]"
                              @change="selectedInputSetMap[input.name] = !selectedInputSetMap[input.name]"
                            >
                              {{ input.name }}
                            </mds-checkbox>
                          </mds-tr>
                        </template>
                      </mds-tbody>
                    </mds-table>
                  </div>
                </mds-col>
                <mds-col :cols="6">
                  <mds-textarea
                    v-if="importFeedsToBeChanged"
                    v-model="importFeedsToBeChanged"
                    spellcheck="false"
                    label="Change Feeds: <BEFORE> ~ <AFTER>"
                    size="medium"
                    style="margin-top: 1rem;"
                  />
                </mds-col>
                <mds-col :cols="3">
                  <mds-textarea
                    v-if="importFeedSourcesToBeChanged"
                    v-model="importFeedSourcesToBeChanged"
                    spellcheck="false"
                    label="Change Sources: <BEFORE> ~ <AFTER>"
                    size="medium"
                    style="margin-top: 1rem;"
                  />
                </mds-col>
              </mds-row>
            </mds-layout-grid>
          </div>
          <div
            v-if="showImportError"
            class="wf-import-error-msg-section"
          >
            <span>Failed to Import Workflow <b>{{ importErrorMessage }}</b></span>
          </div>
          <div
            v-if="showImportSuccess"
            class="wf-import-success-msg-section"
          >
            <span>Workflow Successfully Generated with ID: <b>{{ importWorkflowId }}</b></span>
          </div>
        </div>
      </mds-dialog>
      <mds-dialog
        v-if="showWorkflowProgressDialog && workflowIndex > -1 && workflowProgressData[workflowIndex] && workflowProgressData[workflowIndex][workflowInputIndex]"
        v-model="showWorkflowProgressDialog"
        :title="`${workflowProgressData[workflowIndex][workflowInputIndex].wfId} - ${workflowProgressData[workflowIndex][workflowInputIndex].psmName}`"
        class="wh-incons"
      >
        <div class="workflow-progress-section wh-incons">
          <h2>{{ workflowHomeConstants.progress.dependencies }}</h2>
          <mds-table right-aligned>
            <mds-tbody v-if="workflowIndex > -1 && !!workflowProgressData[workflowIndex]">
              <template v-for="(inputs, idx) in workflowProgressData[workflowIndex][workflowInputIndex].dataDependenciesWorkflowStatus">
                <mds-tr
                  :key="idx"
                  style="margin-top: 1rem !important"
                >
                  <mds-td
                    style="width: 30%; padding-top: 2rem; padding-left: 1rem"
                    class="wh-incons"
                  >
                    {{ inputs.varName }}
                  </mds-td>
                  <mds-td>
                    <div
                      :class="(inputs.completionTime !== 'N/A' ? 'data-arrival' : 'no-data-arrival') + ' wh-incons'"
                      style="padding: 1rem"
                    >
                      <span>{{ inputs.keys }}</span><br>
                      <span>{{ inputs.cols }}</span><br>
                      <span>{{ inputs.feed }}</span>
                    </div>
                  </mds-td>
                  <mds-td
                    v-if="inputs.completionTime !== 'N/A'"
                    style="width: 25%;"
                    class="wh-incons"
                  >
                    <span>{{ workflowHomeConstants.progress.dataArrival }}</span><br>
                    <span>{{ inputs.completionTime }}</span>
                  </mds-td>
                </mds-tr>
              </template>
            </mds-tbody>
          </mds-table>
        </div>

        <!-- <div class="workflow-progress-section wh-incons">
          <h2>Tasks</h2>
        </div> -->

        <div class="workflow-progress-section wh-incons">
          <h2>{{ workflowHomeConstants.progress.targets }}</h2>
          <mds-table right-aligned>
            <mds-tbody v-if="workflowIndex > -1 && !!workflowProgressData[workflowIndex]">
              <template v-for="(inputs, idx) in workflowProgressData[workflowIndex][workflowInputIndex].publishDependenciesWorkflowStatus">
                <mds-tr :key="idx">
                  <mds-td
                    style="width: 30%; padding-top: 2rem; padding-left: 1rem"
                    class="wh-incons"
                  >
                    {{ inputs.varName }}
                  </mds-td>
                  <mds-td>
                    <div
                      :class="(inputs.completionTime !== 'N/A' ? 'data-arrival' : 'no-data-arrival') + ' wh-incons'"
                      style="padding: 1rem"
                    >
                      <span>{{ inputs.keys }}</span><br>
                      <span>{{ inputs.cols }}</span><br>
                      <span>{{ inputs.feed }}</span>
                    </div>
                  </mds-td>
                  <mds-td
                    v-if="inputs.completionTime !== 'N/A'"
                    style="width: 25%;"
                    class="wh-incons"
                  >
                    <span>{{ workflowHomeConstants.progress.dataArrival }}</span><br>
                    <span>{{ inputs.completionTime }}</span>
                  </mds-td>
                </mds-tr>
              </template>
            </mds-tbody>
          </mds-table>
        </div>

        <div
          v-if="workflowProgressData[workflowIndex][workflowInputIndex].userActionHistoryList.length > 0"
          class="workflow-progress-section wh-incons"
        >
          <h2>{{ workflowHomeConstants.progress.userAction }}</h2>
          <mds-table right-aligned>
            <mds-tbody v-if="workflowIndex > -1 && !!workflowProgressData[workflowIndex]">
              <template v-for="(userAction, idx) in workflowProgressData[workflowIndex][workflowInputIndex].userActionHistoryList">
                <mds-tr
                  :key="idx"
                  class="phase-launch"
                >
                  <mds-td
                    style="width: 40%; padding: 1rem"
                    class="wh-incons"
                  >
                    {{ userAction.user }}
                  </mds-td>
                  <mds-td
                    style="width: 40%; padding: 1rem"
                    class="wh-incons"
                  >
                    {{ userAction.runDate }}
                  </mds-td>
                  <mds-td
                    class="wh-incons"
                    style="width: 20%; padding: 1rem"
                  >
                    {{ workflowHomeConstants.progress.phaseLaunch }}
                  </mds-td>
                </mds-tr>
              </template>
            </mds-tbody>
          </mds-table>
        </div>

        <div
          class="workflow-progress-section wh-incons"
        >
          <h2>{{ workflowHomeConstants.progress.runDetails }}</h2>
          <mds-list-group
            size="large"
            style="pointer-events: none;"
            class="wh-incons"
          >
            <mds-list-group-item
              v-if="workflowProgressData[workflowIndex][workflowInputIndex].workflowRunDetails.startMethod"
              :text="'Start Method: ' + workflowProgressData[workflowIndex][workflowInputIndex].workflowRunDetails.startMethod"
              href="#"
              class="wh-incons"
            />
            <mds-list-group-item
              v-if="workflowProgressData[workflowIndex][workflowInputIndex].workflowRunDetails.startedBy"
              :text="'Started By: ' + workflowProgressData[workflowIndex][workflowInputIndex].workflowRunDetails.startedBy"
              href="#"
            />
            <mds-list-group-item
              v-if="workflowProgressData[workflowIndex][workflowInputIndex].workflowRunDetails.finishedBy"
              :text="'Finished By: ' + workflowProgressData[workflowIndex][workflowInputIndex].workflowRunDetails.finishedBy"
              href="#"
            />
            <mds-list-group-item
              :text="'Start Date: ' + workflowProgressData[workflowIndex][workflowInputIndex].workflowRunDetails.startDate"
              href="#"
            />
            <mds-list-group-item
              :text="'Finish Date: ' + workflowProgressData[workflowIndex][workflowInputIndex].workflowRunDetails.finishDate"
              href="#"
            />
            <mds-list-group-item
              :text="'Last Fire Time: ' + workflowProgressData[workflowIndex][workflowInputIndex].workflowRunDetails.lastFireTime"
              href="#"
            />
            <mds-list-group-item
              :text="'Next Fire Time: ' + workflowProgressData[workflowIndex][workflowInputIndex].workflowRunDetails.nextFireTime"
              href="#"
            />
          </mds-list-group>
        </div>
      </mds-dialog>
      <mds-notification-container v-if="showSuccess">
        <!-- Tinted -->
        <mds-notification
          key="success-tinted"
          variation="success"
          title="Success"
          tinted
          :dismiss-delay="3000"
          @mds-notification-dismissed="hideSuccess"
        >
          {{ successMessage }}
        </mds-notification>
      </mds-notification-container>
      <mds-notification-container v-if="showError">
        <!-- Tinted -->
        <mds-notification
          key="error-tinted"
          variation="error"
          title="Error"
          tinted
          :dismiss-delay="3000"
          @mds-notification-dismissed="hideError"
        >
          {{ errorMessage }}
        </mds-notification>
      </mds-notification-container>
      <workflow-edit
        v-if="currentActiveEditIndex >= -1"
        ref="wfEdit"
        :index="currentActiveEditIndex"
        :workflow-name="workflowName"
        :workflow-id="userWorkflows[currentActiveEditIndex].id"
        :operation="wfOperation"
        @closeEdit="closeEditModal"
        @resetWorkflowEdit="resetWorkflowEdit"
      />
      <div>
        <transition name="slide">
          <template v-if="toggle">
            <WorkflowSearchVue @closeModal="closeSearch" />
          </template>
        </transition>
      </div>
      <mds-modal
        v-if="showValidationFailureDialog"
        v-model="showValidationFailureDialog"
        size="medium"
        width="900px"
        title="Errors"
        action-required
      >
        <template #mds-modal-actions>
          <mds-button-container right-aligned>
            <mds-button
              variation="secondary"
              @click="showValidationFailureDialog = !showValidationFailureDialog"
            >
              Cancel
            </mds-button>
            <mds-button
              variation="primary"
              :disabled="!approveButtonEnabled"
              @click="submitUserAction"
            >
              Approve
            </mds-button>
          </mds-button-container>
        </template>
        <mds-table size="small">
          <mds-thead>
            <mds-th style="width: 50px">
              <mds-checkbox
                :checked="selectionStatus.isAllSelected"
                :indeterminate="selectionStatus.isIndeterminate"
                @change="approveAllErrorActionsChanged($event)"
              />
            </mds-th>
            <mds-th
              v-for="(header, index) in errorHeaders"
              :key="index"
              :right-aligned="header.align === 'right'"
              :style="header.style"
            >
              {{ header.text }}
            </mds-th>
          </mds-thead>
          <mds-tbody>
            <mds-tr
              v-for="(row, index) in paramErrors[currentActiveErrorParamIndex][
                currentActiveErrorRowIndex
              ]"
              :key="index"
            >
              <td>
                <mds-checkbox
                  v-if="row.user_actions && row.user_actions.length > 0"
                  v-model="row.enabled"
                  hidden-label
                />
              </td>
              <td
                v-for="(header, i) in errorHeaders"
                :key="i"
              >
                <span v-if="header.fieldName === 'date'">
                  {{ row[header.fieldName] }}
                </span>
                <span v-else>
                  {{ row.data[header.fieldName] }}
                </span>
              </td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
      </mds-modal>
      <mds-dialog
        v-if="showStartStopDialog"
        v-model="showStartStopDialog"
        action-required
        width="500px"
        :title="
          'Start/Stop &quot;' +
            userWorkflows[currentActiveStartStopIndex].name +
            ' (' +
            userWorkflows[currentActiveStartStopIndex].id +
            ')&quot;'
        "
      >
        <mds-loader
          v-if="showStartStopDialogLoader"
          size="small"
          aria-label="Small Loader"
        />
        <mds-table v-else-if="showStartStopError">
          <mds-thead>
            <mds-th />
          </mds-thead>
          <mds-tbody>
            <mds-tr>
              <mds-td>
                <mds-inline-message
                  variation="warning"
                  tinted
                >
                  An error occurred.  This workflow cannot be started or stopped, try again later.
                </mds-inline-message>
              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
        <mds-table v-else>
          <mds-thead>
            <mds-th style="width: 60%">
              Input Set
            </mds-th>
            <mds-th id="start-stop-id">
              <div style="display: flex; justify-content: space-between; width: 40% !important;">
                <span class="mds-th__text___Mcd-ui">Stop</span>
                <mds-switch
                  v-model="mainStartStoptoggle"
                  style="padding-bottom: 15%;"
                  @change="startStopAllInputs($event)"
                />
                <span class="mds-th__text___Mcd-ui">Start</span>
              </div>
            </mds-th>
          </mds-thead>
          <mds-tbody>
            <mds-tr
              v-for="(inputSet, p) in currentStartStopInputSet"
              :key="p"
            >
              <mds-td>
                {{ inputSet.name }}
              </mds-td>
              <mds-td>
                <div style="display: flex; justify-content: space-between; width: 40% !important;">
                  <span class="mds-th__text___Mcd-ui" />
                  <mds-switch
                    v-model="inputSet.uiValue"
                    hidden-label
                    @change="checkState($event)"
                  />
                  <span class="mds-th__text___Mcd-ui" />
                </div>
              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
        <template #mds-dialog-actions-right>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="secondary"
              @click="showStartStopDialog = !showStartStopDialog"
            >
              Cancel
            </mds-button>
            <mds-button
              type="button"
              variation="primary"
              :disabled="disableStartStopProceedButton"
              @click="showStartStopChanges"
            >
              Ok
            </mds-button>
          </mds-button-container>
        </template>
      </mds-dialog>
      <mds-dialog
        id="workflow-delete-dialog"
        v-model="showWorkflowRemovalModal"
        action-required
        title="Workflow Removal"
        size="medium"
        width="500px"
      >
        <hr class="horizontal-bar">
        <div
          style="height: 40px;"
          v-html="`Are you sure you want to remove workflow <strong>'${workflowName}'</strong> ?`"
        />
        <template #mds-dialog-actions-right>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="secondary"
              @click="closeWorkflowRemoveModal"
            >
              Cancel
            </mds-button>
            <mds-button
              type="button"
              variation="primary"
              :loading="showSaveButtonLoader"
              @click="deleteSchedule"
            >
              Yes, Proceed
            </mds-button>
          </mds-button-container>
        </template>
      </mds-dialog>
      <mds-dialog
        id="close-workflow-model"
        v-model="closeWorkflowModal"
        action-required
        title="Closing Window"
        size="medium"
        width="500px"
      >
        <hr class="horizontal-bar">
        <div
          style="height: 40px;"
          v-html="`You have unsaved changes for <strong>${workflowName}</strong> workflow. Please confirm: `"
        />
        <template #mds-dialog-actions-right>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="secondary"
              @click="keepOpenModal"
            >
              Keep Open
            </mds-button>
            <mds-button
              type="button"
              variation="primary"
              :loading="showSaveButtonLoader"
              @click="closeModel"
            >
              Discard Changes
            </mds-button>
          </mds-button-container>
        </template>
      </mds-dialog>
      <mds-modal
        v-if="showStartStopChangesModal"
        v-model="showStartStopChangesModal"
        :title="
          'Continue Start/Stop &quot;' +
            userWorkflows[currentActiveStartStopIndex].name +
            ' (' +
            userWorkflows[currentActiveStartStopIndex].id +
            ')&quot;'
        "
        :width="'600px'"
        action-required
      >
        <template #mds-modal-actions>
          <mds-button-container right-aligned>
            <mds-button
              variation="secondary"
              :disabled="diabledStartStopButtons"
              @click="showStartStopChangesModal = !showStartStopChangesModal"
            >
              Cancel
            </mds-button>
            <mds-button
              variation="primary"
              :disabled="diabledStartStopButtons"
              @click="submitStartStop(currentActiveStartStopIndex)"
            >
              Ok
            </mds-button>
          </mds-button-container>
        </template>
        <mds-loader
          v-if="mainShowLoader"
          size="large"
          aria-label="Large Loader"
        />
        <mds-table v-else-if="inputsToStart.length > 0">
          <mds-thead>
            <mds-th> Starting Below Inputs </mds-th>
          </mds-thead>
          <mds-tbody>
            <mds-tr
              v-for="(row, index) in inputsToStart"
              :key="index + row.name + row.id"
            >
              <mds-td
                size="small"
                style="background-color: #e5f7eb;"
              >
                <mds-inline-message variation="success">
                  {{ row['name'] }}
                </mds-inline-message>
              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
        <mds-table v-else-if="inputsToStop.length > 0">
          <mds-thead>
            <mds-th> Stopping Below Inputs </mds-th>
          </mds-thead>
          <mds-tbody>
            <mds-tr
              v-for="(row, index) in inputsToStop"
              :key="index + row.name + row.id"
            >
              <mds-td
                size="small"
                style="background-color: #ffe5e5;"
              >
                <mds-inline-message variation="error">
                  {{ row['name'] }}
                </mds-inline-message>
              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
      </mds-modal>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import MdsDialog from '@mds/dialog';
import MdsForm from '@mds/form';
import MdsInput from '@mds/input';
import MdsAlert from '@mds/alert';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import {
  MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
} from '@mds/data-table';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { mapActions, mapGetters } from 'vuex';
import MdsEmptyState from '@mds/empty-state';
import { MdsNotification, MdsNotificationContainer } from '@mds/notification';
import MdsModal from '@mds/modal';
import MdsCheckbox from '@mds/checkbox';
import MdsTextarea from '@mds/textarea';
import CryptoJS from 'crypto-js';
import { Promise } from 'bluebird';
import axios from 'axios';
import MdsSwitch from '@mds/switch';
import MdsSection from '@mds/section';
import MdsInlineMessage from '@mds/inline-message';
import MdsRadioButton from '@mds/radio-button';
import MdsFieldSet from '@mds/fieldset';
import MdsBanner from '@mds/banner';
import MdsPagination from '@mds/pagination';
import { MdsSearchResults, MdsSearchResultsSection } from '@mds/search-results';
import { FEATURES } from '@/utils/constants.js';
import { getUserName } from '../../utils/authService';
import WorkflowSearchVue from './Search/WorkflowSearch.vue';
import WorkflowEdit from './WorkflowEdit.vue';
import workflowLangConstants from './Lang/en.json';

const EventBus = new Vue();

export default {
  name: 'WorkflowHome',
  components: {
    MdsDialog,
    MdsForm,
    MdsInput,
    MdsAlert,
    MdsSection,
    MdsLayoutGrid,
    MdsCol,
    MdsRow,
    MdsButtonContainer,
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsButton,
    WorkflowSearchVue,
    MdsEmptyState,
    WorkflowEdit,
    MdsNotification,
    MdsNotificationContainer,
    MdsModal,
    MdsCheckbox,
    MdsSwitch,
    MdsInlineMessage,
    MdsRadioButton,
    MdsSearchResults,
    MdsSearchResultsSection,
    MdsTextarea,
    MdsBanner,
    MdsListGroup,
    MdsListGroupItem,
    MdsFieldSet,
    MdsPagination,
  },
  data() {
    return {
      loggedInUser: getUserName(),
      toggle: false,
      showLoader: true,
      headers: [
        {
          text: 'Workflow',
          fieldName: 'workflowNameToShow',
          align: 'center',
        },
        {
          text: '',
          fieldName: 'status',
          align: 'center',
        },
        {
          text: '',
          fieldName: 'options',
          align: 'center',
        },
      ],
      errorHeaders: [
        {
          text: 'Date',
          fieldName: 'date',
        },
        {
          text: 'Cause',
          fieldName: 'err_cause',
        },
        {
          text: 'Error',
          fieldName: 'err_msg',
        },
      ],
      paramHeaders: [
        {
          text: 'Parameter',
          fieldName: 'name',
          style: {
            textAlign: 'left !important',
          },
        },
        {
          text: 'Description',
          fieldName: 'description',
          style: {
            textAlign: 'center',
          },
        },
        {
          text: 'Status',
          fieldName: 'status.percent_complete',
          style: {
            textAlign: 'center',
          },
        },
      ],
      userWorkflows: [],
      workflowParams: [],
      showEdit: [],
      currentActiveEditIndex: -2,
      wfOperation: '',
      workflowName: '',
      showError: false,
      errorMessage: '',
      showChangeOwnerDialog: false,
      workflowCurrentOwner: '',
      workflowNewOwner: '',
      currentSelectedWorkflowIndex: -1,
      showSuccess: false,
      successMessage: '',
      showChangeOwnerPermissionWarning: false,
      showSaveButtonLoader: false,
      showDetails: false,
      showValidationFailureDialog: false,
      paramErrors: [],
      currentActiveErrorParamIndex: -1,
      currentActiveErrorRowIndex: -1,
      showStartStopDialog: false,
      showStartStopDialogLoader: false,
      currentActiveStartStopIndex: -1,
      currentStartStopInputSet: [],
      showStartStopChangesModal: false,
      inputsToStart: [],
      inputsToStop: [],
      toggles: false,
      toggleEdit: false,
      showPermissionsDialog: false,
      showImportDialog: false,
      workflowConfigContents: '',
      importFeedsToBeChanged: '',
      importFeedSourcesToBeChanged: '',
      workflowConfigName: `WFI_${Date.now()}`,
      workflowConfigNewOwner: getUserName(),
      inputSetStartRange: 1,
      inputSetEndRange: 1,
      importParsedVariables: false,
      importErrorMessage: '',
      importWorkflowId: '',
      showImportError: false,
      showImportSuccess: false,
      showImportLoader: false,
      workflowImportPayload: {},
      selectedInputSetMap: {},
      workflowPermissions: [],
      workflowPermsNewUser: '',
      savedWorkflowPermissionsCount: 0,
      originalWorkflowPermissions: [],
      disableAllPermissionsButton: false,
      workflowProgressData: {},
      openViewDetailsDialog: false,
      viewDetails_WorkflowId: 0,
      viewDetails_PsgId: 0,
      viewDetails_ParameterSetsWithJobIds: [],
      openMigrateWorkflowModal: false,
      workflowDataFeeds: [],
      workflowPublishFeeds: [],
      workflowAllFeeds: [],
      workflowMigrateFeedType: 'same-feeds',
      workflowMigrateTargetFeedNameTimeoutId: null,
      targetWorkflowName: '',
      targetWorkflowNameSuggetions: [],
      allWorkflowDetails: [],
      showTargetWorkflowNameSuggetions: false,
      workflowMigrateTypeOptions: [],
      selectedWorkflowMigrateOption: {},
      isWorkflowMigrated: false,
      allLicensedFeedsDetailForUser: [],
      allLicensedFeedNamesForUser: [],
      allLicensedFeedsDetailMap: new Map(),
      migrationTargetFeedNames: [],
      migrationCurrentFeedsDetails: new Map(),
      showTargetFeedNameSuggestions: [],
      targetFeedNameSuggestions: [],
      currentTargetFeedIndex: -1,
      suffixForCurrentFeedName: '',
      suffixForTargetFeedName: '',
      psgIdForWorkflowMigration: -1,
      migratedWorkflowId: -1,
      isParameterSetUpdatedForMigration: false,
      migrationRenameFeedPromptToggle: false,
      showFeedDetailsForMigrationLoader: false,
      focusedSuggestionIndex: -1,
      actualWorkflowAllFeeds: [],
      showWorkflowRemovalModal: false,
      closeWorkflowModal: false,
      createNewFeedToolTipToggle: [],
      showErrorParameterSetModelNotFound: false,
      showStartStopError: false,
      showPageError: false,
      pageError: '',
      showWorkflowProgressDialog: false,
      workflowIndex: -1,
      workflowInputIndex: -1,
      workflowHomeConstants: workflowLangConstants.workflowHome,
      promises: [],
      statusPayload: {
        // id: crypto.createHash('sha1').update(Date.now().toString()).digest('hex'),
        jsonrpc: '2.0',
        method: 'get_workflow_status_3',
        params: {},
      },
      wfParamSetData: {},
      wfParamSetDataArray: [],
      workflowParamsDummy: [],
      pageSuccess: '',
      showPageSuccess: false,
      mainShowLoader: false,
      diabledStartStopButtons: false,
      mainStartStoptoggle: false,
      showMigrationFeedStatusInfo: false,
      workflowPermission: [],
      disableViewToggle: false,
      overlay: false,
      showMainLoader: false,
      startStopStatusArray: [],
      focusedTargetFeedName: -1,
      selectInputsByType: 'Param Sets',
      selectAllInputSetsForImportCheck: true,
      paginatedOptions: [],
    };
  },
  computed: {
    ...mapGetters('workflowModule', [
      'getParamStatus',
      'getWorkflowName',
      'getUserWorkflowsData',
    ]),
    ...mapGetters('feedModule', ['getFeedList', 'getFeedDetails', 'getAllFeedRoots']),
    showParamLoader() {
      return index => !this.workflowParams[index];
    },
    getWorkflowName() {
      return index => this.userWorkflows[index].workflowNameToShow;
    },
    getWorkflowCompletedInfo() {
      return index => this.userWorkflows[index].completedInfo;
    },
    changeOwnerButtonDisabled() {
      return this.showChangeOwnerPermissionWarning || this.workflowNewOwner.length === 0;
    },
    selectionStatus() {
      if (
        this.currentActiveErrorParamIndex < 0
        || this.currentActiveErrorRowIndex < 0
        || !this.paramErrors[this.currentActiveErrorParamIndex][this.currentActiveErrorRowIndex]
      ) {
        return {
          isIndeterminate: false,
          isAllSelected: false,
        };
      }
      const rows = this.paramErrors[this.currentActiveErrorParamIndex][
        this.currentActiveErrorRowIndex
      ].filter(row => Object.hasOwn(row, 'enabled'));
      const filtered = rows.filter(row => row.enabled === true);

      // isIndeterminate = there are more than one rows checked but not all
      // isAllSelected = all rows are checked
      return {
        isIndeterminate: filtered.length > 0 && filtered.length < rows.length,
        isAllSelected: filtered.length === rows.length,
      };
    },
    approveButtonEnabled() {
      return ((this.selectionStatus.isIndeterminate
      || this.selectionStatus.isAllSelected)
      && this.userWorkflows[this.currentActiveErrorParamIndex].permissions.includes('approval'));
    },
    disableStartStopProceedButton() {
      return this.currentStartStopInputSet.every(input => input.uiValue === input.wfValue);
    },
    savePermissionsButtonValidation() {
      if (this.disableAllPermissionsButton) {
        return true;
      }

      let disableSaveButton = true;
      this.originalWorkflowPermissions.forEach((permission, index) => {
        const modifiedPermission = this.workflowPermissions[index];

        if (JSON.stringify(permission.perms.sort()) !== JSON.stringify(modifiedPermission.perms.sort())) {
          disableSaveButton = false;
        }
      });

      const validWorkflowPermissions = this.workflowPermissions.filter(
        permission => permission.userName === 'Colleagues from same company'
        || (permission.userName.trim() !== '' && permission.perms.length > 0),
      );

      if (validWorkflowPermissions.length > this.originalWorkflowPermissions.length) {
        disableSaveButton = false;
      }

      if (this.workflowPermissions.length > this.originalWorkflowPermissions.length
      && this.workflowPermissions.length !== validWorkflowPermissions.length) {
        disableSaveButton = true;
      }

      return disableSaveButton;
    },
    permissionsAddUserButtonValidate() {
      if (this.disableAllPermissionsButton) {
        return true;
      }

      let disableButton = true;
      const validWorkflowPermissions = this.workflowPermissions.filter(
        permission => permission.userName === 'Colleagues from same company'
        || (permission.userName.trim() !== '' && permission.perms.length > 0),
      );

      if (this.workflowPermissions.length === validWorkflowPermissions.length
      || this.workflowPermissions.length === this.originalWorkflowPermissions.length) {
        disableButton = false;
      }
      return disableButton;
    },
    workflowMigrateSameFeedTypeValidate() {
      return this.workflowMigrateFeedType === 'same-feeds';
    },
    validateTargetWorkflowNameSuggestions() {
      return this.targetWorkflowNameSuggetions.length !== 0 && this.targetWorkflowName !== '';
    },
    validateTargetFeedNameSuggestions() {
      return this.targetFeedNameSuggestions.length !== 0
      && this.migrationTargetFeedNames[this.currentTargetFeedIndex] !== ''
      && this.workflowMigrateFeedType !== 'same-feeds';
    },
    workflowMigrateButtonValidation() {
      return (this.targetWorkflowName.length === 0
      || Object.keys(this.selectedWorkflowMigrateOption).length === 0
      || this.showErrorParameterSetModelNotFound
      || this.workflowAllFeeds.some(feed => !feed.userLicensed
          || feed.misMatchedFeed
          || feed.unRecognized
          || feed.status === 'Pending...')
      );
    },
    validateTargetWorkflowNameField() {
      return this.openMigrateWorkflowModal && this.showErrorParameterSetModelNotFound;
    },
    paginatedData() {
      const arr = [];
      this.workflowParams.forEach((param, index) => {
        if (this.paginatedOptions.length < index + 1) {
          this.paginatedOptions.push({
            firstItem: 1,
            pageSize: 10,
          });
        }
        if (param && Object.hasOwn(param, 'workFlowJobModel')) {
          const inputs = param.workFlowJobModel.parameterSetGroupModel.parameterSetModels;
          const start = this.paginatedOptions[index] ? this.paginatedOptions[index].firstItem - 1 : 0;
          const end = this.paginatedOptions[index] && this.paginatedOptions[index].pageSize !== -1
            ? start + this.paginatedOptions[index].pageSize : inputs.length;
          arr.push(inputs.slice(start, end));
          arr[index].forEach((row, idx) => {
            row['rowIndex'] = idx + start;
          });
        } else {
          arr.push([]);
        }
      });
      return arr;
    },
  },
  watch: {
    workflowParams: {
      handler(value) {
        value.forEach((ele, index) => {
          if (ele && Object.hasOwn(ele, 'workFlowJobModel') && this.paramErrors.length > index) {
            Vue.set(this.paramErrors, index, ele.workFlowJobModel.parameterSetGroupModel.parameterSetModels.map((e) => {
              if (index === this.currentActiveErrorParamIndex) {
                return e.status.errors;
              }
              if (Object.hasOwn(e, 'status') && Object.hasOwn(e.status, 'errors')) {
                e.status.errors.forEach((err) => {
                  err['enabled'] = false;
                });
                return e.status.errors;
              }
              return [];
            }));
          }
        });
      },
      deep: true,
    },
    workflowMigrateFeedType(newVal, oldVal) {
      if (this.workflowMigrateFeedType === 'same-feeds') {
        this.workflowAllFeeds.forEach((workflowFeed, index) => {
          workflowFeed.status = this.actualWorkflowAllFeeds[index].status;
          workflowFeed.misMatchedFeed = this.actualWorkflowAllFeeds[index].misMatchedFeed;
          workflowFeed.unRecognized = this.actualWorkflowAllFeeds[index].unRecognized;
          this.$set(this.migrationTargetFeedNames, index, workflowFeed.feedname);
        });

        this.suffixForCurrentFeedName = '';
        this.suffixForTargetFeedName = '';
        this.targetFeedNameSuggestions = [];
      }
    },
  },
  mounted() {
    this.getSavedUserWorkflows(true);
    this.userWorkflows.forEach((user) => {
      user.isStartStopPermission = false;
    });
    if (this.getFeedList.length === 0) {
      this.getUserFeeds();
    }
    window.addEventListener('resize', this.callPositionTargetFeedNameSuggestionBox);
  },
  created() {
    if (!this.isUserEntitled([FEATURES.WORKFLOW_MANAGER])) {
      this.showPageError = true;
      this.pageError = this.$t('labels.common.featureNotEntitled');
    }
    this.$eventBus.$on('updateWorkflow', (id) => {
      this.updateWorkflowParams(id);
    });
  },
  methods: {
    ...mapActions('workflowModule', [
      'getUserWorkflows',
      'removeUserWorkflowByIndex',
      'removeParameterSetById',
      'getWorkflowStatus',
      'changeWorkflowOwner',
      'getParameterSet',
      'getWorkflowPermissionsById',
      'updateWorkflowPermissionsById',
      'updateUserWorkflows',
      'setCurrentEditableWorkflow',
      'getAllWorkflowsDetails',
      'createNewWorkflow',
      'updateWorkflowByID',
      'getAllLicensedFeedsForUser',
      'getFeedDetailsForWorkflow',
      'saveParameterSet',
      'setWorkflowName',
      'setCurrentWorkflowManager',
      'updateWorkflowScheduleById',
      'updateWorkflowFormulas',
      'updateBubbleDataset',
      'setParamSet',
      'setParamSetWFId',
      'setUserWorkflow',
    ]),
    ...mapActions('feedModule', ['getUserDatasources', 'getUserFeeds', 'getFeedDetailsByName', 'getFeedRoots']),
    handlePageChange(paginationOptions, index) {
      this.$set(this.paginatedOptions, index, paginationOptions);
    },
    async startStopWf(index) {
      this.showStartStopError = false;
      try {
        // let flag = 0;
        this.userWorkflows[index].toggleStartStop = false;
        this.currentActiveStartStopIndex = index;
        const workflowId = this.userWorkflows[index].id;
        this.showStartStopDialog = true;
        this.showStartStopDialogLoader = true;
        await this.getWorkflowStatus(workflowId);
        this.$set(this.workflowParams, index, this.getParamStatus(workflowId));

        this.currentStartStopInputSet = this.workflowParams[
          this.currentActiveStartStopIndex
        ].workFlowJobModel.parameterSetGroupModel.parameterSetModels.map((input) => {
          const temp = { name: input.name };
          if (input.status) {
            temp.uiValue = input.status.finish_date === null;
            temp.wfValue = input.status.finish_date === null;
            temp.runId = input.status.run_id;
          } else {
            temp.uiValue = false;
            temp.wfValue = false;
          }
          temp.param_set_id = input.id;
          temp.workflow_id = workflowId;
          return temp;
        });
      } catch (e) {
        this.showStartStopError = true;
      }
      this.showStartStopDialogLoader = false;
      this.checkState();
    },
    checkState() {
      let flag = 0;
      this.currentStartStopInputSet.forEach((input) => {
        if (!input.uiValue) {
          flag = 1;
        }
      });
      if (flag === 0) {
        this.mainStartStoptoggle = true;
      } else {
        this.mainStartStoptoggle = false;
      }
    },
    startStopAllInputs(value) {
      this.currentStartStopInputSet.forEach((input) => {
        input.uiValue = value;
      });
    },
    showStartStopChanges() {
      const changedInputs = this.currentStartStopInputSet.filter(
        input => input.uiValue !== input.wfValue,
      );
      this.inputsToStart = changedInputs.filter(input => input.uiValue);
      this.inputsToStop = changedInputs.filter(input => !input.uiValue);
      this.showStartStopChangesModal = true;
    },
    async submitStartStop(index) {
      const workflowId = this.userWorkflows[index].id;
      this.diabledStartStopButtons = true;
      const promises = [];
      this.mainShowLoader = true;
      if (this.inputsToStop.length > 0) {
        const params = this.inputsToStop.map(input => input.runId);
        promises.push(axios.post('/api/workflows/stopWorkflowInput', { params }));
        this.mainShowLoader = false;
        this.showStartStopChangesModal = false;
        this.showStartStopDialog = false;
        this.diabledStartStopButtons = false;
        Promise.all(promises).finally(async () => {
          this.showPageSuccess = true;
          this.pageSuccess = 'Successfully start/stop workflow with parameter sets.';
          await this.getWorkflowParamStatus(index, false, false, false);
          this.$set(this.workflowParams, index, this.getParamStatus(workflowId));
        });
      }
      if (this.inputsToStart.length > 0) {
        this.mainShowLoader = false;
        this.showStartStopChangesModal = false;
        this.showStartStopDialog = false;
        this.diabledStartStopButtons = false;
        this.userWorkflows[index].wasWFRestarted = true;
        this.setUserWorkflow(this.userWorkflows);
        await Promise.map(this.inputsToStart, input => axios.post('/api/workflows/startWorkflowInput', {
          params: {
            workflow_id: input.workflow_id,
            param_set_id: input.param_set_id,
          },
        }), { concurrency: 2 }).then(async () => {
          this.showPageSuccess = true;
          this.pageSuccess = 'Successfully start/stop workflow with parameter sets.';
          await this.getWorkflowParamStatus(index, false, false, false);
          this.userWorkflows[index].wasWFRestarted = false;
          this.$refs.wasWFRestarted = false;
          // When the data fetching is complete, trigger the method in WorkflowDiagram
          this.$emit('fetchComplete', false); // Emitting an event to inform child components
          this.setUserWorkflow(this.userWorkflows);
          this.$set(this.workflowParams, index, this.getParamStatus(workflowId));
        });
      }
    },
    showErrorDialog(inputRowIndex, errorRowIndex) {
      this.overlay = true;
      this.showMainLoader = true;
      this.currentActiveErrorParamIndex = inputRowIndex;
      this.currentActiveErrorRowIndex = errorRowIndex;
      this.showValidationFailureDialog = true;
      this.overlay = false;
      this.showMainLoader = false;
    },
    submitUserAction() {
      const actionIds = this.paramErrors[this.currentActiveErrorParamIndex][
        this.currentActiveErrorRowIndex
      ]
        .filter(a => a.enabled)
        .map(error => error.user_actions.map(action => action.action_id));

      actionIds.forEach((error) => {
        error.forEach(async (action) => {
          await axios.post('/api/workflows/approveUserAction', { params: action });
        });
      });
      this.showValidationFailureDialog = false;
      this.getSavedUserWorkflows(true);
    },
    approveAllErrorActionsChanged(value) {
      this.paramErrors[this.currentActiveErrorParamIndex][this.currentActiveErrorRowIndex].forEach(
        (error) => {
          if (error.hasCheckbox || (Object.hasOwn(error, 'user_actions') && error.user_actions.length > 0)) {
            error['enabled'] = value;
          }
        },
      );
    },
    handleRowSelectEvent(rowIndex, isChecked) {
      // set the checked property in the row data to the checked value of the checkbox
      this.$set(this.rows[rowIndex], 'checked', isChecked);
    },
    handleMultiSelection(event) {
      // set all rows checked property to the checked value of the header checkbox
      for (let i = 0; i < this.rows.length; i++) {
        this.$set(this.rows[i], 'checked', event.target.checked);
      }
    },
    setComponentData(response) {
      this.userWorkflows = response.data.workflowsData ? response.data.workflowsData : [];

      this.workflowParams = [...Array(this.userWorkflows.length)];
      this.paramErrors = [...Array(this.userWorkflows.length)];
      this.workflowProgressData = [...Array(this.userWorkflows.length)];
      let count = 0;
      this.userWorkflows[-1] = {};
      this.userWorkflows.forEach((ele) => {
        this.userWorkflows[count].isStartStopPermission = false;
        if (ele.permissions.indexOf('edit') !== -1) {
          this.userWorkflows[count].isEditable = true;
          this.userWorkflows[count].workflowNameToShow = `${ele.id} - ${ele.name}`;
        } else {
          this.userWorkflows[count].isEditable = false;
          this.userWorkflows[count].workflowNameToShow = `${ele.id} - ${ele.name}`;
        }
        if (ele.permissions.indexOf('start_stop') !== -1) {
          this.userWorkflows[count].isStartStopPermission = true;
        }

        this.userWorkflows[count].toggleEditView = false;
        this.userWorkflows[count].toggleStartStop = false;
        this.userWorkflows[count].toggleChangeOwner = false;
        this.userWorkflows[count].toggleChangePermission = false;
        this.userWorkflows[count].toggleDelete = false;
        this.userWorkflows[count].toggleRemove = false;
        this.userWorkflows[count].toggleWorkflowMigration = false;

        if (response.data.config[ele.id] === 1) {
          if (this.userWorkflows[count].name.includes('_retired_')) {
            this.userWorkflows[count].expanded = false;
            this.$set(this.userWorkflows[count], 'expanded', false);
            this.userWorkflows[count].retired = true;
          } else {
            this.userWorkflows[count].showLoader = true;
            this.$set(this.userWorkflows[count], 'expanded', true);
            this.promises.push(this.getWorkflowParamAndUUID(count));
          }
        } else if (this.userWorkflows[count].name.includes('_retired_')) {
          this.userWorkflows[count].expanded = false;
          this.$set(this.userWorkflows[count], 'expanded', false);
          this.userWorkflows[count].retired = true;
        } else {
          this.$set(this.userWorkflows[count], 'workflowParams', {});
          this.$set(this.userWorkflows[count], 'enableActionButtons', true);
        }

        if (ele.name.includes('_retired_')) {
          this.userWorkflows[count].retired = true;
        }
        count++;
      });

      if (this.promises.length > 0) {
        Promise.all(this.promises).finally(() => {
          this.fetchTheStatus();
          this.overlay = false;
          this.showMainLoader = false;
        });
      }
    },
    async getWorkflowParamAndUUID(index) {
      const workflowId = this.userWorkflows[index].id;

      try {
        // Set enableActionButtons property based on existing value
        this.$set(this.userWorkflows[index], 'enableActionButtons', this.userWorkflows[index].enableActionButtons || false);

        const response = await axios.get(`/api/workflows/${workflowId}/parameter-set-groups`, {
          timeout: 30000, // Timeout in milliseconds (e.g., 30 seconds)
        });

        if (Object.keys(response.data).length !== 0) {
          const paramUUIDMap = this.getParamUUIDs(response.data.workFlowJobModel.parameterSetGroupModel.parameterSetModels);

          // Update param UUIDs and errors
          this.statusPayload.params[workflowId] = paramUUIDMap.paramUUIDs;
          const errors = response.data.workFlowJobModel.parameterSetGroupModel.parameterSetModels.map((psm) => {
            psm.fetching = true;
            const temp = psm.status ? JSON.parse(JSON.stringify(psm.status.errors)) : [];
            temp.forEach((error) => {
              error.enabled = false;
              error.hasCheckbox = error.user_actions.length > 0;
            });
            return temp;
          });

          this.$set(this.paramErrors, index, errors);
          this.$set(this.wfParamSetData, workflowId, response.data);
          this.wfParamSetDataArray.push(workflowId);
          this.$set(this.workflowParams, index, response.data);
          this.$set(this.userWorkflows[index], 'showLoader', false);
          this.$set(this.userWorkflows[index], 'workflowParams', response.data);
        } else {
          // Handle case when response data is empty
          this.$set(this.userWorkflows[index], 'errorMessage', 'This workflow has no parameter and no history.');
          this.statusPayload.params[workflowId] = [];
          this.$set(this.workflowParams, index, {});
          this.$set(this.userWorkflows[index], 'workflowParams', {});
          this.$set(this.userWorkflows[index], 'enableActionButtons', true);
          this.$set(this.userWorkflows[index], 'showError', true);
          this.$set(this.userWorkflows[index], 'showLoader', false);
        }
      } catch (error) {
        // Handle errors
        if (error.code === 'ECONNABORTED') {
          // eslint-disable-next-line max-len
          this.$set(this.userWorkflows[index], 'errorMessage', 'The request for workflow status has timed out. Please press the refresh button to try fetching the status again.');
          this.$set(this.workflowParams, index, {});
          this.$set(this.userWorkflows[index], 'workflowParams', {});
          this.$set(this.userWorkflows[index], 'enableActionButtons', true);
          this.$set(this.userWorkflows[index], 'showError', true);
          this.userWorkflows[index].enableActionButtons = true;
        } else {
          // Handle other errors
          console.error('Error fetching workflow parameters:', error);
          // Set appropriate properties and flags
          this.$set(this.userWorkflows[index], 'expanded', false);
          this.$set(this.userWorkflows[index], 'showLoader', false);
        }
      }
    },
    async fetchTheStatus() {
      await axios.post('/api/workflows/parameter-status', this.statusPayload).then(async (response) => {
        if (response) {
          const errors = [];
          for (let i = 0; i < this.wfParamSetDataArray.length; i++) {
            const wfId = this.wfParamSetDataArray[i];
            const currStatusVal = response.data.result[wfId].value;
            this.wfParamSetData[wfId].workFlowJobModel.isWFStarted = false;

            const currentParamModels = this.wfParamSetData[wfId].workFlowJobModel.parameterSetGroupModel.parameterSetModels;
            this.wfParamSetData[wfId].workFlowJobModel.count = 0;
            for (let l = 0; l < currentParamModels.length; l++) {
              const psgModels = currentParamModels[l];
              for (let k = 0; k < currStatusVal.length; k++) {
                const val = currStatusVal[k];
                if (psgModels.uuid === val.param_set_uuid) {
                  this.wfParamSetData[wfId].workFlowJobModel.parameterSetGroupModel.parameterSetModels[l].status = val;
                  this.wfParamSetData[wfId].workFlowJobModel.isWFStarted = true;
                  if (this.wfParamSetData[wfId].workFlowJobModel.parameterSetGroupModel.parameterSetModels[l].status.percent_complete === 100) {
                    this.wfParamSetData[wfId].workFlowJobModel.count = this.wfParamSetData[wfId].workFlowJobModel.count + 1;
                  }
                  this.wfParamSetData[wfId].workFlowJobModel.parameterSetGroupModel.parameterSetModels[l].fetching = false;
                } else {
                  this.wfParamSetData[wfId].workFlowJobModel.parameterSetGroupModel.parameterSetModels[l].fetching = false;
                }
              }
            }
          }
          this.setParamSet({ paramsetData: this.wfParamSetData, wfParamSetDataArray: this.wfParamSetDataArray });
          this.overlay = false;
          this.showMainLoader = false;
          return true;
        }
      }).catch((error) => {
        // this.showPageError = true;
        this.overlay = false;
        this.showMainLoader = false;
        // this.pageError = 'Error occurred while fetching status for workflows';
        return false;
      }).finally(() => {
        for (let l = 0; l < this.userWorkflows.length; l++) {
          this.$set(this.workflowParams, l, {});
          if (this.wfParamSetData[this.userWorkflows[l].id]) {
            const obj = {
              workFlowJobModel: this.wfParamSetData[this.userWorkflows[l].id].workFlowJobModel,
            };
            this.$set(this.userWorkflows[l].workflowParams, l, obj);
            this.$set(this.workflowParams, l, obj);
            this.$set(this.userWorkflows[l], 'showLoader', false);
            this.$set(this.userWorkflows[l], 'enableActionButtons', true);
            if (this.userWorkflows[l].workflowParams.workFlowJobModel) {
              const { count } = this.userWorkflows[l].workflowParams.workFlowJobModel;
              const total = this.userWorkflows[l].workflowParams.workFlowJobModel.parameterSetGroupModel.parameterSetModels ? this.userWorkflows[l].workflowParams.workFlowJobModel.parameterSetGroupModel.parameterSetModels.length : 0;
              this.$set(this.userWorkflows[l], 'completedInfo', `(Completed ${ count }) / ${ total }`);
            }
            this.userWorkflows[l].enableActionButtons = true;
            this.userWorkflows[l].showLoader = false;
            this.setUserWorkflow(this.userWorkflows[l]);

            const errors = [];
            if (this.userWorkflows[l].workflowParams.workFlowJobModel) {
              this.userWorkflows[l].workflowParams.workFlowJobModel.parameterSetGroupModel.parameterSetModels.forEach(
                (psm) => {
                  if (Object.hasOwnProperty.call(psm, 'status')) {
                    const temp = JSON.parse(JSON.stringify(psm.status.errors));
                    temp.forEach((error) => {
                      error.enabled = false;
                      error.hasCheckbox = error.user_actions.length > 0;
                    });
                    errors.push(temp);
                  } else {
                    errors.push([]);
                  }
                },
              );
            }
            this.$set(this.paramErrors, l, errors);
            if (this.userWorkflows[l].expanded) {
              this.setWorkflowProgressPopup(l);
            }
          } else {
            this.$set(this.workflowParams, l, {});
            this.$set(this.paramErrors, l, []);
          }
        }
        this.overlay = false;
        this.showMainLoader = false;
        this.promises = [];
        this.statusPayload = {
          // id: crypto.createHash('sha1').update(Date.now().toString()).digest('hex'),
          jsonrpc: '2.0',
          method: 'get_workflow_status_3',
          params: {},
        };
        this.wfParamSetDataArray = [];
      });
      this.overlay = false;
      this.showMainLoader = false;
    },
    getParamUUIDs(params) {
      const paramUUIDs = [];
      const uuidParamMap = {};

      if (Array.isArray(params)) {
        params.forEach((param) => {
          paramUUIDs.push(param.uuid);
          uuidParamMap[param.uuid] = param;
        });
      } else {
        paramUUIDs.push(params.uuid);
        uuidParamMap[params.uuid] = params;
      }

      uuidParamMap['paramUUIDs'] = paramUUIDs;
      return uuidParamMap;
    },
    getWorkflowProgressPercentage(pRow) {
      if (pRow.status && pRow.status.percent_complete !== undefined) {
        return `${pRow.status.percent_complete.toString()}%`;
      }
      return 'Not Started';
    },
    getSavedUserWorkflows(withLoader) {
      if (withLoader) {
        this.showLoader = true;
      }
      const username = getUserName();
      this.getUserWorkflows(username)
        .then((response) => {
          this.setComponentData(response);
        })
        .finally(() => {
          if (withLoader) {
            this.showLoader = false;
          }
        });
    },
    async toggleRow(index, isUpdate) {
      this.promises.forEach((promise) => {
        if (typeof promise.cancel === 'function') {
          promise.cancel();
        }
      });
      const workflowId = this.userWorkflows[index].id;
      const username = getUserName();
      if (this.userWorkflows[index].name.includes('_retired_')) {
        this.userWorkflows[index].expanded = false;
        this.userWorkflows[index].retired = true;
        return;
      }
      const status = this.userWorkflows[index].expanded
        ? !this.userWorkflows[index].expanded
        : true;

      this.userWorkflows[index].expanded = status;
      this.$set(this.userWorkflows[index], 'expanded', status);

      if (isUpdate) {
        this.overlay = true;
        this.showMainLoader = true;

        let proceedFurther = false;
        if (status && (this.workflowParams[index] ? Object.keys(this.workflowParams[index]).length === 0 : true)) {
          this.$set(this.workflowParams, index, {});
          this.$set(this.userWorkflows[index], 'showLoader', true);
          proceedFurther = true;
        }
        this.$set(this.userWorkflows[index], 'showLoader', true);
        const val = status ? 1 : 0;

        await axios.post(`/api/workflows/${username}/${workflowId}/${val}/update`).then(async (response) => {
          if (response.status === 200) {
            if (proceedFurther) {
              this.$set(this.userWorkflows[index], 'showLoader', true);
              this.$set(this.workflowParams, index, undefined);
              this.$set(this.workflowProgressData, index, undefined);
              this.$set(this.paramErrors, index, undefined);
              this.promises.push(this.getWorkflowParamAndUUID(index));

              if (this.promises.length > 0) {
                Promise.all(this.promises).finally(() => {
                  this.fetchTheStatus();
                  this.overlay = false;
                  this.showMainLoader = false;
                });
              }
            }
          }
          this.overlay = false;
          this.showMainLoader = false;
        }).catch((error) => {
          this.showPageError = true;
          this.pageError = 'Something went wrong. Please refresh the page.';
          this.userWorkflows[index].expanded = false;
          this.$set(this.userWorkflows[index], 'expanded', false);
          this.userWorkflows[index].showLoader = false;
          this.$set(this.userWorkflows[index], 'showLoader', false);
          this.overlay = false;
          this.showMainLoader = false;
          return false;
        }).finally(() => {
          this.overlay = false;
          this.showMainLoader = false;
        });
      } else {
        this.$set(this.userWorkflows[index], 'expanded', status);
        this.overlay = false;
        this.showMainLoader = false;
      }
      this.overlay = false;
      this.showMainLoader = false;
    },
    async showEditModal(index, operation) {
      this.userWorkflows[index].toggleEditView = false;
      if ((index !== null && operation === 'edit') || (index !== null && operation === 'view')) {
        if (this.workflowParams[index] ? Object.keys(this.workflowParams[index]).length === 0 : true) {
          await this.getWorkflowParamStatus(index, false, true, true);
        }
        this.$set(this.showEdit, index, true);
        this.currentActiveEditIndex = index;
        this.wfOperation = operation;
        this.workflowName = this.userWorkflows[this.currentActiveEditIndex]['name'];
      } else if (index === -1 && operation === 'create') {
        this.currentActiveEditIndex = index;
        this.wfOperation = operation;
        this.workflowName = '';
      }
    },
    setVariablesForImportingWorkflow() {
      this.showImportDialog = true;
      this.showImportSuccess = false;
      this.showImportError = false;
      this.showImportLoader = false;
      this.importErrorMessage = '';
      this.importWorkflowId = '';
    },
    parseWorkflowConfigContents() {
      try {
        if (!this.workflowConfigContents) {
          throw new Error('Config cannot be empty!');
        }
        const parameterSetGroup = JSON.parse(this.workflowConfigContents
          .split(/\n=+\nPARAMETER-SET-GROUP\n\n/mg)[1]
          .split(/\n\n=+\nFORMULA/mg)[0]);

        const workflowConfig = JSON.parse(this.workflowConfigContents
          .split(/=+\nWORKFLOW CONFIG\n/mg)[1]);

        const workflowFormula = this.workflowConfigContents
          .split(/=+\nFORMULA -- [a-z0-9-]+\n\n\n/mg)[1]
          .split(/=+\nWORKFLOW CONFIG\n/mg)[0];

        this.inputSetEndRange = parameterSetGroup.parameterSetModels.length;
        this.workflowConfigName = `${this.workflowConfigName}_${workflowConfig.id}`;

        this.workflowImportPayload = {
          parameterSetGroup,
          workflowConfig,
          workflowFormula,
        };

        const { feedNames, sourceNames } = this.getUniqueFeedsForImportingWorkflow(this.workflowImportPayload.parameterSetGroup);
        this.prepareImportFeedsToMigrate(feedNames, sourceNames);
        this.importParsedVariables = true;

        parameterSetGroup.parameterSetModels.forEach((psm) => {
          this.selectedInputSetMap[psm.name] = true;
        });

        this.showImportError = false;
        this.showImportSuccess = false;
      } catch (error) {
        this.importErrorMessage = (error.response && error.response.data && error.response.data.errorMessage) || error.message;
        this.showImportError = true;
        this.showImportSuccess = false;
        this.importWorkflowId = '';
        this.importParsedVariables = false;
      }
    },
    getUniqueFeedsForImportingWorkflow(parameterSetGroup) {
      const feedObjects = [];
      const dataSourceObjects = [];
      parameterSetGroup.parameterSetModels.forEach((psm) => {
        const { parameterModels } = psm;
        const feedNames = parameterModels
          .filter(pm => pm.propKey.includes('.feed'))
          .map(tpmf => tpmf.propValue);
        const dataSourceNames = parameterModels
          .filter(pm => pm.propKey.includes('.source'))
          .map(tpmf => tpmf.propValue);
        feedObjects.push(...feedNames);
        dataSourceObjects.push(...dataSourceNames);
      });

      return {
        feedNames: Array.from(new Set(feedObjects)),
        sourceNames: Array.from(new Set(dataSourceObjects)),
      };
    },
    replaceFeedsAndSources(feeds, sources, parameterSetGroup) {
      let stringifiedPSM = JSON.stringify(parameterSetGroup);
      sources.forEach((source) => {
        stringifiedPSM = stringifiedPSM.replace(new RegExp(`"${source.before}"`, 'g'), `"${source.after}"`);
      });
      feeds.forEach((feed) => {
        stringifiedPSM = stringifiedPSM.replace(new RegExp(`"${feed.before}"`, 'g'), `"${feed.after}"`);
      });
      return JSON.parse(stringifiedPSM);
    },
    prepareImportFeedsToMigrate(feedNames, sourceNames) {
      this.importFeedsToBeChanged = feedNames
        .map(f => `${f} ~ ${f}`)
        .join('\n');
      this.importFeedSourcesToBeChanged = sourceNames
        .map(f => `${f} ~ ${f}`)
        .join('\n');
    },
    generateWorkflowFromConfig() {
      if (!this.workflowConfigContents) return;
      const { parameterSetGroup, workflowConfig, workflowFormula } = this.workflowImportPayload;
      const feedsToBeChanged = this.importFeedsToBeChanged.trim().split('\n').map((c) => {
        const [source, destination] = c.split('~').map(f => f.trim());
        return {
          before: source,
          after: destination,
        };
      });
      const sourcesToBeChanged = this.importFeedSourcesToBeChanged.trim().split('\n').map((c) => {
        const [source, destination] = c.split('~').map(f => f.trim());
        return {
          before: source,
          after: destination,
        };
      });
      const newParameterSetGroup = this.replaceFeedsAndSources(feedsToBeChanged, sourcesToBeChanged, parameterSetGroup);
      const selectedInputSetForNewWorkflow = Object.keys(this.selectedInputSetMap).filter(k => this.selectedInputSetMap[k]);

      if (this.selectInputsByType === 'Range') {
        newParameterSetGroup.parameterSetModels = newParameterSetGroup.parameterSetModels
          .slice(+this.inputSetStartRange - 1, +this.inputSetEndRange);
      } else {
        newParameterSetGroup.parameterSetModels = newParameterSetGroup.parameterSetModels
          .filter(psm => selectedInputSetForNewWorkflow.includes(psm.name));
      }

      this.generateWorkflow(newParameterSetGroup, workflowConfig, workflowFormula);
    },
    async getWorkflowParamStatus(index, showNotif, showLoader, showOverlay) {
      if (showOverlay) {
        this.overlay = true;
      }
      // this.showMainLoader = true;
      if (!this.userWorkflows[index].expanded) {
        this.showMainLoader = showLoader;
      }
      this.showLoader = false;
      this.$set(this.workflowParams, index, undefined);
      const workflowId = this.userWorkflows[index].id;
      if (this.userWorkflows[index].enableActionButtons) {
        this.$set(this.userWorkflows[index], 'enableActionButtons', true);
      } else {
        this.$set(this.userWorkflows[index], 'enableActionButtons', false);
      }
      if (this.userWorkflows[index].errorMessage) {
        // this.$set(this.workflowParams, index, undefined);
        this.$set(this.userWorkflows[index], 'enableActionButtons', false);
        this.$set(this.userWorkflows[index], 'showLoader', true);
        this.$set(this.userWorkflows[index], 'workflowParams', {});
        this.$set(this.userWorkflows[index], 'showError', false);
        this.$set(this.userWorkflows[index], 'errorMessage', null);
      }
      await this.getWorkflowStatus(workflowId)
        .then((response) => {
          this.$set(this.userWorkflows[index], 'enableActionButtons', true);
          this.$set(this.workflowParams, index, this.getParamStatus(workflowId));
          const errors = [];
          response.data.workFlowJobModel.count = 0;
          response.data.workFlowJobModel.parameterSetGroupModel.parameterSetModels.forEach(
            (psm) => {
              if (Object.hasOwnProperty.call(psm, 'status')) {
                this.wfParamSetData[workflowId].workFlowJobModel.isWFStarted = true;
                if (psm.status.percent_complete === 100) {
                  response.data.workFlowJobModel.count = response.data.workFlowJobModel.count + 1;
                }
                const temp = JSON.parse(JSON.stringify(psm.status.errors));
                temp.forEach((error) => {
                  error.enabled = false;
                  error.hasCheckbox = error.user_actions.length > 0;
                });
                errors.push(temp);
              } else {
                errors.push([]);
                this.wfParamSetData[workflowId].workFlowJobModel.isWFStarted = false;
              }
            },
          );
          this.$set(this.workflowParams, index, response.data);
          this.$set(this.userWorkflows[index], 'completedInfo', `(Completed ${ response.data.workFlowJobModel.count }/${ response.data.workFlowJobModel.parameterSetGroupModel.parameterSetModels.length })`);

          this.$set(this.paramErrors, index, errors);
          this.setWorkflowProgressPopup(index);
          if (showNotif) {
            this.showPageSuccess = true;
            this.pageSuccess = `Successfully fetch the status for workflow.${ this.userWorkflows[index].workflowNameToShow}`;
          }
          this.$set(this.userWorkflows[index], 'showLoader', false);
          this.overlay = false;
          this.showMainLoader = false;
        })
        .catch((error) => {
          this.$set(
            this.userWorkflows[index],
            'errorMessage',
            'This workflow has no parameter and no history.',
          );
          this.$set(this.userWorkflows[index], 'showError', true);
          this.$set(this.userWorkflows[index], 'showLoader', false);
          this.overlay = false;
          this.showMainLoader = false;
        });
      // this.overlay = false;
      // this.showMainLoader = false;
    },
    async updateWorkflowParams(id) {
      await this.getSavedUserWorkflows(false);
      const index = this.userWorkflows.findIndex(wf => wf.id === id);
      this.$set(this.workflowParams, index, this.getParamStatus(id));
    },
    closeSearch(refresh) {
      this.toggle = false;
      if (refresh) {
        this.getSavedUserWorkflows(true);
      }
    },
    async closeEditModal(index, btnType) {
      this.showLoader = false;
      this.userWorkflows = this.getUserWorkflowsData;
      if (btnType === 'cancel') {
        this.closeWorkflowModal = true;
      } else if (btnType === 'close') {
        this.$refs.wfEdit.toggleFullscreen = false;
        this.closeWorkflowModal = false;
        this.$set(this.showEdit, index, false);
        this.currentActiveEditIndex = -2;
        const workflowId = this.userWorkflows[index].id;
        let wasWFRestartedStatus = false;
        if (this.userWorkflows[index].wasWFRestarted) {
          wasWFRestartedStatus = this.userWorkflows[index].wasWFRestarted;
        }
        if (workflowId) {
          const freshWFDeatils = await axios.get(`/api/workflows/${workflowId}/getWorkflowByID`);
          freshWFDeatils.data.workflowNameToShow = `${freshWFDeatils.data.id} - ${freshWFDeatils.data.name}`;
          freshWFDeatils.data.enableActionButtons = true;
          freshWFDeatils.data.expanded = false;
          freshWFDeatils.data.toggleEditView = false;
          freshWFDeatils.data.toggleStartStop = false;
          freshWFDeatils.data.toggleChangeOwner = false;
          freshWFDeatils.data.toggleChangePermission = false;
          freshWFDeatils.data.toggleDelete = false;
          freshWFDeatils.data.toggleRemove = false;
          freshWFDeatils.data.toggleWorkflowMigration = false;
          freshWFDeatils.data.isEditable = !!freshWFDeatils.data.permissions.includes('edit');
          freshWFDeatils.data.isStartStopPermission = !!freshWFDeatils.data.permissions.includes('start_stop');
          if (wasWFRestartedStatus) {
            freshWFDeatils.data.wasWFRestarted = wasWFRestartedStatus;
          }
          this.userWorkflows = this.getUserWorkflowsData;
          let flag = 0;
          this.userWorkflows.forEach((wf) => {
            if (wf.id === freshWFDeatils.data.id) {
              freshWFDeatils.data.expanded = wf.expanded ? wf.expanded : false;
              this.setUserWorkflow(freshWFDeatils.data);
              flag = 1;
            }
          });
          if (flag === 0) {
            freshWFDeatils.data.expanded = false;
            this.userWorkflows.push(freshWFDeatils.data);
            this.updateUserWorkflowsDetails(this.userWorkflows);
          }
        }

        this.setCurrentEditableWorkflow({
          workflowUI: {},
          paramSet: {},
          formulae: [],
          newlyAddedFormule: [],
          operation: '',
          nodes: [],
          targets: [],
          links: {},
        });
        this.setCurrentWorkflowManager({});
      }
    },
    async closeModel(index) {
      this.overlay = true;
      this.closeWorkflowModal = false;
      this.$set(this.showEdit, index, false);
      const prevActiveEditIndex = this.currentActiveEditIndex;
      const workflowId = this.userWorkflows[this.currentActiveEditIndex].id;
      let wasWFRestartedStatus = false;
      if (this.userWorkflows[this.currentActiveEditIndex].wasWFRestarted) {
        wasWFRestartedStatus = this.userWorkflows[this.currentActiveEditIndex].wasWFRestarted;
      }
      this.currentActiveEditIndex = -2;
      if (workflowId) {
        const freshWFDeatils = await axios.get(`/api/workflows/${workflowId}/getWorkflowByID`);
        freshWFDeatils.data.workflowNameToShow = `${freshWFDeatils.data.id} - ${freshWFDeatils.data.name}`;
        freshWFDeatils.data.enableActionButtons = true;
        freshWFDeatils.data.expanded = false;
        freshWFDeatils.data.toggleEditView = false;
        freshWFDeatils.data.toggleStartStop = false;
        freshWFDeatils.data.toggleChangeOwner = false;
        freshWFDeatils.data.toggleChangePermission = false;
        freshWFDeatils.data.toggleDelete = false;
        freshWFDeatils.data.toggleRemove = false;
        freshWFDeatils.data.toggleWorkflowMigration = false;
        freshWFDeatils.data.isEditable = !!freshWFDeatils.data.permissions.includes('edit');
        freshWFDeatils.data.isStartStopPermission = !!freshWFDeatils.data.permissions.includes('start_stop');
        if (wasWFRestartedStatus) {
          freshWFDeatils.data.wasWFRestarted = wasWFRestartedStatus;
        }
        this.userWorkflows = this.getUserWorkflowsData;
        let flag = 0;
        this.userWorkflows.forEach((wf) => {
          if (wf.id === freshWFDeatils.data.id) {
            freshWFDeatils.data.expanded = wf.expanded ? wf.expanded : false;
            this.setUserWorkflow(freshWFDeatils.data);
            flag = 1;
          }
        });
        if (flag === 0) {
          freshWFDeatils.data.expanded = false;
          this.userWorkflows.push(freshWFDeatils.data);
          this.updateUserWorkflowsDetails(this.userWorkflows);
        }
      }
      this.overlay = false;

      await this.getWorkflowParamStatus(prevActiveEditIndex, false, true);
      // this.getSavedUserWorkflows(true);
      this.setCurrentEditableWorkflow({
        workflowUI: {},
        paramSet: {},
        formulae: [],
        newlyAddedFormule: [],
        operation: '',
        nodes: [],
        targets: [],
        links: {},
      });
      this.setCurrentWorkflowManager({});
    },
    keepOpenModal() {
      this.closeWorkflowModal = false;
      this.$refs.workflowEdit.toggleFullscreen = true;
    },
    async resetWorkflowEdit(workflowId, workflowParam) {
      this.userWorkflows = this.getUserWorkflowsData;

      let count = 0;
      this.userWorkflows.forEach((wf) => {
        if (wf.id === workflowId) {
          this.wfOperation = wf.permissions.includes('edit') ? 'edit' : 'view';
          this.currentActiveEditIndex = count;
          this.workflowName = wf.name;
          this.$set(this.workflowParams, count, workflowParam);
        }
        count++;
      });
    },
    hideError() {
      this.showError = false;
    },
    hideSuccess() {
      this.showSuccess = false;
    },
    showChangeOwnerModal(index) {
      this.userWorkflows[index].toggleChangeOwner = false;
      this.workflowName = this.userWorkflows[index]['name'];
      this.workflowCurrentOwner = this.userWorkflows[index]['owner'];
      this.currentSelectedWorkflowIndex = index;
      this.showChangeOwnerDialog = true;
      const username = getUserName();
      if (this.workflowCurrentOwner.toLowerCase() !== username.toLowerCase()) {
        this.showChangeOwnerPermissionWarning = true;
      }
    },
    closeChangeOwnerModal() {
      this.showSaveButtonLoader = false;
      this.showChangeOwnerDialog = false;
      this.showChangeOwnerPermissionWarning = false;
      this.workflowName = '';
      this.workflowCurrentOwner = '';
      this.workflowNewOwner = '';
      this.currentSelectedWorkflowIndex = -1;
    },
    async changeWfOwner() {
      this.showSaveButtonLoader = true;
      const { id: workflowId } = this.userWorkflows[this.currentSelectedWorkflowIndex];
      const newOwnerName = this.workflowNewOwner;
      const payload = { workflowId, newOwnerName };

      this.changeWorkflowOwner(payload)
        .then(async (response) => {
          const freshWFDeatils = await axios.get(`/api/workflows/${workflowId}/getWorkflowByID`);
          freshWFDeatils.data.workflowNameToShow = `${freshWFDeatils.data.id} - ${freshWFDeatils.data.name}`;
          freshWFDeatils.data.enableActionButtons = true;
          freshWFDeatils.data.expanded = false;
          if (freshWFDeatils.data.name.includes('_retired_')) {
            freshWFDeatils.data.retired = true;
          }
          freshWFDeatils.data.toggleEditView = false;
          freshWFDeatils.data.toggleStartStop = false;
          freshWFDeatils.data.toggleChangeOwner = false;
          freshWFDeatils.data.toggleChangePermission = false;
          freshWFDeatils.data.toggleDelete = false;
          freshWFDeatils.data.toggleRemove = false;
          freshWFDeatils.data.toggleWorkflowMigration = false;
          freshWFDeatils.data.isEditable = !!freshWFDeatils.data.permissions.includes('edit');
          freshWFDeatils.data.isStartStopPermission = !!freshWFDeatils.data.permissions.includes('start_stop');
          this.$set(this.userWorkflows, this.currentSelectedWorkflowIndex, freshWFDeatils.data);
          this.$set(this.workflowParams, this.currentSelectedWorkflowIndex, {});
          this.$set(this.workflowProgressData, this.currentSelectedWorkflowIndex, {});
          this.successMessage = 'Workflow owner has been successfully updated.';
          this.showSuccess = true;

          // this.getSavedUserWorkflows(false);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.errorMessage = error.response.data.errorMessage;
          } else {
            this.errorMessage = 'Failed changing workflow owner.';
          }
          this.showError = true;
        })
        .finally(() => {
          this.closeChangeOwnerModal();
        });
    },
    showDetailsPopup(index, i) {
      if (this.workflowParams[index].workFlowJobModel) {
        this.workflowParams[index].workFlowJobModel.parameterSetGroupModel.parameterSetModels[
          i
        ].showDetails = !this.workflowParams[index].workFlowJobModel.parameterSetGroupModel
          .parameterSetModels[i].showDetails;
      }
    },
    showPermissionsModal(index) {
      this.userWorkflows[index].toggleChangePermission = false;
      this.showLoader = true;
      this.workflowName = this.userWorkflows[index]['name'];
      this.workflowCurrentOwner = this.userWorkflows[index]['owner'];
      const { id: workflowId } = this.userWorkflows[index];
      this.currentSelectedWorkflowIndex = index;

      this.getWorkflowPermissionsById(workflowId)
        .then((response) => {
          this.workflowPermissions = response.data;

          const isPermissionsForAllUsersExists = this.workflowPermissions.some(
            permsObj => permsObj.userName === '<<ENTIRE_COMPANY>>',
          );

          if (isPermissionsForAllUsersExists) {
            this.workflowPermissions.forEach((permsObj) => {
              if (permsObj.userName === '<<ENTIRE_COMPANY>>') {
                permsObj.userName = 'Colleagues from same company';
              }
            });
          } else {
            this.workflowPermissions.unshift({
              userName: 'Colleagues from same company',
              perms: [],
            });
          }

          const selectedWf = this.userWorkflows[index];
          if (!selectedWf.permissions.includes('edit')) {
            this.disableAllPermissionsButton = true;
          }
          this.originalWorkflowPermissions = JSON.parse(JSON.stringify(this.workflowPermissions));
          this.savedWorkflowPermissionsCount = this.workflowPermissions.length;
          this.showPermissionsDialog = true;
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.errorMessage = error.response.data.errorMessage;
          }
          this.errorMessage = 'Failed to fetch workflow permissions details.';
          this.showError = true;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    isPermissionBtnChecked(workflowPermission, permission) {
      return workflowPermission.perms.includes(permission);
    },
    AddUserToWorkflowPermissions() {
      const newUser = {
        userName: '',
        perms: [],
      };
      this.workflowPermissions.push(newUser);
    },
    checkPermissionsBtn(workflowPermission, permission, index) {
      this.permissionsButtonClickCount += 1;
      const permIndex = workflowPermission.perms.indexOf(permission);

      if (permIndex === -1) {
        // If not present, add it to the array
        if ((permission === 'input_edit' || permission === 'formula_edit')
          && workflowPermission.perms.indexOf('view') === -1) {
          workflowPermission.perms.push('view');
          this.workflowPermissions[index].disableViewToggle = true;
        } else if ((permission === 'input_edit' || permission === 'formula_edit')
          && workflowPermission.perms.indexOf('view') !== -1) {
          this.workflowPermissions[index].disableViewToggle = true;
        }
        workflowPermission.perms.push(permission);
      } else {
        // If present, remove it from the array
        if (permission === 'input_edit' && workflowPermission.perms.indexOf('formula_edit') === -1) {
          const permIndexView = workflowPermission.perms.indexOf('view');
          workflowPermission.perms.splice(permIndexView, 1);
          this.workflowPermissions[index].disableViewToggle = false;
        } else if (permission === 'formula_edit' && workflowPermission.perms.indexOf('input_edit') === -1) {
          const permIndexView = workflowPermission.perms.indexOf('view');
          workflowPermission.perms.splice(permIndexView, 1);
          this.workflowPermissions[index].disableViewToggle = false;
        }
        workflowPermission.perms.splice(permIndex, 1);
      }
    },
    setUserNameForPerms(workflowPermission) {
      workflowPermission.userName = this.workflowPermsNewUser;
      this.workflowPermsNewUser = '';
    },
    closePermissionsDialog() {
      this.showSaveButtonLoader = false;
      this.showPermissionsDialog = false;
      this.workflowName = '';
      this.workflowPermissions = [];
      this.originalWorkflowPermissions = [];
      this.savedWorkflowPermissionsCount = 0;
      this.currentSelectedWorkflowIndex = -1;
      this.disableAllPermissionsButton = false;
    },
    saveWorkflowPermissions() {
      this.showSaveButtonLoader = true;
      const { id: workflowId } = this.userWorkflows[this.currentSelectedWorkflowIndex];
      const payload = this.workflowPermissions
        .filter(permission => permission.userName.trim() !== '' && permission.perms.length > 0)
        .map(permission => ({
          ...permission,
          userName: permission.userName === 'Colleagues from same company' ? '<<ENTIRE_COMPANY>>' : permission.userName,
        }));

      this.updateWorkflowPermissionsById({ workflowId, payload })
        .then((response) => {
          this.successMessage = 'Workflow permissions has been successfully updated.';
          this.showSuccess = true;
          this.closePermissionsDialog();
        })
        .catch((error) => {
          this.showSaveButtonLoader = false;
          if (error.response && error.response.data) {
            this.errorMessage = error.response.data.errorMessage;
          } else {
            this.errorMessage = 'Failed updating workflow permissions.';
          }
          this.showError = true;
        });
    },
    getInputOutputVars(type, workflow) {
      const { datasets } = workflow.ui;
      const dataSetInputBubbles = workflow.ui.bubbles.filter(b => b.type === type);
      const dataSetVariableNames = [];

      for (let i = 0; i < dataSetInputBubbles.length; ++i) {
        const bubbleId = dataSetInputBubbles[i].id;
        dataSetVariableNames.push(...datasets.filter(ds => ds.bubbleId === bubbleId));
      }

      return dataSetVariableNames;
    },
    getNotCompletedWorkflowDependencies(dependenciesList, workflowId, dependencyType) {
      const notCompletedDependencies = [];
      // Use map instead of forEach for better readability and direct creation of the array
      dependenciesList
        .filter(d => d.name === dependencyType)
        .forEach((dep) => {
          const { name, dependencies } = dep;
          const deps = dependencies.map((_, i) => ({
            completed: undefined,
            id: `${workflowId}|${name}|${i.toString().padStart(3, '0')}`,
            reason: 'message',
          }));
          // Avoid spread operator for concatenation, directly push items to the array
          notCompletedDependencies.push(...deps);
        });
      return notCompletedDependencies;
    },
    getCompletedAndIncompleteDeps(completedDependencies, incompleteDependencies) {
      const mergedDependencies = [...completedDependencies];
      const completedIdsSet = new Set(completedDependencies.map(dep => dep.id));
      // Use filter to avoid nested loop, improving time complexity
      incompleteDependencies.forEach((inc) => {
        if (!completedIdsSet.has(inc.id)) {
          mergedDependencies.push(inc);
        }
      });
      return mergedDependencies;
    },

    getWorkflowDependenciesStatus(dependenciesList, parameterSetModel, workflowId) {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      const dependenciesCompleteStatus = [];
      dependenciesList.forEach((dep) => {
        const { name, dependencies } = dep;
        const { status, parameterModels } = parameterSetModel;

        if (!status || !parameterModels) return;

        const filteredStatusDeps = status.dependencies.filter(d => d.id.includes(name));
        const notCompletedDeps = this.getNotCompletedWorkflowDependencies(dependenciesList, workflowId, name);
        const dependenciesCompletionTimeList = this.getCompletedAndIncompleteDeps(filteredStatusDeps, notCompletedDeps);

        // Use map for direct transformation instead of forEach
        dependenciesCompletionTimeList.forEach((dd) => {
          const { completed, id, reason } = dd;
          const ddIdx = +id.split('|')[2];
          const feedDetailsObj = dependencies[ddIdx];

          if (!feedDetailsObj.columns || !feedDetailsObj.feed) return;

          let colNames = parameterModels.find(pm => feedDetailsObj.columns.includes(pm.propKey));
          colNames = colNames ? colNames.propValue : undefined;
          colNames = colNames && colNames.includes(']') ? JSON.parse(colNames).join(', ') : colNames;


          let keyNames = parameterModels.find(pm => (feedDetailsObj.keys || feedDetailsObj.roots).includes(pm.propKey));
          keyNames = keyNames ? keyNames.propValue : undefined;

          if (keyNames && keyNames.includes('}')) {
            const keysObj = JSON.parse(keyNames);
            keyNames = Object.keys(keysObj).map(k => keysObj[k]).join(', ');
          } else if (keyNames !== undefined && keyNames.includes(']')) {
            keyNames = JSON.parse(keyNames).join(', ');
          }

          const varNameMatch = /{{udef.ds.(.*).feed}}/gm.exec(feedDetailsObj.feed);
          const varName = varNameMatch ? varNameMatch[1] : undefined;

          const feedDetails = {
            varName,
            feed: (parameterModels.find(pm => feedDetailsObj.feed.includes(pm.propKey)) || {}).propValue,
            cols: colNames,
            keys: keyNames,
            completionTime: completed ? new Date(completed).toLocaleString('en-US', { timeZone }) : 'N/A',
          };
          dependenciesCompleteStatus.push(feedDetails);
        });
      });
      return {
        dependenciesCompleteStatus,
      };
    },
    getWorkflowProgressII(index, tIndex) {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      const workflowJobModel = this.workflowParams[index].workFlowJobModel;
      const parameterSetModel = workflowJobModel.parameterSetGroupModel.parameterSetModels[tIndex];
      const statusObject = parameterSetModel.status;
      const workflowTargets = this.userWorkflows[index].targets;
      const dataDependenciesList = workflowTargets
        .filter(t => t.name.includes('formula'))
        .map(t => ({
          name: t.name,
          dependencies: t.dependencies || [],
        }));
      const publishDependenciesList = workflowTargets
        .filter(t => t.name.includes('notif') || t.name.includes('workflow.completion'))
        .map(t => ({
          name: t.name,
          dependencies: t.dependencies || [],
        }));
      const dataDependenciesWorkflowStatus = this.getWorkflowDependenciesStatus(
        dataDependenciesList, parameterSetModel, this.userWorkflows[index].id,
      );
      const publishDependenciesWorkflowStatus = this.getWorkflowDependenciesStatus(
        publishDependenciesList, parameterSetModel, this.userWorkflows[index].id,
      );
      const combinedProgress = [
        ...dataDependenciesWorkflowStatus.dependenciesCompleteStatus,
        ...publishDependenciesWorkflowStatus.dependenciesCompleteStatus,
      ];
      const actionHistory = statusObject ? statusObject.action_history : [];
      let userActionHistoryList = [];
      if (actionHistory && actionHistory.length > 0) {
        userActionHistoryList = actionHistory.map((ah) => {
          const {
            user, date, type, data, ref,
          } = ah;
          return {
            user,
            runDate: date ? new Date(date).toLocaleString('en-US', { timeZone }) : 'N/A',
            type,
            data,
          };
        });
      }

      const { lastFireTime, nextFireTime } = workflowJobModel;
      const workflowRunDetails = {
        // eslint-disable-next-line no-nested-ternary
        startDate: statusObject
          ? (statusObject.start_date
            ? new Date(statusObject.start_date).toLocaleString('en-US', { timeZone })
            : 'N/A')
          : 'N/A',
        // eslint-disable-next-line no-nested-ternary
        finishDate: statusObject
          ? (statusObject.finish_date
            ? new Date(statusObject.finish_date).toLocaleString('en-US', { timeZone })
            : 'N/A')
          : 'N/A',
        startedBy: statusObject ? statusObject.started_by : 'N/A',
        finishedBy: statusObject ? statusObject.finished_by : 'N/A',
        finishResult: statusObject ? statusObject.finish_result : 'N/A',
        startMethod: statusObject ? statusObject.start_method : 'N/A',
        lastFireTime: lastFireTime ? new Date(lastFireTime).toLocaleString('en-US', { timeZone }) : 'N/A',
        nextFireTime: nextFireTime ? new Date(nextFireTime).toLocaleString('en-US', { timeZone }) : 'N/A',
      };

      return {
        wfId: this.userWorkflows[index].id,
        psmName: parameterSetModel.name,
        combinedProgress,
        dataDependenciesWorkflowStatus: dataDependenciesWorkflowStatus.dependenciesCompleteStatus,
        publishDependenciesWorkflowStatus: publishDependenciesWorkflowStatus.dependenciesCompleteStatus,
        userActionHistoryList,
        workflowRunDetails,
      };
    },
    setWorkflowProgressPopup(index) {
      const workflowJobModel = this.workflowParams[index].workFlowJobModel;
      const parameterSetModel = workflowJobModel.parameterSetGroupModel.parameterSetModels;
      const workflowProgressData = new Array(parameterSetModel.length);
      for (let i = 0; i < parameterSetModel.length; i++) {
        workflowProgressData[i] = this.getWorkflowProgressII(index, i);
      }
      this.workflowProgressData[index] = workflowProgressData;
      this.overlay = false;
      this.showMainLoader = false;
    },
    setWorkflowProgressForInputSet(workflowIndex, inputIndex) {
      this.overlay = true;
      this.showMainLoader = true;
      this.setWorkflowProgressPopup(workflowIndex);
      this.workflowIndex = workflowIndex;
      this.workflowInputIndex = inputIndex;
      this.showWorkflowProgressDialog = true;
    },
    refreshWorkflow(index) {
      this.getWorkflowParamStatus(index, true, true, true);
    },
    removeWorkflowFromDashboard(index) {
      this.overlay = true;
      this.showMainLoader = true;
      const username = getUserName();
      this.updateUserWorkflows({
        username,
        workflowsToAdd: [],
        workflowsToRemove: [this.userWorkflows[index].id],
      }).finally(() => {
        const wfId = this.userWorkflows[index].id;
        this.workflowParams.splice(index, 1);
        this.paramErrors.splice(index, 1);
        this.userWorkflows.splice(index, 1);
        this.workflowProgressData.splice(index, 1);
        this.setUserWorkflow(this.userWorkflows);
        this.removeParameterSetById(wfId);
        delete this.wfParamSetData[wfId];
        this.overlay = false;
        this.showMainLoader = false;
        this.closeSearch(false);
      });
    },
    showWorkflowRemoveModal(index) {
      this.workflowName = this.userWorkflows[index].name;
      this.currentSelectedWorkflowIndex = index;
      this.showWorkflowRemovalModal = true;
    },
    closeWorkflowRemoveModal() {
      this.workflowName = '';
      this.currentSelectedWorkflowIndex = -1;
      this.showSaveButtonLoader = false;
      this.showWorkflowRemovalModal = false;
    },
    async deleteSchedule() {
      this.showSaveButtonLoader = true;
      this.overlay = true;
      const payload = { jobID: this.userWorkflows[this.currentSelectedWorkflowIndex].jobId };
      const workflowId = this.userWorkflows[this.currentSelectedWorkflowIndex].id;
      await axios.post(`/api/workflows/${workflowId}/deleteSchedule`, payload)
        .then()
        .catch((error) => {
        }).finally(async () => {
          await axios.delete(`/api/workflows/${workflowId}`)
            .then(() => {
              this.successMessage = 'Workflow deleted sucessesfully..!!';
              this.successMessage = true;
            })
            .catch((error) => {
              this.errorMessage = `Error while deleting the schedule for workflow, Error: ${error.message}`;
              this.showError = true;
            }).finally(() => {
              const username = getUserName();
              this.updateUserWorkflows({
                username,
                workflowsToAdd: [],
                workflowsToRemove: [this.userWorkflows[this.currentSelectedWorkflowIndex].id],
              }).finally(() => {
                const wfId = this.userWorkflows[this.currentSelectedWorkflowIndex].id;
                this.workflowParams.splice(this.currentSelectedWorkflowIndex, 1);
                this.paramErrors.splice(this.currentSelectedWorkflowIndex, 1);
                this.userWorkflows.splice(this.currentSelectedWorkflowIndex, 1);
                this.workflowProgressData.splice(this.currentSelectedWorkflowIndex, 1);
                this.setUserWorkflow(this.userWorkflows);
                this.removeParameterSetById(wfId);
                delete this.wfParamSetData[wfId];
                this.closeSearch(false);
                this.closeWorkflowRemoveModal();
                this.overlay = false;
              });
            });
        });
    },
    showViewDetailsDialog(index) {
      const selectedWf = this.userWorkflows[index];
      this.workflowName = selectedWf.name;
      this.viewDetails_WorkflowId = selectedWf.id;
      this.viewDetails_PsgId = selectedWf.psgId;

      const selectedWfParam = this.workflowParams[index];

      if ('workFlowJobModel' in selectedWfParam) {
        const selectedparameterSetGroupModel = selectedWfParam.workFlowJobModel.parameterSetGroupModel;

        selectedparameterSetGroupModel.parameterSetModels.forEach((parameterSetModel) => {
          const parameterSetName = `${parameterSetModel.name} (${parameterSetModel.id} - ${parameterSetModel.uuid})`;
          const runId = `${parameterSetModel.status && parameterSetModel.status.run_id !== 'undefined'
            ? parameterSetModel.status.run_id : 'N/A'}`;
          this.viewDetails_ParameterSetsWithJobIds.push({ parameterSetName, runId });
        });
      } else {
        this.showErrorParameterSetModelNotFound = true;
      }
      this.openViewDetailsDialog = true;
    },
    closeViewDetailsDialog() {
      this.workflowName = '';
      this.viewDetails_WorkflowId = 0;
      this.viewDetails_PsgId = 0;
      this.viewDetails_ParameterSetsWithJobIds = [];
      this.openViewDetailsDialog = false;
      this.showErrorParameterSetModelNotFound = false;
    },
    removePermission(index) {
      this.workflowPermissions.splice(index, 1);
    },
    async showMigrateWorkflowModal(index) {
      try {
        this.showLoader = true;
        this.currentSelectedWorkflowIndex = index;
        const selectedWf = this.userWorkflows[index];
        this.workflowName = selectedWf.name;

        if (this.workflowParams[index] ? Object.keys(this.workflowParams[index]).length === 0 : true) {
          await this.getWorkflowParamStatus(index, false, true, true);
        }
        this.overlay = true;
        this.showMainLoader = true;
        const response = await this.getAllWorkflowsDetails();
        this.allWorkflowDetails = response.data;
        this.overlay = false;
        this.showMainLoader = false;
        this.showLoader = false;
        this.openMigrateWorkflowModal = true;
        this.showFeedDetailsForMigrationLoader = true;
        const username = getUserName();

        if (this.allLicensedFeedsDetailForUser.length === 0) {
          this.allLicensedFeedsDetailForUser = await this.getAllLicensedFeedsForUser(username);
        }

        this.fetchFeedsFromWorkflowAndValidate(selectedWf, index);
        this.showFeedDetailsForMigrationLoader = false;
      } catch (error) {
        if (error.response && error.response.data) {
          this.errorMessage = error.response.data.errorMessage;
        } else {
          this.errorMessage = 'Failed loading workflow details.';
        }
        this.showError = true;
        this.closeMigrateWorkflowModal();
      }
    },
    closeMigrateWorkflowModal() {
      this.showSaveButtonLoader = false;
      this.workflowDataFeeds = [];
      this.workflowPublishFeeds = [];
      this.workflowAllFeeds = [];
      this.workflowMigrateFeedType = 'same-feeds';
      this.workflowMigrateTargetFeedNameTimeoutId = null;
      this.targetWorkflowName = '';
      this.targetWorkflowNameSuggetions = [];
      this.allWorkflowDetails = [];
      this.showTargetWorkflowNameSuggetions = false;
      this.workflowMigrateTypeOptions = [];
      this.selectedWorkflowMigrateOption = {};
      this.workflowName = '';
      this.currentSelectedWorkflowIndex = -1;
      this.isWorkflowMigrated = false;
      this.allLicensedFeedNamesForUser = [];
      this.migrationTargetFeedNames = [];
      this.migrationCurrentFeedsDetails = new Map();
      this.showTargetFeedNameSuggestions = [];
      this.targetFeedNameSuggestions = [];
      this.currentTargetFeedIndex = -1;
      this.suffixForCurrentFeedName = '';
      this.suffixForTargetFeedName = '';
      this.psgIdForWorkflowMigration = -1;
      this.migratedWorkflowId = -1;
      this.isParameterSetUpdatedForMigration = false;
      this.showFeedDetailsForMigrationLoader = false;
      this.focusedSuggestionIndex = -1;
      this.actualWorkflowAllFeeds = [];
      this.showErrorParameterSetModelNotFound = false;
      this.openMigrateWorkflowModal = false;
    },
    fetchFeedsFromWorkflowAndValidate(selectedWf, index) {
      const { bubbles, datasets } = selectedWf.ui;

      const dataBubbleIds = bubbles.filter(bubble => bubble.type === 'data').map(bubble => bubble.id);
      const publishBubbleIds = bubbles.filter(bubble => bubble.type === 'save').map(bubble => bubble.id);

      const dataBubbleNames = datasets
        .filter(dataset => dataBubbleIds.includes(dataset.bubbleId))
        .map(dataset => `udef.ds.${dataset.varName}.feed`);

      const publishBubbleNames = datasets
        .filter(dataset => publishBubbleIds.includes(dataset.bubbleId))
        .map(dataset => `udef.ds.${dataset.varName}.feed`);

      const workflowParam = this.workflowParams[index];

      if (!('workFlowJobModel' in workflowParam)) {
        this.showErrorParameterSetModelNotFound = true;
        return;
      }

      const selectedParameterSetModels = workflowParam.workFlowJobModel.parameterSetGroupModel.parameterSetModels;

      const dataFeeds = new Set();
      const publishFeeds = new Set();

      selectedParameterSetModels.forEach((psm) => {
        psm.parameterModels.forEach((pm) => {
          const { propKey, propValue } = pm;

          if (dataBubbleNames.includes(propKey)) {
            dataFeeds.add(propValue);
          } else if (publishBubbleNames.includes(propKey)) {
            publishFeeds.add(propValue);
          }
        });
      });

      this.workflowDataFeeds = dataFeeds;
      this.workflowPublishFeeds = publishFeeds;

      const allFeeds = new Set([...dataFeeds, ...publishFeeds]);
      const workflowFeeds = [...allFeeds].sort();

      const { feedDetailMap, allFeedNames } = this.allLicensedFeedsDetailForUser.reduce((accumulator, feedObj) => {
        accumulator.feedDetailMap.set(feedObj.name.toLowerCase(), feedObj);
        accumulator.allFeedNames.push(feedObj.name);
        return accumulator;
      },
      {
        feedDetailMap: new Map(),
        allFeedNames: [],
      });
      this.allLicensedFeedNamesForUser = [...allFeedNames];
      this.allLicensedFeedsDetailMap = feedDetailMap;

      workflowFeeds.forEach((feed) => {
        const defaultFeedDetail = {
          feedname: feed,
          isPrivateFeed: false,
          status: 'Same',
          userLicensed: false,
          misMatchedFeed: false,
          unRecognized: false,
        };

        const feedDetail = feedDetailMap.get(feed.toLowerCase());
        if (feedDetail) {
          defaultFeedDetail.isPrivateFeed = feedDetail.privateFeed;
          defaultFeedDetail.userLicensed = true;
        } else {
          defaultFeedDetail.status = 'Source not found';
        }

        this.workflowAllFeeds.push(defaultFeedDetail);
        this.migrationTargetFeedNames.push(feed);
        this.showTargetFeedNameSuggestions.push(false);
        this.createNewFeedToolTipToggle.push(false);
      });
      this.actualWorkflowAllFeeds = JSON.parse(JSON.stringify(this.workflowAllFeeds));
    },
    targetWorkflowNameChange() {
      clearTimeout(this.workflowMigrateTargetFeedNameTimeoutId);
      this.workflowMigrateTargetFeedNameTimeoutId = setTimeout(() => {
        this.focusedSuggestionIndex = -1;
        this.preparTargetWorkflowNameSuggetionsList();
      }, 1000);
    },
    preparTargetWorkflowNameSuggetionsList() {
      if (this.targetWorkflowName !== '') {
        const results = this.allWorkflowDetails
          .filter(wf => wf.name.toLowerCase().includes(this.targetWorkflowName.toLowerCase()) && wf.permissions.includes('view'))
          .map(wf => wf.name);

        const unqiueResults = new Set([...results]);
        this.targetWorkflowNameSuggetions = [...unqiueResults].sort();
        this.showTargetWorkflowNameSuggetions = true;
      } else {
        this.showTargetWorkflowNameSuggetions = false;
        this.targetWorkflowNameSuggetions = [];
        this.workflowMigrateTypeOptions = [];
        this.selectedWorkflowMigrateOption = {};
      }
    },
    targetWorkflowNameFocusOut(event) {
      if (this.$refs.targetWfNameSuggestionsTable) {
        const tableElement = this.$refs.targetWfNameSuggestionsTable.$el;

        if (event.relatedTarget
          && !tableElement.contains(event.relatedTarget)
          && !this.isClickInsideSuggestionsTable(event, tableElement)) {
          this.showTargetWorkflowNameSuggetions = false;
          this.performActionOnSelectedTargetWfName();
        }
      } else if (this.targetWorkflowName !== '') {
        this.performActionOnSelectedTargetWfName();
      } else {
        this.workflowMigrateTypeOptions = [];
        this.selectedWorkflowMigrateOption = {};
      }
    },
    isClickInsideSuggestionsTable(event, element) {
      const rect = element.getBoundingClientRect();

      return (event.clientX >= rect.left
            && event.clientX <= rect.right
            && event.clientY >= rect.top
            && event.clientY <= rect.bottom
      );
    },
    targetWorkflowNameSelected(selectedTargetWorkflowName) {
      this.targetWorkflowName = selectedTargetWorkflowName;
      this.showTargetWorkflowNameSuggetions = false;
      this.performActionOnSelectedTargetWfName();
    },
    performActionOnSelectedTargetWfName() {
      this.selectedWorkflowMigrateOption = {};
      const wfMigrateTypes = [];
      const user = getUserName();
      const matchedWf = this.allWorkflowDetails.filter(wf => wf.name.toLowerCase() === this.targetWorkflowName.toLowerCase());

      let wfAlreadyHave = false;
      matchedWf.forEach((wf) => {
        if (wf.owner.toLowerCase() === user.toLowerCase()) {
          wfAlreadyHave = true;
          wfMigrateTypes.unshift({
            type: 'overwrite',
            owner: wf.owner,
            id: wf.id,
          });
        } else {
          wfMigrateTypes.push({
            type: 'overwrite',
            owner: wf.owner,
            id: wf.id,
            isEditable: !!wf.permissions.includes('edit'),
          });
        }
      });

      if (!wfAlreadyHave) {
        wfMigrateTypes.unshift({
          type: 'new',
          owner: user,
        });
      }
      if (wfMigrateTypes.length === 1) {
        const [selectObj] = wfMigrateTypes;
        this.selectedWorkflowMigrateOption = selectObj;
      } else if (wfMigrateTypes.length > 1) {
        const filteredOptions = wfMigrateTypes.filter(option => !('isEditable' in option) || option.isEditable === true);
        if (filteredOptions.length === 1) {
          [this.selectedWorkflowMigrateOption] = wfMigrateTypes;
        }
      }
      this.workflowMigrateTypeOptions = wfMigrateTypes;
    },
    async migrateWorkflow() {
      this.showSaveButtonLoader = true;
      const selectedWfForMigration = this.userWorkflows[this.currentSelectedWorkflowIndex];

      if (!this.isWorkflowMigrated) {
        await this.saveNewParameterSetGroupForWorkflow();
        await this.saveWorkflowDetailsForMigration(selectedWfForMigration);
      }

      if (this.isWorkflowMigrated) {
        this.saveScheduleDetailForWorkflow(selectedWfForMigration, 0);
      }
    },
    async saveScheduleDetailForWorkflow(selectedWfForMigration, retryCount) {
      const maxRetries = 3;
      if (retryCount >= maxRetries) {
        this.errorMessage = 'Failed migrating workflow: Error while scheduling the cron job. Please retry.';
        this.showError = true;
        this.showSaveButtonLoader = false;
        return;
      }

      try {
        const cron = this.workflowParams[this.currentSelectedWorkflowIndex].workFlowJobModel.cronExpression;
        const { timeZone } = selectedWfForMigration;
        const schedulePayload = { psgId: this.psgIdForWorkflowMigration, cron, timeZone };
        const response = await axios.post(`/api/workflows/${this.migratedWorkflowId}/updateSchedule`, schedulePayload);

        const newMigratedWf = await axios.get(`/api/workflows/${this.migratedWorkflowId}/getWorkflowByID`);
        newMigratedWf.data.workflowNameToShow = `${newMigratedWf.data.id} - ${newMigratedWf.data.name}`;
        newMigratedWf.data.enableActionButtons = true;
        newMigratedWf.data.expanded = false;
        if (newMigratedWf.data.name.includes('_retired_')) {
          newMigratedWf.data.retired = true;
        }
        newMigratedWf.data.toggleEditView = false;
        newMigratedWf.data.toggleStartStop = false;
        newMigratedWf.data.toggleChangeOwner = false;
        newMigratedWf.data.toggleChangePermission = false;
        newMigratedWf.data.toggleDelete = false;
        newMigratedWf.data.toggleRemove = false;
        newMigratedWf.data.toggleWorkflowMigration = false;
        newMigratedWf.data.isEditable = !!newMigratedWf.data.permissions.includes('edit');
        newMigratedWf.data.isStartStopPermission = !!newMigratedWf.data.permissions.includes('start_stop');
        this.userWorkflows.push(newMigratedWf.data);
        this.workflowParams.push({});
        this.workflowProgressData.push({});
        this.successMessage = 'Workflow has been successfully migrated.';
        this.showSuccess = true;
        this.closeMigrateWorkflowModal();
      } catch (error) {
        this.saveScheduleDetailForWorkflow(selectedWfForMigration, retryCount + 1);
      }
    },
    async validateTargetFeedBeforeMigration(index) {
      const unLicensedFeedFound = this.actualWorkflowAllFeeds.some(feed => !feed.userLicensed);
      if (unLicensedFeedFound) {
        return;
      }

      const workflowFeed = this.workflowAllFeeds[index];
      workflowFeed.status = 'Pending...';
      const currentFeedName = workflowFeed.feedname;

      const modifiedFeedName = this.migrationTargetFeedNames[index];

      if (currentFeedName.toLowerCase() === modifiedFeedName.toLowerCase()) {
        workflowFeed.status = 'Same';
        workflowFeed.misMatchedFeed = false;
        workflowFeed.unRecognized = false;
      } else if (!this.allLicensedFeedsDetailMap.get(modifiedFeedName.toLowerCase())) {
        workflowFeed.status = 'Unrecognized';
        workflowFeed.unRecognized = true;
        workflowFeed.misMatchedFeed = false;
      } else {
        let currentFeedDetails = this.migrationCurrentFeedsDetails.get(currentFeedName.toLowerCase());
        if (!currentFeedDetails) {
          currentFeedDetails = await this.fetchFeedDetails(currentFeedName);
          this.migrationCurrentFeedsDetails.set(currentFeedName.toLowerCase(), currentFeedDetails);
        }

        const targetFeedDetails = await this.fetchFeedDetails(modifiedFeedName);
        const feedsMatched = this.compareFeeds(currentFeedDetails, targetFeedDetails);

        workflowFeed.status = feedsMatched ? 'Ok' : 'Mismatched';
        workflowFeed.misMatchedFeed = !feedsMatched;
        workflowFeed.unRecognized = false;
      }
    },
    async fetchFeedDetails(feedName) {
      const feedDetails = await this.getFeedDetailsForWorkflow(feedName);
      return feedDetails.data;
    },
    compareFeeds(srcFeed, desFeed) {
      if (srcFeed.timeUnits !== desFeed.timeUnits) {
        return false;
      }

      const srcFields = srcFeed.fields;
      const desFields = desFeed.fields;
      if (!srcFields || !desFields || srcFields.length !== desFields.length) {
        return false;
      }

      for (let i = 0; i < srcFields.length; i++) {
        const srcFieldObj = srcFields[i];
        const desFieldObj = desFields[i];

        if (srcFieldObj.fieldName !== desFieldObj.fieldName
          || srcFieldObj.fieldDataType !== desFieldObj.fieldDataType
          || srcFieldObj.type !== desFieldObj.type
        ) {
          return false;
        }
      }
      return true;
    },
    targetFeedNameChange(feedIndex) {
      if (this.workflowMigrateFeedType === 'same-feeds') {
        return;
      }

      this.currentTargetFeedIndex = feedIndex;
      clearTimeout(this.workflowMigrateTargetFeedNameTimeoutId);
      this.workflowMigrateTargetFeedNameTimeoutId = setTimeout(() => {
        this.preparTargetFeedNameSuggetionsList(feedIndex);
      }, 500);
    },
    preparTargetFeedNameSuggetionsList(feedIndex) {
      const modifiedFeedName = this.migrationTargetFeedNames[feedIndex];
      if (modifiedFeedName !== '') {
        const results = this.allLicensedFeedNamesForUser
          .filter(feedname => feedname.toLowerCase().startsWith(modifiedFeedName.toLowerCase()));

        const unqiueResults = new Set([...results]);
        if (this.focusedTargetFeedName === feedIndex) {
          this.targetFeedNameSuggestions = [...unqiueResults]
            .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));

          this.$set(this.showTargetFeedNameSuggestions, feedIndex, true);
          this.positionTargetFeedNameSuggestionBox(feedIndex);
        } else {
          this.targetFeedNameSuggestions = [];
          this.$set(this.showTargetFeedNameSuggestions, feedIndex, false);
        }
      } else {
        this.$set(this.showTargetFeedNameSuggestions, feedIndex, false);
        this.targetFeedNameSuggestions = [];
      }
    },
    positionTargetFeedNameSuggestionBox(index) {
      this.$nextTick(() => {
        const inputField = this.$refs[`targetFeedInputFields_${index}`];
        const suggestionBox = this.$refs[`targetFeedSuggestionBoxes_${index}`];

        if (inputField.length !== 0 && suggestionBox.length !== 0) {
          const inputFieldEl = inputField[0].$el;
          const suggestionBoxEl = suggestionBox[0].$el;

          const inputRect = inputFieldEl.getBoundingClientRect();

          // Adjust suggestion box position based on input field position
          suggestionBoxEl.style.top = `${inputRect.bottom + window.scrollY}px`;
          suggestionBoxEl.style.left = `${inputRect.left}px`;
        }
      });
    },
    callPositionTargetFeedNameSuggestionBox() {
      if (this.openMigrateWorkflowModal && this.currentTargetFeedIndex !== -1) {
        this.positionTargetFeedNameSuggestionBox(this.currentTargetFeedIndex);
      }
    },
    targetFeedNameSelected(selectedTargetFeedName) {
      this.$set(this.migrationTargetFeedNames, this.currentTargetFeedIndex, selectedTargetFeedName);
      this.$set(this.showTargetFeedNameSuggestions, this.currentTargetFeedIndex, false);
      this.validateTargetFeedBeforeMigration(this.currentTargetFeedIndex);
      this.currentTargetFeedIndex = -1;
    },
    migrateTargetFeedNameFocusout(event) {
      this.focusedTargetFeedName = -1;
      if (this.currentTargetFeedIndex !== -1) {
        const refName = `targetFeedNameSuggestionsTable_${this.currentTargetFeedIndex}`;
        if (this.$refs[refName] && this.$refs[refName].length !== 0) {
          const tableElement = this.$refs[refName][0].$el;

          if (event.relatedTarget
          && !tableElement.contains(event.relatedTarget)
          && !this.isClickInsideSuggestionsTable(event, tableElement)) {
            this.$set(this.showTargetFeedNameSuggestions, this.currentTargetFeedIndex, false);
            this.validateTargetFeedBeforeMigration(this.currentTargetFeedIndex);
          }
        } else if (this.migrationTargetFeedNames[this.currentTargetFeedIndex] !== '') {
          this.validateTargetFeedBeforeMigration(this.currentTargetFeedIndex);
        }
      }
    },
    applySuffixToTargetFeedName() {
      const unLicensedFeedFound = this.actualWorkflowAllFeeds.some(feed => !feed.userLicensed);
      if (unLicensedFeedFound) {
        return;
      }

      if (this.suffixForCurrentFeedName === '' && this.suffixForTargetFeedName === '') {
        const feedStatusChanged = this.workflowAllFeeds.some(feed => feed.status !== 'Same');

        if (feedStatusChanged) {
          return;
        }

        this.workflowAllFeeds.forEach((workflowFeed, index) => {
          workflowFeed.status = 'Same';
          workflowFeed.misMatchedFeed = false;
          workflowFeed.unRecognized = false;
          this.$set(this.migrationTargetFeedNames, index, workflowFeed.feedname);
        });
      } else {
        this.actualWorkflowAllFeeds.forEach((workflowFeed, index) => {
          if (workflowFeed.isPrivateFeed === true) {
            const currentTargetFeedName = workflowFeed.feedname.toLowerCase();
            const lastIndexOfSuffix = currentTargetFeedName.lastIndexOf(this.suffixForCurrentFeedName.toLowerCase());
            if (lastIndexOfSuffix !== -1 && currentTargetFeedName.endsWith(this.suffixForCurrentFeedName.toLowerCase())) {
              const modifiedFeedName = workflowFeed.feedname.slice(0, lastIndexOfSuffix)
                + this.suffixForTargetFeedName
                + workflowFeed.feedname.slice(lastIndexOfSuffix + this.suffixForCurrentFeedName.length);

              this.$set(this.migrationTargetFeedNames, index, modifiedFeedName);
            } else {
              this.$set(this.migrationTargetFeedNames, index, workflowFeed.feedname + this.suffixForTargetFeedName);
            }
            this.validateTargetFeedBeforeMigration(index);
          } else {
            this.$set(this.workflowAllFeeds, index, workflowFeed);
            this.$set(this.migrationTargetFeedNames, index, workflowFeed.feedname);
          }
        });
      }
    },
    preparePayloadForParameterSetGroup() {
      const modifiedFeedMappingMap = new Map();
      const isFeedMappingChanged = this.workflowAllFeeds.some(feed => feed.status === 'Ok');

      if (isFeedMappingChanged) {
        this.workflowAllFeeds.forEach((workflowFeed, index) => {
          if (workflowFeed.status === 'Ok') {
            const newFeedName = this.allLicensedFeedsDetailMap.get(this.migrationTargetFeedNames[index].toLowerCase()).name;
            modifiedFeedMappingMap.set(workflowFeed.feedname.toLowerCase(), newFeedName);
          }
        });
      }

      const currentWfParameterSetModels = this.workflowParams[this.currentSelectedWorkflowIndex]
        .workFlowJobModel
        .parameterSetGroupModel
        .parameterSetModels;


      const newWfParameterSetModels = currentWfParameterSetModels.map(psm => ({
        name: psm.name,
        description: psm.description,
        uuid: null,
        parameterModels: psm.parameterModels.map(({ propKey, propValue }) => ({
          propKey,
          propValue: isFeedMappingChanged && propKey.endsWith('.feed') && modifiedFeedMappingMap.has(propValue.toLowerCase())
            ? modifiedFeedMappingMap.get(propValue.toLowerCase()) : propValue,
        })),
      }));

      const currentDateTime = new Date();
      return {
        name: `${this.targetWorkflowName}_${currentDateTime.toISOString().slice(0, 19)}`,
        parameterSetModels: newWfParameterSetModels,
      };
    },
    async saveNewParameterSetGroupForWorkflow() {
      if (!this.isParameterSetUpdatedForMigration) {
        try {
          const reqPayload = this.preparePayloadForParameterSetGroup();
          const response = await this.saveParameterSet(reqPayload);
          this.psgIdForWorkflowMigration = response.data.id;
          this.isParameterSetUpdatedForMigration = true;
        } catch (error) {
          this.errorMessage = 'Failed migrating workflow: Error while saving parameter-set details';
          this.showError = true;
          this.isWorkflowMigrated = false;
          this.showSaveButtonLoader = false;
          this.isParameterSetUpdatedForMigration = false;
        }
      }
    },
    async saveWorkflowDetailsForMigration(selectedWfForMigration) {
      if (this.isParameterSetUpdatedForMigration) {
        const username = getUserName();
        try {
          const wfPayload = {
            name: this.targetWorkflowName,
            timeZone: selectedWfForMigration.timeZone,
            targets: selectedWfForMigration.targets,
            ui: selectedWfForMigration.ui,
            description: selectedWfForMigration.description,
            psgId: this.psgIdForWorkflowMigration,
          };

          wfPayload.ui.psg_id = this.psgIdForWorkflowMigration;

          if (this.selectedWorkflowMigrateOption.type === 'new') {
            const response = await this.createNewWorkflow(wfPayload);
            this.migratedWorkflowId = response.data.id;

            this.updateUserWorkflows({
              username,
              workflowsToAdd: [this.migratedWorkflowId],
              workflowsToRemove: [],
            });
          } else {
            wfPayload.id = this.selectedWorkflowMigrateOption.id;
            wfPayload.owner = this.selectedWorkflowMigrateOption.owner;
            const response = await this.updateWorkflowByID(wfPayload);
            this.migratedWorkflowId = response.data.id;

            // check for updated wf already present on dashboard or not
            const updatedWf = this.userWorkflows.find(wf => wf.id === this.migratedWorkflowId);
            if (!updatedWf) {
              // If not present already, add that updated wf to the user's dashboard
              this.updateUserWorkflows({
                username,
                workflowsToAdd: [this.migratedWorkflowId],
                workflowsToRemove: [],
              });
            }
          }
          this.isWorkflowMigrated = true;
        } catch (error) {
          if (error.response && error.response.data) {
            this.errorMessage = error.response.data.errorMessage;
          } else {
            this.errorMessage = 'Failed migrating workflow: Error while creating/updating workflow';
          }
          this.showError = true;
          this.isWorkflowMigrated = false;
          this.showSaveButtonLoader = false;
        }
      }
    },
    focusTargetWfNameSuggestion(event) {
      if (this.$refs.targetWfNameSuggestionsTable) {
        this.$nextTick(() => {
          const suggestionButtons = this.$refs.targetWfNameSuggestionsTable.$el.querySelectorAll('button');
          this.updateFocusedIndex(event, suggestionButtons);
        });
      }
    },
    focusTargetFeedNameSuggestion(event) {
      const refName = `targetFeedNameSuggestionsTable_${this.currentTargetFeedIndex}`;

      if (this.$refs[refName].length !== 0) {
        this.$nextTick(() => {
          const suggestionButtons = this.$refs[refName][0].$el.querySelectorAll('button');
          this.updateFocusedIndex(event, suggestionButtons);
        });
      }
    },
    updateFocusedIndex(event, suggestionButtons) {
      if (suggestionButtons.length > 0) {
        if (event.key === 'ArrowDown') {
          this.focusedSuggestionIndex = (this.focusedSuggestionIndex + 1) % suggestionButtons.length;
        } else if (event.key === 'ArrowUp') {
          this.focusedSuggestionIndex = this.focusedSuggestionIndex === -1 ? 0 : this.focusedSuggestionIndex;
          this.focusedSuggestionIndex = (this.focusedSuggestionIndex - 1 + suggestionButtons.length)
                % suggestionButtons.length;
        }
        suggestionButtons[this.focusedSuggestionIndex].focus();
      }
    },
    resetFocusedSuggestionIndex() {
      this.focusedSuggestionIndex = -1;
    },
    async redirectToNewFeedWithDefaults(feedIndex) {
      const currentFeedName = this.workflowAllFeeds[feedIndex].feedname;
      let currentFeedDetails = this.migrationCurrentFeedsDetails.get(currentFeedName.toLowerCase());

      if (!currentFeedDetails) {
        currentFeedDetails = await this.fetchFeedDetails(currentFeedName);
        this.migrationCurrentFeedsDetails.set(currentFeedName.toLowerCase(), currentFeedDetails);
      }

      const feedName = this.migrationTargetFeedNames[feedIndex];
      const {
        timeUnits, fields, privateFeed, provider,
      } = currentFeedDetails;

      const defaultObj = {
        name: 'NewFeed',
        params: {
          feedName,
          timeUnits,
          provider,
          redirectedFrom: 'workflowMigrate',
        },
      };

      if (privateFeed) {
        const keyInputs = this.extractFieldInputs(fields, 'k');
        const valueInputs = this.extractFieldInputs(fields, 'v');

        defaultObj.params.keyInputs = keyInputs;
        defaultObj.params.valueInputs = valueInputs;
      }
      this.$router.push(defaultObj);
    },
    extractFieldInputs(fields, fieldType) {
      return fields
        .filter(field => field.type === fieldType)
        .map((field) => {
          if (fieldType === 'k') {
            return { key: field.fieldName };
          }
          return {
            format: field.fieldDataType,
            value: field.fieldName,
          };
        });
    },
    async generateWorkflow(parameterSetGroup, workflowConfig, workflowFormula) {
      this.showImportLoader = true;
      let wfId = null;

      try {
        const dateString = new Date().toISOString().slice(0, 19);
        const targetWorkflowName = `${this.workflowConfigName}_${dateString}_${workflowConfig.id}`;
        parameterSetGroup.name = targetWorkflowName;

        const saveParameterSetResponse = await this.saveParameterSet(parameterSetGroup);
        const psgID = saveParameterSetResponse.data.id;

        const createNewWorkflowPayload = {
          name: this.workflowConfigName.replaceAll(' ', '-'),
          timeZone: workflowConfig.timeZone,
          targets: workflowConfig.targets,
          ui: workflowConfig.ui,
          description: '',
          psgId: psgID,
          psgVersion: 0,
        };

        const createWorkflowResponse = await this.createNewWorkflow(createNewWorkflowPayload);
        const workflowId = createWorkflowResponse.data.id;
        wfId = workflowId;

        const updateWorkflowSchedulePayload = {
          id: workflowId,
          psgId: psgID,
          cron: '0 0 8 ? * MON,FRI *',
          timeZone: workflowConfig.timeZone,
        };

        const retryCount = 5;
        for (let i = 0; i < retryCount; i++) {
          try {
            // eslint-disable-next-line no-await-in-loop
            const updateScheduleResponse = await this.updateWorkflowScheduleById(updateWorkflowSchedulePayload);
            const statusCode = updateScheduleResponse.data
              && updateScheduleResponse.data.result
              && updateScheduleResponse.data.result.status;
            if (statusCode === 200) {
              break;
            }
          } catch (error) {
            console.log(error);
          }
        }

        const updateFormulaPayload = {
          name: `wfgen_${targetWorkflowName.replaceAll(' ', '_')}_${dateString.replace(/[-:]/g, '')}_1`,
          type: 'JS',
          formula: workflowFormula,
        };
        const updateWorkflowFormulasResponse = await this.updateWorkflowFormulas(updateFormulaPayload);
        const formulaUUID = updateWorkflowFormulasResponse.data.uuid;
        const { targets, ui } = createWorkflowResponse.data;
        const oldFormulaUUIDs = ui.bubbles.filter(b => b.type === 'formula').map(b => b.props.formula_id);

        let targetsString = JSON.stringify(targets);
        let uiString = JSON.stringify(ui);

        oldFormulaUUIDs.forEach((ouuid) => {
          targetsString = targetsString.replaceAll(ouuid, formulaUUID);
          uiString = uiString.replaceAll(ouuid, formulaUUID);
        });

        const updateBubbleDataSetPayload = {
          ui: JSON.parse(uiString),
          targets: JSON.parse(targetsString),
          name: this.workflowConfigName,
          timeZone: workflowConfig.timeZone,
          description: '',
          psgId: psgID,
          owner: this.workflowConfigNewOwner,
          id: workflowId,
        };
        await this.updateBubbleDataset(updateBubbleDataSetPayload);

        this.showImportError = false;
        this.showImportSuccess = true;
        this.importWorkflowId = workflowId;
      } catch (error) {
        this.importErrorMessage = (error.response && error.response.data && error.response.data.errorMessage) || error.message;
        this.showImportError = true;
        this.showImportSuccess = false;
        this.importWorkflowId = '';
        this.inputSetStartRange = 1;
        this.inputSetEndRange = 1;
        this.importParsedVariables = false;
      } finally {
        this.updateUserWorkflows({
          username: getUserName(),
          workflowsToAdd: [wfId],
          workflowsToRemove: [],
        }).finally(() => {
          this.workflowConfigContents = '';
          this.importFeedsToBeChanged = '';
          this.importFeedSourcesToBeChanged = '';
          this.workflowConfigName = `WFI_${Date.now()}`;
          this.getSavedUserWorkflows(true);
          this.showImportLoader = false;
          this.inputSetStartRange = 1;
          this.inputSetEndRange = 1;
          this.importParsedVariables = false;
          this.selectAllInputSetsForImportCheck = false;
          this.selectInputsByType = 'Param Sets';
        });
      }
    },
    selectAllInputSetsForImport() {
      Object.keys(this.selectedInputSetMap).forEach((input) => {
        this.selectedInputSetMap[input] = !this.selectAllInputSetsForImportCheck;
      });
      this.selectAllInputSetsForImportCheck = !this.selectAllInputSetsForImportCheck;
    },
    hideSuccess1() {
      this.showPageSuccess = false;
      this.pageSuccess = '';
    },
    focusMigrateButton(event) {
      if (event.key === 'Tab' && !event.shiftKey) {
        event.preventDefault();
        const migrateButton = document.getElementById('migrateButton');

        if (migrateButton) {
          migrateButton.focus();
        }
      }
    },
    focusTargetWorkflowNameInputField(event) {
      if (event.key === 'Tab' && !event.shiftKey) {
        event.preventDefault();
        const inputField = document.getElementById('targetWorkflowNameInput');

        if (inputField) {
          inputField.focus();
        }
      }
    },
    resetTargetFeedNameSuggestionIndex(feedIndex) {
      this.focusedSuggestionIndex = -1;
      this.focusedTargetFeedName = feedIndex;
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap');
@import '@mds/typography';
@import '@mds/constants';
@import 'src/assets/styles/components/custom/user';

h3 {
  @include mds-level-5-heading();

  color: $mds-text-color-primary;
}
#workflow-home {
  font-family: "Univers",HelveticaNeue,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  margin-top: -0.5rem;
}
#workflow-home .mds-th__inner___Mcd-ui {
  margin-bottom: 0px;
}
#workflow-home .mds-directive--overlay__v3 .mds-overlay___Mcd-ui {
  opacity: 0 !important;
  display: contents !important;
}
.pading-top_1rem {
  padding-top: 1rem;
}
.pading-top_05rem {
  padding-top: 0.5rem;
}
.horizontal-bar {
  border-top: 2px solid #0c0c0c;
}
#userWorkflowsTable .mds-th__inner___Mcd-ui {
  justify-content: center !important;
}
#permission-dialog .mds-dialog--width-500px___Mcd-ui {
  max-width: 60% !important;
}
#import-dialog .mds-dialog--width-500px___Mcd-ui {
  max-width: 80% !important;
}
.table-scrolling-wrapper {
  max-height: 215px;
  overflow-y: scroll;
  overflow: auto;
}
mds-tooltip .mds-tooltip {
  max-width: 35%;
  background: #fff;
}
.mds-dialog___Mcd-ui {
  max-width: 60%;
  overflow: auto;
  max-height: 80vh;
}
.workflow-progress-section {
  padding: 0.5rem;
}

.workflow-progress-section + table {
  padding: 1rem;
}
.no-data-arrival {
  background: #ffbfbf;
  border-radius: 3px;
}
.data-arrival {
  background: #d2eccf;
  border-radius: 3px;
}
.phase-launch {
  background-color: #DBDDDC;
}
#view-details-dialog .mds-dialog--width-500px___Mcd-ui {
  max-width: 50% !important;
}
#workflow-migration-dialog .mds-dialog--width-500px___Mcd-ui {
  max-width: 50% !important;
}
.migration-table-wrapper{
  margin-top: 3%;
  height: 240px;
  overflow-y: scroll;
  overflow: auto;
}
.workflow-migrate-options-section{
  display: block;
  height: 57px;
  margin: 1% 0% 1% 0%;
  overflow-y: scroll;
  overflow: auto;
}
#workflow-delete-dialog .mds-dialog--width-500px___Mcd-ui {
  max-width: 35% !important;
}
#targetWorkflowNameSearchResults .mds-section__header-container___Mcd-ui {
  display: none !important;
}
#targetFeedNameSearchResults .mds-section__header-container___Mcd-ui {
  display: none !important;
}
.wf-import-error-msg-section {
  border-radius: 5px;
  border: 1px solid #FB517D;
  color: #FB517D;
  background: #ffe1e8;
  text-align: center;
  padding: .6rem;
  margin-top: 1rem;
  font-size: 18px;
}
.wf-import-success-msg-section {
  border-radius: 5px;
  border: 1px solid #02AD07;
  background: #d3ffeb;
  color: #02AD07;
  text-align: center;
  padding: .6rem;
  margin-top: 1rem;
  font-size: 18px;
}
.incons {
  font-family: 'Open Sans', sans-serif !important;
}
.wh-incons {
  font-size: 17px;
}
.row-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.row-opacity-low {
  opacity: 0.5;
}
[id*= 'row_tooltip_'] .mds-tooltip--visible {
  left: 250px !important;
}
#start-stop-id .mds-th__inner___Mcd-ui {
  display: contents;
  // width: 100% !important;
}
.retired-background {
  background-color: #ffe5e5;
}
.non-retired-background {
  background-color: #e5f7eb;
}
</style>
