import axios from 'axios';
import templateString from '../../../utils/stringUtils';
import getAPIConfigs from './config.js';

const config = getAPIConfigs();

const actions = {
  searchWorkflows({ commit }, searchFilters) {
    return axios
      .get(templateString(config.SEARCH_WORKFLOWS, searchFilters, '""'))
      .then((response) => {
        if (response.data) {
          if (!Array.isArray(response.data)) {
            response.data = [response.data];
          }
          response.data.forEach((element) => {
            element.isChecked = false;
          });
        }
        commit('SET_SEARCHED_WORKFLOWS', response.data);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
  getUserWorkflows({ commit }, username) {
    return new Promise((resolve, reject) => {
      axios.get(templateString(config.GET_WORKFLOWS_FOR_USER, { username }))
        .then((response) => {
          commit('SET_USER_WORKFLOWS', response.data.workflowsData ? response.data.workflowsData : []);
          resolve(response);
        });
    });
  },
  updateUserWorkflowsDetails({ commit }, updatedWorkflow) {
    commit('SET_USER_WORKFLOWS', updatedWorkflow);
  },
  setUserWorkflow({ commit }, updatedWorkflow) {
    commit('SET_USER_WORKFLOWS_WITH_ID', updatedWorkflow);
  },
  removeUserWorkflowByIndex({ commit }, index) {
    commit('REMOVE_USER_WORKFLOWS_WITH_INDEX', index);
  },
  removeParameterSetById({ commit }, workflowID) {
    commit('REMOVE_PARAMETER_SET_BY_ID', workflowID);
  },
  setCurrentEditableWorkflow({ commit }, currentEditableWorkflow) {
    commit('SET_EDITABLE_WORKFLOW_DETAILS', currentEditableWorkflow);
  },
  setCurrentEditWorkflowUI({ commit }, workflowUI) {
    commit('SET_EDIT_WORKFLOW_UI', workflowUI);
    commit('SET_USER_WORKFLOWS_WITH_ID', workflowUI);
  },
  setCurrentEditParamSet({ commit }, { data, workflowId }) {
    commit('SET_EDIT_PARAM_SET', data);
    commit('SET_WORKFLOW_PARAM_SET', { data, workflowId });
  },
  setCurrentEditFormulae({ commit }, { data, workflowId }) {
    commit('SET_WORKFLOW_FORMULAS', { data, workflowId });
    commit('SET_EDIT_FORMULAE', data);
  },
  addNewFormulaForWorkflow({ commit }, { formula, workflowId }) {
    commit('PUSH_FORMULA_FOR_WORKFLOW', { formula, workflowId });
  },
  setCurrentEditNewFormula({ commit }, newFormula) {
    commit('SET_EDIT_NEW_FORMULAE', newFormula);
  },
  setCurrentEditNodes({ commit }, nodes) {
    commit('SET_EDIT_NODES', nodes);
  },
  setCurrentEditLinks({ commit }, links) {
    commit('SET_EDIT_LINKS', links);
  },
  setCurrentEditTargets({ commit }, targets) {
    commit('SET_EDIT_TARGETS', targets);
  },
  setNewlyCreateWorkflow({ commit }, newWorkflow) {
    commit('SET_CREATE_NEW_WORKFLOW_DETAILS', newWorkflow);
  },
  setNewlyCreateWorkflowUI({ commit }, workflowUI) {
    commit('SET_CREATE_NEW_WORKFLOW_UI', workflowUI);
  },
  setNewlyCreateParamSet({ commit }, paramSet) {
    commit('SET_CREATE_NEW_PARAM_SET', paramSet);
  },
  setNewlyCreateNewFormula({ commit }, newFormula) {
    commit('SET_CREATE_NEW_NEW_FORMULAE', newFormula);
  },
  setNewlyCreateNodes({ commit }, nodes) {
    commit('SET_CREATE_NEW_NODES', nodes);
  },
  setNewlyCreateLinks({ commit }, links) {
    commit('SET_CREATE_NEW_LINKS', links);
  },
  setNewlyCreateTargets({ commit }, targets) {
    commit('SET_CREATE_NEW_TARGETS', targets);
  },
  setAllWorkflowsForName({ commit }, data) {
    commit('SET_ALL_WORKFLOWS_FOR_NAME', data);
  },
  setWorkflowName({ commit }, data) {
    commit('SET_WORKFLOW_NAME', data);
  },
  setAllParamSet({ commit }, data) {
    commit('SET_ALL_PARAM_SET', data);
  },
  setParamSet({ commit }, { paramsetData, wfParamSetDataArray }) {
    wfParamSetDataArray.forEach((workflowId) => {
      const data = {
        workFlowJobModel: paramsetData[workflowId].workFlowJobModel,
      };
      commit('SET_WORKFLOW_PARAM_SET', { data, workflowId });
    });
  },
  setParamSetWFId({ commit }, { data, workflowId }) {
    commit('SET_WORKFLOW_PARAM_SET', { data, workflowId });
  },
  getWorkflowStatus({ commit }, workflowId) {
    const promise = new Promise((resolve, reject) => {
      axios.get(templateString(config.GET_WORKFLOW_STATUS, { workflowId }))
        .then((response) => {
          const { data } = response;
          commit('SET_WORKFLOW_PARAM_SET', { data, workflowId });
          resolve(response);
        })
        .catch((error) => {
          commit('SET_WORKFLOW_PARAM_SET', { data: {}, workflowId });
          reject(error);
        });
    });
    return promise;
  },
  updateUserWorkflows({ commit }, payload) {
    const { username, workflowsToAdd, workflowsToRemove } = payload;
    return axios.post(templateString(config.UPDATE_USER_WORKFLOWS, { username }), {
      add: workflowsToAdd,
      remove: workflowsToRemove,
    });
  },
  getFormulasForWorkflow({ commit }, workflow) {
    const formulaIds = [];
    workflow.ui.bubbles.filter(bubble => Object.hasOwnProperty.call(bubble.props, 'formula_id'))
      .forEach(bubble => formulaIds.push(bubble.props.formula_id));
    const allFormulaPromises = [];
    formulaIds.forEach((id) => {
      allFormulaPromises.push(new Promise(((resolve, reject) => {
        axios.get(templateString(config.GET_FORMULA_FOR_ID, { id })).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      })));
    });
    return Promise.all(allFormulaPromises).then((response) => {
      commit('SET_WORKFLOW_FORMULAS', { data: response, workflowId: workflow.id });
      return response;
    });
  },
  getAllWorkflowsForDiagram({ commit }) {
    return axios.get(templateString(config.GET_ALL_WORKFLOWS)).then(response => response);
  },
  updateUserWorkflowsDiagram({ commit }, workflowUi) {
    return axios.post(templateString(config.UPDATE_USER_WORKFLOW_DIAGRAM), {
      workflowUi,
    }).then(response => response);
  },

  updateWorkflowFormulas({ commit }, workflowFormula) {
    return axios.post(templateString(config.UPDATE_FORMULA), {
      ...workflowFormula,
    });
  },

  updateBubbleDataset({ commit }, newBubbleDataset) {
    return axios.put(templateString(config.UPDATE_BUBBLE_DATASET, { workflowId: newBubbleDataset.id }), newBubbleDataset)
      .then(response => response);
  },

  runFormula({ commit }, formulaPayload) {
    return axios.post(templateString(config.RUN_FORMULA), formulaPayload)
      .then(response => response);
  },

  runJSFormula({ commit }, formulaPayload) {
    return axios.post(templateString(config.RUN_JS_FORMULA), formulaPayload)
      .then(response => response);
  },

  updateWorkflowByID({ commit }, workflow) {
    return axios.put(templateString(config.UPDATE_WORKFLOW_BY_ID, { workflowId: workflow.id }), workflow);
  },

  saveParameterSet({ commit }, ParameterSet) {
    return axios.post(templateString(config.SAVE_PARAMETRSET_MODEL), ParameterSet);
  },

  createNewWorkflow({ commit }, workflow) {
    return axios.post(templateString(config.CREATE_NEW_WORKFLOW), workflow);
  },

  changeWorkflowOwner({ commit }, { workflowId, newOwnerName }) {
    return axios.put(templateString(config.CHANGE_WORKFLOW_OWNER, { workflowId, newOwnerName }))
      .then(response => response);
  },

  getParameterSet({ commit }, workflowId) {
    return axios.get(templateString(config.GET_PARAMETER_SET_MODELS, { workflowId }))
      .then(response => response);
  },

  getWorkflowPermissionsById({ commit }, workflowId) {
    return axios.get(templateString(config.GET_PERMISSIONS_BY_WORKFLOW_ID, { workflowId }))
      .then(response => response);
  },
  updateWorkflowPermissionsById({ commit }, { workflowId, payload }) {
    return axios.post(templateString(config.UPDATE_PERMISSIONS_BY_WORKFLOW_ID, { workflowId }), payload)
      .then(response => response);
  },
  setCurrentWorkflowManager({ commit }, manager) {
    commit('SET_CURRENT_WORKFLOW_MANAGER', manager);
  },
  setFormulaDataNodeSet({ commit }, { nodeId, dataNodes }) {
    commit('SET_FORMULA_DATA_NODE_SET', { nodeId, dataNodes });
  },
  clearFormulaDataNodeSet({ commit }) {
    commit('CLEAR_FORMULA_DATA_NODE_SET');
  },
  getAllWorkflowsDetails({ commit }) {
    return axios.get(templateString(config.GET_ALL_WORKFLOWS))
      .then(response => response);
  },
  getAllLicensedFeedsForUser({ commit }, username) {
    return axios.get(templateString(config.GET_FEEDS_FOR_USER, { username }))
      .then(response => response.data);
  },
  getFeedDetailsForWorkflow({ commit }, feedname) {
    return axios.get(templateString(config.GET_FEED_DETAILS, { feedname }))
      .then(response => response);
  },
  updateWorkflowScheduleById({ commit }, workflow) {
    return axios.post(templateString(config.UPDATE_WORKFLOW_SCHEDULE, { workflowId: workflow.id }), workflow);
  },
  addUnsavedFormula({ commit }, formula) {
    commit('SET_UNSAVED_FORMULA', formula);
  },
};

export default actions;
