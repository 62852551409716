<template>
  <div id="mds-masthead__right">
    <mds-button-container right-aligned>
      <mds-button
        id="popover-notif"
        variation="flat"
        type="button"
        @click.stop="readNotifs"
      >
        <notification-bell
          icon-color="#333333"
          :count="userNotifs.length"
          :upper-limit="50"
          :size="20"
          counter-padding=".1em"
          counter-style="rectangle"
          font-size=".8em"
          counter-location="upperRight"
        />
      </mds-button>
      <mds-button
        id="popover-help"
        variation="icon-only"
        icon="question-circle"
        type="button"
        @click.stop="(showHelp=!showHelp) && (helpTT=!helpTT)"
      />
      <mds-button
        id="popover-account"
        variation="icon-only"
        icon="person"
        type="button"
        @click="showAccount=!showAccount"
      />
    </mds-button-container>
    <mds-tooltip
      v-model="notifsTT"
      triggered-by="popover-notif"
      :position="['bottom-left']"
    >
      {{ $t('labels.notification.name') }}
    </mds-tooltip>
    <mds-tooltip
      v-model="helpTT"
      triggered-by="popover-help"
      :position="['bottom-left']"
    >
      {{ $t('navigation.help') }}
    </mds-tooltip>
    <mds-tooltip
      v-model="accountTT"
      triggered-by="popover-account"
      :position="['bottom-left']"
    >
      {{ $t('navigation.rightMasthead') }}
    </mds-tooltip>
    <mds-popover
      triggered-by="popover-help"
      :visible="showHelp"
      :position="['bottom-left']"
      class="settings-popover"
      width="200px"
      fixed
    >
      <mds-list-group>
        <div class="popover-heading">
          {{ $t('navigation.help') }}
        </div>
        <mds-list-group-item
          @mds-list-item-clicked="(showWhatsNew=!showWhatsNew) && (showHelp=!showHelp)"
        >
          <mds-button
            variation="flat"
          >
            {{ $t('navigation.whatsNewText') }}
          </mds-button>
        </mds-list-group-item>
        <mds-list-group-item>
          <mds-button
            id="footer__visit-us"
            :href="corporateSite"
            variation="flat"
          >
            {{ $t('navigation.visitUsText') }}
          </mds-button>
        </mds-list-group-item>
        <mds-list-group-item>
          <mds-button
            id="footer__contact-us"
            href="mailto:commoditydata-support@morningstar.com"
            variation="flat"
          >
            {{ $t('navigation.contactUsText') }}
          </mds-button>
        </mds-list-group-item>
      </mds-list-group>
    </mds-popover>
    <transition name="slide">
      <template v-if="showNotifs">
        <Notifications :user-notifs="userNotifs" />
      </template>
    </transition>
    <transition name="slide">
      <template v-if="showWhatsNew">
        <whats-new />
      </template>
    </transition>
    <popover-items :show-account="showAccount" />
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import NotificationBell from 'vue-notification-bell';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import Cookies from 'js-cookie';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group-v4';
import MdsPopover from '@mds/popover';
import WhatsNew from '@/components/Common/WhatsNew.vue';
import { CORPORATE_SITE } from '@/utils/constants.js';
import PopoverItems from '../../PopoverItems';
import { ThemeMixin } from '../../../mixins/themeMixin';
import Notifications from '../../Notifications';
import EventBus from '../../../main';
import MDSPopover from '../../imports';

export default {
  name: 'RightMasthead',
  components: {
    PopoverItems,
    NotificationBell,
    Notifications,
    MdsButton,
    MdsButtonContainer,
    MdsListGroup,
    MdsListGroupItem,
    WhatsNew,
    MdsPopover,
  },
  mixins: [ThemeMixin],
  data() {
    return {
      showNotifs: false,
      notifsCall: false,
      hover: false,
      showAccount: false,
      showHelp: false,
      showWhatsNew: false,
      notifsTT: false,
      accountTT: false,
      helpTT: false,
      corporateSite: CORPORATE_SITE,
    };
  },
  computed: {
    ...mapGetters({
      userNotifs: 'homeModule/getUserNotifs',
      globalSettings: 'homeModule/getGlobalSettings',
    }),
  },
  created() {
    if (Cookies.get('loggedIn')) {
      if (!this.globalSettings.size || this.globalSettings.size === 0) {
        this.getUserGlobalSettings().then((res) => {
          this.setUserNotifications();
        });
      } else {
        this.setUserNotifications();
      }
    }
    EventBus.$on('markNotifsRead', this.markNotificationsRead);
    EventBus.$on('hideNotifs', this.hideNotifs);
    EventBus.$on('hideWhatsNew', this.hideWhatsNew);
    EventBus.$on('hideAccounts', () => {
      this.hideAccounts();
    });
  },
  mounted() {
    // to call user notifications every 60 secs
    setInterval(this.setUserNotifications, 60000);
  },

  methods: {
    ...mapActions('homeModule', ['getUserNotifications', 'setNotifsRead', 'getUserGlobalSettings']),
    handleChangeThem() {
      this.$emit('handleChangeThem', !this.darkMode);
    },
    hideAccounts() {
      this.showAccount = false;
    },
    readNotifs() {
      this.showNotifs = !this.showNotifs;
      if (this.showNotifs) {
        this.setUserNotifications();
      }
    },
    hideNotifs() {
      this.showNotifs = false;
    },
    hideWhatsNew() {
      this.showWhatsNew = false;
    },
    setUserNotifications() {
      if (!this.notifsCall && Cookies.get('loggedIn')) {
        this.notifsCall = true;
        this.getUserNotifications().then((response) => {
          this.notifsCall = false;
        });
      }
    },
    markNotificationsRead() {
      this.setNotifsRead();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/components/custom/transition";
  .nine-menu {
    fill:  var(--dynamic-icon-color);
  }
  .nine-menu:hover {
    fill:  var(--dynamic-icon-color);
  }
  .chat-bubble-icon {
    fill:  var(--dynamic-icon-color);
  }
  .chat-bubble-icon:hover {
    fill:  var(--dynamic-icon-color);
  }
  .person-icon {
    fill:  var(--dynamic-icon-color);
  }
  .person-icon:hover {
    fill:  var(--dynamic-icon-color);
  }
  .lang-text-button{
    fill:  var(--dynamic-icon-color);
    color: var(--dynamic-title-color); ;
  }
.settings-popover{
  z-index: 9999;
  top:40px !important;
  background-color: #F8F8F8;
}
.popover-heading{
  font-weight: bold;
  margin-bottom:5px;
}

</style>
