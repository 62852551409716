const mutations = {
  SET_SEARCHED_WORKFLOWS: (state, searchedWorkflows) => {
    state.searchedWorkflows = searchedWorkflows;
  },
  SET_USER_WORKFLOWS: (state, userWorkflows) => {
    state.userWorkflows = userWorkflows;
  },
  SET_USER_WORKFLOWS_WITH_ID: (state, userWorkflow) => {
    const index = state.userWorkflows.findIndex(wf => wf.id === userWorkflow.id);
    state.userWorkflows[index] = userWorkflow;
  },
  SET_PARAM_SET: (state, data) => {
    state.paramSet = data;
  },
  SET_ALL_PARAM_SET: (state, data) => {
    state.paramSet = data;
  },
  SET_WORKFLOW_PARAM_SET: (state, { data, workflowId }) => {
    state.paramSet[workflowId] = data;
  },
  REMOVE_USER_WORKFLOWS_WITH_INDEX: (state, index) => {
    state.userWorkflows.splice(index, 1);
  },
  REMOVE_PARAMETER_SET_BY_ID: (state, workflowID) => {
    delete state.paramSet[workflowID];
  },
  SET_WORKFLOW_FORMULAS: (state, { data, workflowId }) => {
    state.formulae[workflowId] = data;
  },
  PUSH_FORMULA_FOR_WORKFLOW: (state, { formula, workflowId }) => {
    if (state.formulae[`${workflowId }`]) {
      state.formulae[`${workflowId }`].push(formula);
    } else {
      state.formulae[`${workflowId }`] = [];
      state.formulae[`${workflowId }`].push(formula);
    }
  },
  SET_EDITABLE_WORKFLOW_DETAILS: (state, workflowDetails) => {
    state.selectedWorkflow.workflowUI = workflowDetails.workflowUI;
    state.selectedWorkflow.paramSet = workflowDetails.paramSet;
    state.selectedWorkflow.formulae = workflowDetails.formulae;
    state.selectedWorkflow.newlyAddedFormule = [];
    state.selectedWorkflow.operation = workflowDetails.operation;
    state.selectedWorkflow.nodes = workflowDetails.nodes;
    state.selectedWorkflow.targets = workflowDetails.targets;
    state.selectedWorkflow.links = workflowDetails.links;
  },
  SET_CREATE_NEW_WORKFLOW_DETAILS: (state, workflowDetails) => {
    state.createNewWorkflow.workflowUI = workflowDetails.workflowUI;
    state.createNewWorkflow.paramSet = workflowDetails.paramSet;
    state.createNewWorkflow.newlyAddedFormule = [];
    state.createNewWorkflow.nodes = workflowDetails.nodes;
    state.createNewWorkflow.targets = workflowDetails.targets;
    state.createNewWorkflow.links = workflowDetails.links;
  },
  SET_EDIT_WORKFLOW_UI: (state, workflowUI) => {
    state.selectedWorkflow.workflowUI = workflowUI;
  },
  SET_EDIT_PARAM_SET: (state, paramSet) => {
    state.selectedWorkflow.paramSet = paramSet;
  },
  SET_EDIT_FORMULAE: (state, formulae) => {
    state.selectedWorkflow.formulae = formulae;
  },
  SET_EDIT_NEW_FORMULAE: (state, newFormula) => {
    state.selectedWorkflow.newlyAddedFormule = newFormula;
  },
  SET_EDIT_NODES: (state, nodes) => {
    state.selectedWorkflow.nodes = nodes;
  },
  SET_EDIT_LINKS: (state, links) => {
    state.selectedWorkflow.links = links;
  },
  SET_EDIT_TARGETS: (state, targets) => {
    state.selectedWorkflow.targets = targets;
  },
  SET_CREATE_NEW_WORKFLOW_UI: (state, workflowUI) => {
    state.createNewWorkflow.workflowUI = workflowUI;
  },
  SET_CREATE_NEW_PARAM_SET: (state, paramSet) => {
    state.createNewWorkflow.paramSet = paramSet;
  },
  SET_CREATE_NEW_NEW_FORMULAE: (state, newFormula) => {
    state.createNewWorkflow.newlyAddedFormule.push(newFormula);
  },
  SET_CREATE_NEW_NODES: (state, nodes) => {
    state.createNewWorkflow.nodes = nodes;
  },
  SET_CREATE_NEW_LINKS: (state, links) => {
    state.createNewWorkflow.links = links;
  },
  SET_CREATE_NEW_TARGETS: (state, targets) => {
    state.createNewWorkflow.targets = targets;
  },
  SET_ALL_WORKFLOWS_FOR_NAME: (state, data) => {
    state.allWorkflowsForName = data;
  },
  SET_CURRENT_WORKFLOW_MANAGER: (state, manager) => {
    state.currentWorkflowManager = manager;
  },
  SET_FORMULA_DATA_NODE_SET: (state, { nodeId, dataNodes }) => {
    state.formulaDataNodeSet[nodeId] = dataNodes;
  },
  CLEAR_FORMULA_DATA_NODE_SET: (state) => {
    state.formulaDataNodeSet = {};
  },
  SET_WORKFLOW_NAME: (state, data) => {
    state.workflowName = data;
    state.selectedWorkflow.workflowUI.name = data;
    state.currentWorkflowManager._priv.name = data;
  },
  SET_UNSAVED_FORMULA: (state, formula) => {
    state.currentUnsavedFormula = formula;
  },
};

export default mutations;
